import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../../../hooks/useWidth"
import background from "../../../../images/eidBackgroundv3.png";
import environment from "../../../../environment/environment";


const ElectronicIdentificationBannerComponent = () => {
    const breakpoints = useWidth();

    const ediURL = environment.ediURL

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: breakpoints == "xs" ? 3 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: breakpoints == "xs" ? '1.5rem!important' : '3rem!important' }}>
                        <Box sx={{ mb: 5 }}>
                            <Typography
                                sx={{
                                    fontSize: 29,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    fontWeight: 700,
                                    textAlign: 'start',
                                    color: "#000"
                                }}
                            >
                                Electronic Identification (eID)
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    color: 'rgb(100, 110, 115)',
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    width: breakpoints == "xs" ? '70%' : '80%'
                                }}
                            >
                                Your secure and hassle-free digital ID.
                            </Typography>
                        </Box>
                        <Box sx={{ mb: breakpoints === 'xs' ? 1 : 4 }}>
                            <Grid container>
                                <Grid item xs={5} sx={{ textAlign: 'start' }}>
                                    <Button variant="outlined"
                                        sx={{ width: breakpoints === 'xs' ? '100%' : '70%', fontSize: 14, height: 40, borderColor: '#017eff', color: '#017eff', textTransform: 'none', borderRadius: 0, '&:hover': { backgroundColor: 'transparent', borderColor: '#017eff', color: '#017eff' } }}
                                        onClick={() => { window.open(ediURL, '_blank'); }}
                                    >
                                        Sign In
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ElectronicIdentificationBannerComponent;

import React from 'react';
import { Card, CardContent, Typography, Link, List, ListItem, ListItemText, Chip, Box, Container, ListItemIcon } from '@mui/material';
import BackToTopButton from '../components/layout/scroll-up.component';
import ElectronicIdentificationAndroidAppReleaseNotes from '../components/ovcode-website-v2/enterprise/eid-android-app-release-notes.component';
import ElectronicIdentificationAndroidAppReleaseNotesv2 from '../components/ovcode-website-v2/enterprise/eid-android-app-release-notes-v2.component';



const ElectronicIdentificationAndroidAppReleaseNotesScreen = () => {


    return (
        <Box>

            <div class="container">
                <BackToTopButton />
            </div>

            <Box sx={{
                overflow: 'hidden',
                margin: 'auto 0',
                width: ' 100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Container maxWidth="md">
                    {/* <Box sx={{ mb: 4 }}>
                        <Typography sx={{ fontSize: '40px', margin: 0, color: '#000000CC', fontWeight: 600, textTransform: 'none' }}>
                            Official Release Notes
                        </Typography>
                    </Box> */}
                    <Box sx={{width: '100%', margin: 'auto', marginLeft: '230px', mt: 4}}>
                        <Box sx={{width: '80%'}}>
                            <ElectronicIdentificationAndroidAppReleaseNotes />
                            <br/>
                            <ElectronicIdentificationAndroidAppReleaseNotesv2 />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>

    );
}

export default ElectronicIdentificationAndroidAppReleaseNotesScreen;

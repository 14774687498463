import React from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import patents_images from "./patents-images.component";

const PatentsComponent = () => {

    const breakpoints = useWidth();
    
    return (
        <Box>
            <Box sx={{mb: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontSize: "37px",
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: "start",
                    mb: "1.25rem",
             }}>
                    Patents
                </Typography>
            </Box>
           
            <Grid container spacing={2}>
                {patents_images.map((value) => { 
                    return (
                        <Grid item xs={12} sm={4}>                       
                            <Card sx={{
                                boxShadow: 'none', 
                                border: 'none', 
                                // borderRadius: 4, 
                                width: "100%", 
                                margin: "auto",
                                padding: "25px",
                                // padding: 5, 
                            }}>
                                <img alt="" src={value.icon} style={{width: (value.name == "korea") ? "50%" : "65%"}} />
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>   
        </Box>    
    )  
}

export default PatentsComponent
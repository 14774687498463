
import React, { useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Grid, Paper, Stack, Typography, ImageList, ImageListItem } from "@mui/material";
import solutions_images from "./soultions-images.component";
import SolutionsDialogComponent from "../../dialog/solutions-dialog.component";




function SolutionsSliderComponent(props) {

  const { breakpoints } = props;

  const [openSolutions, setOpenSolutions] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  const handleClickOpen = () => {
    setOpenSolutions(true);
  };

  const handleClose = () => {
    setOpenSolutions(false);
  };


  return (

    <Box sx={{ overflow: 'hidden' }}>
      <Box sx={{ mb: 4 }}>
        <Typography sx={{
          fontSize: 37,
          margin: 0,
          lineHeight: 1.2,
          fontWeight: 600,
          textAlign: 'start',
          color: "#000",
          mb: '1.25rem'
        }}>
          {/* USE CASE IMPLEMENTATION AND PRODUCT PORTFOLIO */}
          Use Case Implementation
        </Typography>
      </Box>

      <div class="content-card carouselbase carousel aem-GridColumn aem-GridColumn--default--12">
        <section id="content-card-uid55d6">
          <div class="row row-cols-1   row-cols-sm-2 row-cols-lg-4" style={{justifyContent: 'center'}}>
            {solutions_images.map((value) => {
              return (
                <div class="col  mb-5 mb-lg-0" style={{paddingBottom: '20px'}}  >
                  <div class="card" style={{ cursor: 'pointer' }} onClick={(e) => { setDescription(value.description); setTitle(value.secondName); setName(value.title); handleClickOpen(); }}>
                    <img src={value.icon}
                      loading="lazy" class="card-img" alt="Document Authentication" />
                    <div class="card-body  pt-3" >
                      <h2 class="h4"  style={{ textAlign: 'center', marginTop: '30px', color: '#0067b8', textDecoration: 'underline'}} >
                        {value.title}
                      </h2>
                      <p style={{ textAlign: 'center', paddingRight: '12px' }}>{value.name}</p>
                    </div>
                  </div>
                </div>
              )
            })}


          </div>
        </section>
      </div>

      {/* <Box class="case-studies-wrap astro-GC4WYHXB aos-init aos-animate" data-aos="fade-up">
        <ul class="case-studies-list astro-GC4WYHXB">
          {solutions_images.map((value) => {
            return (
              <li class="case-card relative astro-WPN5BZQ5" data-size={value.size}>
                <Box sx={{ cursor: 'pointer' }} onClick={(e) => { setDescription(value.description); setTitle(value.secondName); setName(value.title); handleClickOpen(); }}>
                  <Box class="case-card-img-wrapper astro-WPN5BZQ5">
                    <img class="h-full astro-WPN5BZQ5" src={value.icon} alt="rescur" />
                  </Box>
                  <Box class="case-card-content absolute w-full h-full top-0 left-0 flex flex-col justify-between astro-WPN5BZQ5">
                    <Box class="case-card-visible astro-WPN5BZQ5">
                      <Typography class="case-card-type astro-WPN5BZQ5">{value.title}</Typography>
                      <Typography class="case-card-title mt-[5px] astro-WPN5BZQ5">{value.name}</Typography>
                    </Box>
                    <Box class="case-card-hidden-info astro-WPN5BZQ5">
                      <Typography class="case-card-desc relative -top-[20px] astro-WPN5BZQ5">{value.text}</Typography>
                      <Typography class="case-card-tech astro-WPN5BZQ5" onClick={(e) => { setDescription(value.description); setTitle(value.secondName); setName(value.title); handleClickOpen(); }}>Read More</Typography>
                    </Box>
                  </Box>
                </Box>
              </li>
            )
          })}
        </ul>
      </Box> */}

      <SolutionsDialogComponent openSolutions={openSolutions} handleClose={handleClose} description={description} title={title} name={name} />

    </Box>

  );

}

export default SolutionsSliderComponent

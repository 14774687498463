import { Box, Grid, Typography } from "@mui/material";
import electronicIdentificationIcon from "../../../../icons/eIDSuperFinalLogoSize.png";
import YouTube from 'react-youtube';
import useWidth from "../../../../hooks/useWidth";

const AboutElectronicIdentificationComponent = (props) => {
 
    const breakpoints = useWidth();


    const opts = {
        width: breakpoints === 'xs' ? 'auto' : '765px',
        height:  breakpoints === 'xs' ? 'auto' : '430px',
        playerVars: {
            autoplay: 1, 
            mute: 1, 
        },
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            <Box sx={{ mb: 2 }}>
                                <img src={electronicIdentificationIcon} style={{ width: '25%' }} alt="eID Icon" />
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <Typography sx={{
                                color: "#000",
                                fontSize: 24,
                                margin: 0,
                                lineHeight: 1.6,
                                fontWeight: 400,
                                textAlign: 'start',
                                width: '95%'
                            }}
                            >
                             Unleashing Your Digital Persona :
                            </Typography>
                            </Box>
                            <Box sx={{ mb: 5 }}>
                                <Typography
                                    sx={{
                                        fontSize: 19,
                                        margin: 0,
                                        lineHeight: 1.2,
                                        fontWeight: 700,
                                        textAlign: 'start',
                                        color: "#000",
                                        width: '95%'
                                    }}
                                >
                                    The Digital Revolution of eID
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        color: 'rgb(100, 110, 115)',
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '85%'
                                    }}
                                >
                                   Step into a secure and paperless future as electronic identification revolutionizes how you verify your identity with a simple digital platform.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {breakpoints === 'xs' ? null : <Grid item xs={12} sm={1}></Grid> }
                    <Grid item xs={12} sm={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: breakpoints === 'xs' ? 'auto' : '100vh',
                    }}>
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: breakpoints === 'xs' ? 'auto' : '100%' }}>
                            <Grid item>
                                <YouTube
                                    videoId="WnzgtuJXfzo"
                                    opts={opts}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        // <Box
        //     sx={{
        //         display: 'flex',
        //         flexDirection: 'column',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //         minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
        //         maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
        //         overflow: 'hidden',
        //         margin: 'auto 0',
        //         width: ' 100%',
        //         overflow: 'hidden',
        //     }}
        // >
        //      <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
        //         <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
        //             <Grid item xs={12} sm={12} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
        //                 <img src={electronicIdentificationIcon} style={{ width: '8%' }} alt="eID Icon" />
        //             </Grid>
        //             <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        // <Typography sx={{
                        //     color: "#000",
                        //     fontSize: 29,
                        //     margin: 0,
                        //     lineHeight: 1.6,
                        //     fontWeight: 400,
                        // }}
                        // >
                        //     Unleashing Your Digital Persona: The Power of eID
                        // </Typography>
        //             </Grid>
        //         </Grid>

        //         <Grid item xs={12} sx={{ mt: 3 }}>
        //             <Grid container spacing={0} sx={{ alignContent: 'center', alignItems: 'center', padding: 6, pb: 0, pt: 0 }}>
        //                 <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '3rem!important' }}>
        //                     <Box sx={{ mb: 5 }}>
        //                         <Typography
        //                             sx={{
        //                                 fontSize: 29,
        //                                 margin: 0,
        //                                 lineHeight: 1.2,
        //                                 fontWeight: 700,
        //                                 textAlign: 'start',
        //                                 color: "#000"
        //                             }}
        //                         >
        //                             The Digital Revolution of eID
        //                         </Typography>
        //                     </Box>
        //                     <Box sx={{ mb: 0 }}>
        //                         <Typography
        //                             sx={{
        //                                 fontSize: 16,
        //                                 margin: 0,
        //                                 lineHeight: 1.6,
        //                                 color: 'rgb(100, 110, 115)',
        //                                 fontWeight: 400,
        //                                 textAlign: 'justify',
        //                                 textJustify: 'inter-word',
        //                                 color: "#000",
        //                                 width: '80%'
        //                             }}
        //                         >
        //                             Step into a secure and paperless future as electronic identification revolutionizes how you verify your identity with a simple digital touch.
        //                         </Typography>
        //                     </Box>
        //                 </Grid>
        //                 <Grid item xs={12} sm={1}></Grid>
        //                 <Grid item xs={12} sm={6} sx={{
        //                     display: 'flex',
        //                     justifyContent: 'center',
        //                     alignItems: 'center',
        //                     height: '100%',
        //                 }}>
        //                     <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        //                         <Grid item>
        //                             <YouTube
        //                                 videoId="OEuxoYbTScw"
        //                                 opts={opts}
        //                             />
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </Grid>
                
        //     </Box>
        // </Box>
    )
}

export default AboutElectronicIdentificationComponent;


import React, { useRef, useState } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import VaultStorageImage from '../../../images/products/ovcode-vault-storage.jpg';
import DecentralizedStorageImage from '../../../images/products/ovcode-decentralized-storage.jpg';

const ServicesTwoComponent = () => {

    const breakpoints = useWidth();

    return (

        <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: 6 }}>
            <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <img src={VaultStorageImage} style={{ width: '80%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }} />
                <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>Vault Storage</Typography>
                <Typography
                    sx={{ width: '85%', margin: 'auto', fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
                    OVCODE Vault is a secure platform to permanently register and store valuable digital assets, such as documents, pictures, videos, and other files. The vault provides access and sharing rights features for efficient management and use.
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <img src={DecentralizedStorageImage} style={{ width: '80%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }} />
                <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>IPFS Storage</Typography>
                <Typography
                    sx={{ width: '85%', margin: 'auto', fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
                    IPFS is a peer-to-peer file system that allows users to access and share data without the need for a central server. This makes it more resistant to censorship and more secure than traditional file sharing systems.
                </Typography>
            </Grid>
        </Grid>


    );

}

export default ServicesTwoComponent

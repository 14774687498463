import { Box, Paper, Typography } from "@mui/material"
import React from "react";
import IndustryInsightsTopicsComponent from "../components/ovcode-website-v2/blog/topics/industry-insights-topics.component";



const IndustryInsightsScreen = () => {

    const bannerStyle = {
        backgroundColor: '#017eff',
        height: 230,
        width: '100%',
        display: 'flex', // Use flex display for centering
        alignItems: 'center', // Center vertically
        paddingLeft: '50px', // Adjust padding
        color: '#fff',
        textAlign: 'start'
    };

    const bannerContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center content vertically within the banner
        textAlign: 'start',
       

    };

    return(
        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12" style={{overflow: 'hidden'}}>
            <div class="layout-container responsivegrid aem-GridColumn aem-GridColumn--default--12" style={{overflow: 'hidden'}}>
                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12" style={{overflow: 'hidden'}}>

                    <div class="container-banner" style={{ padding: '0 5px' }}>
                        <Paper style={bannerStyle}>
                            <div style={bannerContentStyle}>
                                <Typography variant="h4">Industry Insights</Typography>
                                <Typography variant="subtitle1">
                                    The official source of product insight from the OVCODE Team
                                </Typography>
                            </div>
                        </Paper>
                    </div>

                    <Box sx={{ mt: 5 }} />

                    <IndustryInsightsTopicsComponent />

                </div>
            </div>
        </div>
    )
}

export default IndustryInsightsScreen
import React, { useState } from "react";
import { Card,  CardContent,  Chip,  List, ListItem, ListItemIcon,  ListItemText, Typography} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const OvcodeReleaseNotes = () => {
    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card
            sx={{
                borderRadius: "4px",
                marginBottom: "10px",
                transition: ".5s",
                boxShadow: "0 0 6px 1px rgba(0,0,0,0.08)",
                backgroundColor: "#ffffff",
                "&:hover": {
                    boxShadow: "0 0 12px 2px rgba(0,0,0,0.16)",
                },
            }}
        >
            <div style={{ padding: "40px" }}>
                <header style={{ textAlign: "start" }}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}
                    >
                        OVCODE Enterprise Portal Version 2
                    </Typography>
                    <div
                        style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            alignItems: "center",
                            fontSize: 14,
                            color: "#999",
                        }}
                    >
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={"CHANGED"} sx={{background: '#ffa5004f'}} />
                            </ListItem>
                        </List>
                        <Typography
                            variant="body2"
                            component="span"
                            className="posted-time"
                        >
                            <time dateTime="2020-04-23">August 25, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>
                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: "start" }}>
                            New Features and Enhancements
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Authentication and Authorization</Typography>}
                                    secondary="OVCODE now utilizes enterprise Identity and Access Management for enhanced security and access control."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>UI/UX Enhancement</Typography>}
                                    secondary="The user interface has been improved to provide a more user-friendly experience."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Dashboard</Typography>}
                                    secondary="Introducing a comprehensive dashboard for an overview of usage statistics and Credit Management."
                                />
                            </ListItem>
                            {showFullDetails && (
                                <>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Profile Management</Typography>}
                                            secondary="Users can now update their company logo, company details, personal signature, and subscription key within their profiles."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Sign in Logs</Typography>}
                                            secondary="Keep track of all sign-in activities for enhanced security and monitoring."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Current Plan</Typography>}
                                            secondary="Easily view and manage your current subscription plan."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Management</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>Create, edit, search, view details, delete, restore, and permanently delete projects.</ListItemText>
                                                        <ListItemText>Activate and deactivate projects.</ListItemText>
                                                        <ListItemText>Manage API Keys, Templates, Webhooks, and Email Senders.</ListItemText>
                                                        <ListItemText>View Document Authentication Records.</ListItemText>
                                                        <ListItemText>Configure Verification Settings :</ListItemText>
                                                        <ListItem>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <Typography>Choose between Public or Private policy.</Typography>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <Typography>Enable or disable document download.</Typography>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <Typography>Configure Auto Revoke settings.</Typography>
                                                        </ListItem>
                                                        <ListItemText>Test API functionality.</ListItemText>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Vault</Typography>}
                                            secondary="Access a list of authenticated documents stored securely."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Authentication</Typography>}
                                            secondary="View a list of authentication logs, filter and search for specific entries."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Management</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>Create new templates for documents.</ListItemText>
                                                        <ListItemText>Customize templates with options such as QR codes, e-signatures, e-seals, text fields, dates, and support for up to 3 signatures.</ListItemText>
                                                        <ListItemText>Enable and disable templates, and include a certificate on the last page.</ListItemText>
                                                        <ListItemText>Delete, restore, or permanently delete templates.</ListItemText>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Analytics</Typography>}
                                            secondary="Analyze document authentication usage through graph representations."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>User Management</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>Create new users.</ListItemText>
                                                        <ListItemText>View existing users.</ListItemText>
                                                        <ListItemText>Deactivate or activate user accounts.</ListItemText>
                                                        <ListItemText>Edit email addresses.</ListItemText>
                                                        <ListItemText>Search and filter users for easy management.</ListItemText>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Reports</Typography>}
                                            secondary="Generate monthly reports per project, with options to propagate data by date or pipeline."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>API Documentation</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>Enhanced API instructions.</ListItemText>
                                                        <ListItemText>Categorized into 4 API Categories</ListItemText>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Authentication"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>PFS (Decentralized Storage)</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>IPFS and Private Blockchain</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>IPFS and Public Blockchain</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Verification"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Verify Data and File</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Verify Token ID, IPFS CID, Hash, and Reference</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="OVI"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Generate OVI reference</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Generate Encrypted OVI</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Misc"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Download Authenticated Document API using reference or IPFS CID</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Revoke or Suspend Authenticated Document API</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Get Authenticated Document Status API</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>OVCODE API</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>File Authentication using digital signatures and blockchain technology.</ListItemText>
                                                        <ListItemText>JSON format data Authentication.</ListItemText>
                                                        <ListItemText>Template and Email features.</ListItemText>
                                                        <ListItemText>Credit System Integrated</ListItemText>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    );
};

export default OvcodeReleaseNotes;

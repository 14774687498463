import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import LandingSolutions from '../../../images/solutions-7.png';
import solutionsBackground from "../../../images/banner-3.png";


const LandingSolutionsComponent = () => {

    const breakpoints = useWidth();


    return (

        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${solutionsBackground})`, // Use backticks to format as URL
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                sx={{
                                    fontSize: 29,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    fontWeight: 700,
                                    textAlign: 'start',
                                    color: "#fff",
                                // textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"

                                }}
                            >

                                OVCODE Solutions
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography sx={{
                                fontSize: 16,
                                margin: 0,
                                lineHeight: 1.6,
                                // color: 'rgb(100, 110, 115)',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                color: "#fff",
                                width: '95%',
                                // textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"

                            }}>
                                <b style={{ fontWeight: 700, textTransform: 'uppercase' }}>OVCODE</b> innovative solutions have revolutionized the way documents are verified and authenticated online, ensuring a seamless and foolproof process which eliminates any possible fraudulent activity.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'end' }}>
                        {/* <Box sx={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
                        {console.log("breakpointsxxx", breakpoints)}

                            <img
                                src={LandingSolutions}
                                style={{
                                    imageRendering: 'pixelated',
                                    msInterpolationMode: 'nearest-neighbor', // For IE
                                    imageRendering: '-moz-crisp-edges', // For Firefox
                                    imageRendering: '-webkit-optimize-contrast', // For Webkit browsers
                                    // width: '55%'
                                    width: (breakpoints == "xs" || breakpoints == "sm") ? '85%' : (breakpoints == "lg") ? '55%' : (breakpoints == "md") ? '68%' : '45%'
                                }}
                                alt="Landing Solutions"
                            />
                        </Box> */}
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}

export default LandingSolutionsComponent
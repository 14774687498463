import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const support_assistance = [
    {
        name: "How can I get technical support for OVCODE products? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    You can reach our technical support team through our provided contact channels (Channel Information) for assistance with any technical issues.
                </Typography>
            </Box>
    },
    {
        name: "What is the role of the support team at OVCODE? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    The support team at OVCODE is dedicated to assisting users with any questions, concerns, or technical issues they may encounter while using our platform.
                </Typography>
            </Box>
    },
    {
        name: "How can I contact OVCODE's support team? ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    You can reach our support team through the designated support channels, which include (Links).
                </Typography>
            </Box>
    }
    ,
    {
        name: "What information should I have ready when contacting support? ",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    To help us assist you more effectively, please provide details such as your account information, a brief description of the issue, and any relevant screenshots or error messages.
                </Typography>
            </Box>
    },
    {
        name: "Is OVCODE's support available 24/7? ",
        panel: "Panel5",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Our support team operates during specified hours (operation hours). While we strive to provide timely responses, please note that responses might be delayed during off-hours.
                </Typography>
            </Box>
    }

]

export default support_assistance

// local instance
// const website_URL = 'https://blue-bay-0057a4b1e.2.azurestaticapps.net'
// const ovcodeWebServiceBaseURL = "https://cybergem.azure-api.net/ovcode-website"
// const subscriptionKey = "c15e79fa1d304133b9d820f41fd0d5de"
// const apiDocURL = "https://black-desert-0783be21e.2.azurestaticapps.net"


// dev instance
// const website_URL = 'https://dev.ovcode.com'
// const ovcodeWebServiceBaseURL = "https://cybergem.azure-api.net/ovcode-website"
// const subscriptionKey = "c15e79fa1d304133b9d820f41fd0d5de"
// const apiDocURL = "https://dev-docs.ovcode.com"
const ediURL = "https://eid.ovcode.com"
const esignURL = "https://esign.ovcode.com"

// live instance
const website_URL = 'https://ovcode.com'
const ovcodeWebServiceBaseURL = "https://ovcodeprodapim.azure-api.net/ovcode-website"
const subscriptionKey = "d82a0e69c1424e41a002564062912bef"
const apiDocURL = "https://docs.ovcode.com"

// 
export default {

    domain: {
        url: website_URL,
    },

    ovcode_website_service: {
        url: ovcodeWebServiceBaseURL,
        subscriptionKey: subscriptionKey
    },

    apiDocURL: apiDocURL,

    ediURL : ediURL,

    esignURL : esignURL



}
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import electronicIdentificationIcon from "../../../../icons/electronic-identification.png";
import YouTube from 'react-youtube'; // Import the react-youtube package
import ovsign from '../../../../icons/eSignSuperFinalLogoSize.png';
import useWidth from "../../../../hooks/useWidth";



const AboutElectronicSignatureComponent = (props) => {

    const breakpoints = useWidth();

    const opts = {
        width: breakpoints == "xs" ? 'auto' : '765px',
        height: breakpoints == "xs" ? 'auto' : '430px',
        playerVars: {
            autoplay: 1, // Autoplay the video
            mute: 1
        },
    };


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            <Box sx={{ mb: 2 }}>
                                <img src={ovsign} style={{ width: '25%' }} alt="eSign Icon" />
                            </Box>
                            <Box sx={{ mb: 5 }}>
                                <Typography sx={{
                                color: "#000",
                                fontSize: 24,
                                margin: 0,
                                lineHeight: 1.6,
                                fontWeight: 400,
                                textAlign: 'start',
                                width: '90%'
                            }}
                            >
                             Redefining Signatures for a Digitally Connected World
                            </Typography>
                            </Box>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        color: 'rgb(100, 110, 115)',
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '85%'
                                    }}
                                >
                                   Empower your documents with the seamless efficiency of electronic signatures, bridging the gap between tradition and technology.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {breakpoints === 'xs' ? null : <Grid item xs={12} sm={1}></Grid> }
                    <Grid item xs={12} sm={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: breakpoints === 'xs' ? 'auto' : '100vh',
                    }}>
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: breakpoints === 'xs' ? 'auto' : '100%' }}>
                            <Grid item>
                                <YouTube
                                    videoId="ThUyqAC5Frc"
                                    opts={opts}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AboutElectronicSignatureComponent
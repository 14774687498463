import React from "react";
import { Box, Grid, Typography, Card, CardMedia } from "@mui/material";
import DrawerHeader from "../../components/layout/drawer-header";
import AboutBannerComponent from "./about-banner.component";

import AwardsComponent from "../landing/awards/awards.component";
import PatentsComponent from "../landing/patents/patents.component";
import OvcodeNewsComponent from "../landing/ovcode-news/ovcode-news.component";
import PartnersComponent from "../landing/partners/partners.component";
import aboutUs from "../../images/about-us.png";
import background from "../../images/ovcodeBackground.png";
import useWidth from "../../hooks/useWidth";



const AboutUsComponent = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Use backticks to format as URL
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            {/* <div class="container-banner"> */}
           <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                sx={{
                                    fontSize: 29,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    fontWeight: 700,
                                    textAlign: 'start',
                                    color: "#000",
                                }}
                            >
                                What Is OVCODE ?
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography sx={{
                                fontSize: 16,
                                margin: 0,
                                lineHeight: 1.6,
                                color: 'rgb(100, 110, 115)',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                color: "#000",
                                width: '95%'
                            }}>
                                Backed by its award-winning patented technology, incorporating electronic identity, blockchain, permanent storage, and artificial intelligence, <b style={{ fontWeight: 700, textTransform: 'uppercase' }}>OVCODE</b> ensures utmost integrity and reliability throughout the authentication and verification process.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'end' }}>
                        <Box sx={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
                            <img
                                src={aboutUs}
                                style={{
                                    imageRendering: 'pixelated',
                                    msInterpolationMode: 'nearest-neighbor', // For IE
                                    imageRendering: '-moz-crisp-edges', // For Firefox
                                    imageRendering: '-webkit-optimize-contrast', // For Webkit browsers
                                    width: '40%'
                                }}
                                alt="Landing Solutions"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* </div> */}
        </Box>

    )
}

export default AboutUsComponent
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const ovcode_network = [
    {
        name: "How many OVCODE Network coins are in circulation? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    There are 83,875,000 OVCs in circulation. Unlike other cryptocurrencies, OVCODE is a utility token focused on full regulatory compliance, ensuring trust and reliability.
                    We hope these FAQs provide a comprehensive understanding of the OVCODE Trust Platform. Should you have more specific questions or require further information, please don't hesitate to contact us. We're dedicated to delivering transparency, security, and trust through our cutting-edge technology.
                </Typography>
            </Box>
    },
    {
        name: "What are OVCODE’s Products? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 1 }}>
                    OVCODE Trust Platform is at the forefront of continuous innovation, delivering a suite of cutting-edge solutions to enhance trust, security, and authenticity in the digital realm. While our commitment to evolution and progress drives our entire range of offerings, our primary products include:
                </Typography>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        <b>A. OVCODE eID: </b>
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        An eID is a digital identity document that can be used to verify your identity securely online, providing a convenient and trusted way to authenticate yourself.
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        As a cornerstone of the OVCODE Trust Platform, OVCODE eID pioneers the way we establish and manage digital identities. It harnesses advanced biometric verification and decentralized identity protocols, providing individuals and businesses with a secure and verifiable means to authenticate users. Beyond its immediate benefits, OVCODE eID serves as a powerful Single Sign-On (SSO) credential, enabling seamless access to all OVCODE products, including our groundbreaking eSign solution.
                    </Typography>

                </Box>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        <b>B. OVCODE eSign: </b>
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        OVCODE eSign redefines digital signatures by seamlessly integrating blockchain technology and AI-enhanced verification. Our eSign solution replaces traditional paper-based signatures with secure electronic signatures, ensuring tamper-proof and legally compliant transactions. By enhancing the authenticity and integrity of digital signatures, OVCODE eSign empowers individuals and businesses to conduct digital interactions with unparalleled confidence.
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        These products represent the foundation of the OVCODE Trust Platform's commitment to trust, security, and innovation. As we continue to evolve and expand our offerings, rest assured that OVCODE's holistic approach will empower you to navigate the digital landscape with unwavering trust and authenticity.
                    </Typography>

                </Box>
            </Box>
    }

]

export default ovcode_network

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Box, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useWidth from "../../../../hooks/useWidth";
import verification_images from "./verification-icons.component";

// Initialize Swiper modules
SwiperCore.use([Pagination]);

function AboutVerificationComponent(props) {
    const breakpoints = useWidth();
    const [swiper, setSwiper] = useState(null);

    return (
        <Box sx={{ mb: 4,  margin: "auto", overflow: "hidden" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <button onClick={() => swiper.slidePrev()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowBackIosIcon />
                </button>
                <Swiper
                    pagination={{ clickable: true }}
                    loop={true}
                    className="mySwiper"
                    onSwiper={setSwiper}
                >
                    {verification_images.map((value) => {
                        return (
                            <SwiperSlide key={value.name}>
                                <div style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030",
                                    display: "flex", height: "100%", width: breakpoints === 'xs' ? "100%" : "95%", margin: 'auto', padding: breakpoints === 'xs' ? 1 : 6, borderRadius: "8px", }}>
                                    <List sx={{height: breakpoints === 'xs' ? 'auto' : 80}}>
                                        <ListItem>
                                            <ListItemIcon sx={{ minWidth: 50, maxWidth: 50, margin: 'auto' }}>
                                                <img alt="" src={value?.icon} style={{ width: 35 }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value?.description} />
                                        </ListItem>
                                    </List>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <button onClick={() => swiper.slideNext()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowForwardIosIcon />
                </button>
            </div>
        </Box>
    );
}

export default AboutVerificationComponent;

import environment from "../../../environment/environment";
import routes from "../../api/common/api-routes";
import {CustomHttpPost} from "../common/http-request-builder";

const SUBSCRIPTION_KEY = environment.ovcode_website_service.subscriptionKey;

const joinOVCode = (firstName, lastName, companyName, inquiryDetails, email, contactNo, website) => {
    
    let payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        company_name: companyName,
        inquiry_details: inquiryDetails,
        contact_no: contactNo,
        website: website
    }

    console.log("joinOVCode payload xxxx", payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = CustomHttpPost(routes.JOIN_OVCODE, payload, subscriptionKey);

    return result;
}

const JoinService = {
    joinOVCode: joinOVCode
}

export default JoinService
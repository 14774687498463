import { Box, Grid, Typography } from "@mui/material"


const trust_service = [
    {
        name: "What is trust Service? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    A trust service is an electronic service that creates, validates, and verifies electronic signatures, time stamps, seals, and certificates.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    A Trust Service is more than just a technological feature; it's a crucial component in the digital landscape that fosters authenticity, security, and reliability. Think of it as a digital guardian that oversees and ensures the credibility of electronic transactions, signatures, and documents.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    In today's interconnected world, where the majority of interactions occur digitally, the need for trust and assurance is paramount. Trust Services play a pivotal role by providing a set of electronic services that serve as a digital notary, ensuring that the information exchanged between parties is genuine and remains unchanged over time.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    These services encompass a range of functions:
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b><i>Electronic Signatures:</i></b>&nbsp;Trust Services enable the creation and validation of electronic signatures, confirming the authenticity of a sender and their intent to be bound by the content of a document. This helps prevent fraudulent activities and unauthorized alterations.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b><i>Time Stamps:</i></b> &nbsp;By adding time stamps to electronic documents, Trust Services establish a clear and irrefutable timeline, ensuring the integrity of data and proving that it existed at a particular point in time.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b><i>Seals and Certificates:</i></b> &nbsp;Trust Services allow for the creation and management of digital seals and certificates. These act as digital fingerprints, confirming the source and integrity of electronic information.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b><i>Verification:</i></b> &nbsp;Trust Services validate the authenticity of electronic transactions, signatures, and documents. They act as an independent arbiter, verifying that the information has not been tampered with or altered since its creation.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    In essence, Trust Services create a secure environment where digital interactions can be conducted with confidence. They form the foundation for building trust in an increasingly virtual world, ensuring that electronic exchanges are as valid and legally binding as their physical counterparts.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    At OVCODE, we take pride in offering robust Trust Services that empower individuals and businesses with the tools needed to establish trust, security, and transparency in their digital interactions. Our platform seamlessly integrates these services, enabling you to conduct transactions and handle documents with the utmost confidence and credibility.
                </Typography>
            </Box>
    },
    {
        name: "Why are Trust Services important in the digital world? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Trust Services are essential in the digital world because they address the challenges of authenticity, security, and data integrity. As more interactions and transactions occur online, the need for a reliable and tamper-proof way to verify identities, documents, and timestamps becomes critical. Trust Services provide the foundation for building trust in digital environments, ensuring that electronic interactions are legally binding, secure, and credible.
                </Typography>
            </Box>
    },
    {
        name: "How do Trust Services ensure the security of electronic signatures?",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Trust Services use advanced cryptographic techniques to secure electronic signatures. When you create an electronic signature, Trust Services generate a unique digital fingerprint of the document, known as a hash. This hash is then encrypted using your private key. When the signature is verified, the hash is decrypted with the corresponding public key, ensuring the document's integrity and the authenticity of the signer
                </Typography>
            </Box>
    },
    {
        name: "Can Trust Services be used for document archiving?",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Yes, Trust Services can be used for document archiving. By applying time stamps and digital seals, Trust Services create an auditable trail that proves the existence of a document at a specific time. This is particularly valuable for legal and regulatory purposes, as it provides evidence of document authenticity and helps establish a reliable historical record.
                </Typography>
            </Box>
    },
    {
        name: "How do Trust Services protect against tampering or forgery?",
        panel: "Panel5",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Trust Services employ cryptographic techniques and secure algorithms to protect against tampering and forgery. When a document is signed or sealed using Trust Services, any alteration to the document's content would result in a different hash value. Even a small change in the document would lead to a significant change in the hash, making tampering or forgery evident upon verification.
                </Typography>
            </Box>
    },
    {
        name: "Are Trust Services legally recognized?",
        panel: "Panel6",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Yes, Trust Services are legally recognized in many jurisdictions around the world. Various countries have enacted laws and regulations that provide legal validity to electronic signatures, time stamps, and digital seals facilitated by Trust Services. These legal frameworks ensure that electronic transactions conducted using Trust Services hold the same legal status as traditional paper-based transactions.
                </Typography>
            </Box>
    },
    {
        name: "How do Trust Services comply with industry standards and regulations?",
        panel: "Panel7",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Trust Services adhere to industry standards and regulations, such as those outlined by eIDAS in Europe and NIST in the United States. These standards set requirements for the security, interoperability, and reliability of Trust Services. By following these standards, Trust Services ensure that their operations meet the highest level of security and compliance, enhancing their credibility and trustworthiness.
                </Typography>
            </Box>
    },
    {
        name: "Can Trust Services be used across different industries? ",
        panel: "Panel8",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Absolutely, Trust Services are versatile and can be applied across various industries. From finance and healthcare to legal and supply chain management, Trust Services provide a secure and reliable way to verify identities, transactions, and documents. Their flexibility makes them a valuable asset for any industry that requires trust and security in their digital interactions.
                </Typography>
            </Box>
    }

]

export default trust_service

import React from "react";
import {Box} from "@mui/material";
import useWidth from "../hooks/useWidth";
import JoinNowComponent from "../components/join/join-now.component";

const JoinNowScreen = () => {
    
    const breakpoints = useWidth();

    return (
        <Box>
            <JoinNowComponent breakpoints={breakpoints} />
        </Box>     
    )
}

export default JoinNowScreen
import React, {useState} from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import products_images from "./products-images.component";
import SolutionsDialogComponent from "../../dialog/solutions-dialog.component";

const ProductPortfolioComponent = () => {

    const breakpoints = useWidth();

    const [openSolutions, setOpenSolutions] = useState(false);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [name, setName] = useState("");

    const handleClickOpen = () => {
        setOpenSolutions(true);
    }

    const handleClose = () => {
        setOpenSolutions(false);
    }

    
    return (
        <Box>
            <Box sx={{mb: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontSize: "37px",
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: "start",
                    mb: "1.25rem",
                }}>
                    Product Portfolio
                </Typography>
            </Box>

            <div class="content-card carouselbase carousel aem-GridColumn aem-GridColumn--default--12">
                <section id="content-card-uid55d6">
                    <div class="row row-cols-1   row-cols-sm-3 row-cols-lg-3">
                        {products_images.map((value) => {
                            return (
                                <div class="col  mb-5 mb-lg-0" style={{paddingBottom: '20px'}}  >
                                    <div class="card" style={{ cursor: 'pointer' }} onClick={(e) => { setDescription(value.description); setTitle(value.secondName); setName(value.title); handleClickOpen(); }}>
                                        <img src={value.icon}
                                            loading="lazy" class="card-img" alt="Document Authentication" 
                                        />
                                        
                                        <div class="card-body  pt-3">
                                            <h2 class="h4"  style={{textAlign: 'center', marginTop: '30px', color: '#0067b8', textDecoration: 'underline'}}>
                                                {value.title}
                                            </h2>
                                            
                                            <p style={{textAlign: 'center', paddingRight: '12px'}}>
                                                {value.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </div>

            <SolutionsDialogComponent 
                openSolutions={openSolutions} 
                handleClose={handleClose} 
                description={description} 
                title={title} name={name}
            />
        </Box>
    )

}

export default ProductPortfolioComponent
import { Box, Button, Grid, Typography } from "@mui/material";
import useWidth from "../hooks/useWidth";
import background from "../images/banner1.jpg";
import eMarkLogo from "../icons/eMarkSuperFinalLogoSize.png";
import eMarkBanner from "../images/ovcode-api-banner1.png";

const ElectronicMarkLandingScreen = () => {
    const breakpoints = useWidth();

    // Set overflow: hidden on the html and body elements to prevent scrolling
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                minHeight: '90vh', // Use 100vh for full viewport height
                overflow: 'hidden',
                width: '100%',
                padding: 0, // Remove padding
                margin: 0, // Remove margin
            }}
        >
            <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', width: '100%' }}>
                <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '1rem!important', paddingLeft: '2rem !important' }}>
                                    <Box sx={{ mb: 5 }}>
                         <Typography
                            sx={{
                                fontSize: 29,
                                margin: 0,
                                lineHeight: 1.6,
                                fontWeight: 700,
                                textAlign: 'start',
                                color: "#000"
                            }}
                        >
                            Electronic Mark (eMark)
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 4, textAlign: 'start' }}>
                        <img src={eMarkLogo} style={{ width: '45%', textAlign: 'start' }} />
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Grid container>
                            <Grid item xs={5} sx={{ textAlign: 'start' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { window.open('https://dev-docs.ovcode.com/api-documentation/category', '_blank'); }}
                                    sx={{ width: '90%', fontSize: 14, height: 40, color: '#ffff', backgroundColor: '#017eff', textTransform: 'none', borderRadius: 10, '&:hover': { backgroundColor: 'transparent', borderColor: '#017eff', color: '#017eff' } }}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '1rem!important', paddingRight: '0px !important' }}>
                <img src={eMarkBanner} style={{ width: '100%' }} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ElectronicMarkLandingScreen;



// import { Box, Button, Grid, Typography } from "@mui/material"
// import useWidth from "../hooks/useWidth"
// import background from "../images/banner1.jpg";
// import eMarkLogo from "../icons/eMarkSuperFinalLogoSize.png";
// import eMarkBanner from "../images/ovcode-api-banner1.png"


// const ElectronicMarkLandingScreen = () => {
//     const breakpoints = useWidth();

//     return (
//         <Box sx={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             minHeight: 500,
//             maxHeight: 500,
//             alignItems: 'center',
//             overflow: 'hidden',
//             width: ' 100%',
//         }}>
//             <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center' }}>
//                 <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '1rem!important' }}>
//                     <Box sx={{ mb: 5 }}>
//                         <Typography
//                             sx={{
//                                 fontSize: 29,
//                                 margin: 0,
//                                 lineHeight: 1.6,
//                                 fontWeight: 700,
//                                 textAlign: 'start',
//                                 color: "#000"
//                             }}
//                         >
//                             Electronic Mark (eMark)
//                         </Typography>
//                     </Box>
//                     <Box sx={{ mb: 4, textAlign: 'start' }}>
//                         <img src={eMarkLogo} style={{ width: '50%', textAlign: 'start' }} />
//                     </Box>
//                     <Box sx={{ mb: 4 }}>
//                         <Grid container>
//                             <Grid item xs={5} sx={{ textAlign: 'start' }}>
//                                 <Button
//                                     variant="outlined"
//                                     onClick={() => { window.open('https://dev-docs.ovcode.com/api-documentation/category', '_blank'); }}
//                                     sx={{ width: '90%', fontSize: 14, height: 40, borderColor: '#017eff', color: '#017eff', textTransform: 'none', borderRadius: 0, '&:hover': { backgroundColor: 'transparent', borderColor: '#017eff', color: '#017eff' } }}
//                                 >
//                                     Get Started
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Grid>
//                 <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '1rem!important' }}>
//                     <img src={eMarkBanner} style={{ width: '100%' }} />
//                 </Grid>
//             </Grid>
//         </Box>
//     )
// }

// export default ElectronicMarkLandingScreen;

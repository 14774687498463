import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../../../hooks/useWidth"
import ElectronicSignatureBannerComponent from "./electronic-signature-banner.component";
import AboutElectronicSignatureComponent from "./about-electronic-signature.component";
import ExploreElectronicSignatureComponent from "./explore-electronic-signature.component";
import DrawerHeader from "../../layout/drawer-header";
import BackToTopButton from "../../../layout/scroll-up.component";
import Chatbot from "../../../layout/chatbot.component";
import { useState } from "react";



const ElectronicSignatureComponent = () => {

    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);


    return (
        <Box>

            {/* <div class="container">
                { isChatbotOpen ?
                    null
                :
                    <BackToTopButton isChatbotOpen={isChatbotOpen} />
                }
                <Chatbot setIsChatbotOpen={setIsChatbotOpen} />

            </div> */}
           
           <ElectronicSignatureBannerComponent />

           <Box sx={{backgroundColor: '#E2E2E6'}}>
                <AboutElectronicSignatureComponent />
            </Box>

            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '93%',}}>
                <ExploreElectronicSignatureComponent />
            </Box>
            
        </Box>
    )
}

export default ElectronicSignatureComponent;

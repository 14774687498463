import React from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import useWidth from '../../../hooks/useWidth';
import productSupport from '../../../images/product-support.png'
import LazyLoad from "react-lazyload";


const ProductSupportComponent = () => {

  const breakpoints = useWidth();

  const showAlert = () => {
    alert("Coming soon, our team is working on the link.");
  };

  return (
    <div class="container">
      <Box sx={{
        minHeight: 500,
        maxHeight: 500,
        overflow: 'hidden',
        margin: 'auto 0',
        width: ' 100%'
      }}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={7} sx={{ height: 500 }}>
            <Grid container sx={{ height: '100%' }}>
              <Grid item sx={{ width: '100%' }}>
                <LazyLoad height={200}>
                  <img
                    src={productSupport}
                    style={{
                      imageRendering: 'pixelated',
                      imageRendering: '-webkit-optimize-contrast',
                      width: '100%',
                      objectFit: 'cover',
                      height: '100%',
                    }}
                    alt="Enterprise Support"
                  />
                </LazyLoad>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: '3rem!important', pb: 0 }}>
            <Box sx={{ mb: 5 }}>
              <Typography
                sx={{
                  fontSize: 29,
                  margin: 0,
                  lineHeight: 1.2,
                  fontWeight: 700,
                  textAlign: 'start',
                  color: "#000"
                }}
              >
                Welcome to OVCODE Product Support
              </Typography>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontSize: 16,
                  margin: 0,
                  lineHeight: 1.6,
                  color: 'rgb(100, 110, 115)',
                  fontWeight: 400,
                  textAlign: 'start',
                  textJustify: 'inter-word',
                  color: "#000",
                }}
              >
                We provide support in all product solutions.
              </Typography>
            </Box>
            <Box >
              <Grid container>
                <Grid item xs={6} sx={{ textAlign: 'start' }}>
                  <Button variant="outlined"
                    sx={{ width: '60%', fontSize: 14, height: 40, borderColor: '#017eff', color: '#017eff', textTransform: 'none', borderRadius: 0, '&:hover': { backgroundColor: 'transparent', borderColor: '#017eff', color: '#017eff' } }}
                    onClick={() => window.open('https://itop.ovcode.com', '_blank')}
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ProductSupportComponent;

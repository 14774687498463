import { Box, Card, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import ovcode_news_images from "./ovcode-new-images.component";


function OvcodeNewsComponent() {

    const breakpoints = useWidth();

    function goToPath(path){
        window.location.href = path;
    }
    

    return(

        <Box>

            <Box sx={{mb: 4}}>
                <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
                    Explore OVCODE
                </Typography>
            </Box>

            <Box sx={{mb: 4, padding: 3, backgroundColor: '#80808024'}} >
                <Grid container spacing={2} sx={{alignItem: 'center', justifyContent: 'center'}}>
                    {ovcode_news_images.map((value)=>{
                        return(
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                <Card sx={{boxShadow: 'none', borderRadius: 2,':hover' :{boxShadow: 'rgba(200, 200, 200) 0px 7px 29px 0px'}, height: (breakpoints == "md") ? 320 : {} }} >
                                    <Box sx={{margin: 2}}>
                                        <Link  target="_blank" href={value.path} underline="none">
                                            <CardMedia
                                                component="img"
                                                image={value.icon}
                                                style={{objectFit: 'contain', margin:'auto'}}
                                            />
                                        </Link>
                                        <Typography sx={{textAlign: 'start',fontWeight: 600, mt: 1, pt: 1,pb: 2,fontSize: 20,lineHeight: 1.6}}>
                                            {value.name}
                                        </Typography>
                                        <CardContent sx={{pl: 0, pr: 0,height: 90}}>
                                            <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', lineHeight: 1.5,  textAlign: 'justify',  color: 'rgb(100, 110, 115)'}}>
                                                {value.description}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
}

export default OvcodeNewsComponent
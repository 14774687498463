import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 25px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: bottom 0.3s ease; // Add a smooth transition for better user experience
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0px;
`;


const Chatbot = (props) => {

    const { setIsChatbotOpen } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    setIsChatbotOpen(!isOpen);
  };

  return (
    <>
      <ChatbotContainer isOpen={isOpen}>
        {isOpen && (
          <>
            <CloseButton onClick={toggleChatbot}>
              <IconButton aria-label="close" style={{color: '#fff',  marginBottom: '-55px'}}>
                <CancelRoundedIcon />
              </IconButton>
            </CloseButton>
            <iframe
              src="https://web.powerva.microsoft.com/environments/Default-3ae1f3d7-162d-4936-be5c-f5ed0b4eaaa5/bots/cr782_nextIxSupportGroup/webchat?__version__=2"
              frameBorder="0"
              style={{ height: 400, width: 320 }} // Adjust the size as needed
            ></iframe>
          </>
        )}
      </ChatbotContainer>
      {!isOpen && (
        <ChatbotContainer>
          <Button
            onClick={toggleChatbot}
            variant="contained"
            sx={{
              backgroundColor: 'rgb(11, 85, 106)',
              color: 'rgb(255, 255, 255)',
              fontWeight: 600,
              height: 40,
              padding: '0px 13px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 250,
              textAlign: 'left',
              fontSize: 14,
              textTransform: 'none',
              '&:hover': { backgroundColor: 'rgb(11, 85, 106)' },
            }}
          >
            Ask OVCODE
          </Button>
        </ChatbotContainer>
      )}
    </>
  );
};

export default Chatbot;

import ovcodeImageOne from '../../../../images/ovcode-insights/insights-1.png';
import ovcodeImageTwo from '../../../../images/ovcode-insights/insights-2.png';
import ovcodeImageThree from '../../../../images/ovcode-insights/insights-3.png';
import ovcodeImageFour from '../../../../images/ovcode-insights/insights-4.png';
import ovcodeImageFive from '../../../../images/ovcode-insights/insights-5.png';
import ovcodeImageSix from '../../../../images/ovcode-insights/insights-6.png';
import ovcodeImageSeven from '../../../../images/ovcode-insights/insights-7.png';
import ovcodeImageEight from '../../../../images/ovcode-insights/insights-8.png';
import ovcodeImageNine from '../../../../images/ovcode-insights/insights-9.png';
import ovcodeImageTen from '../../../../images/ovcode-insights/insights-10.png';
import ovcodeImageEleven from '../../../../images/ovcode-insights/insights-11.png';
import ovcodeImageTwelve from '../../../../images/ovcode-insights/insights-12.png';
import ovcodeImageThirteen from '../../../../images/ovcode-insights/insights-13.png';




const insightsData = [
    {
        image: ovcodeImageOne,
        title: "The Evolution of Digital Trust: A Deep Dive into OVCODE",
        date: "October 09, 2023",
        description: "Explore the historical context and development of digital trust, and discuss OVCODE's role in shaping this evolution.",
        url: "/blog-ovcode-insights-evolution"
    },
    {
        image: ovcodeImageTwo,
        title: "Why Digital Trust Matters in Today's World",
        date: "October 09, 2023",
        description: "Highlight the importance of digital trust in various industries and how OVCODE addresses the growing need for secure digital transactions.",
        url: "/blog-ovcode-insights-digital-trust"
    },
    {
        image: ovcodeImageThree,
        title: "OVCODE: A Trailblazer in Blockchain Technology",
        date: "October 09, 2023",
        description: "Showcase how OVCODE leverages blockchain technology to provide a robust and secure solution for digital trust.",
        url: "/blog-ovcode-insights-trailblazer-blockchain"
    },
    {
        image: ovcodeImageFour,
        title: "Securing the Future: A Look into OVCODE's Vision",
        date: "October 09, 2023",
        description: "Discuss OVCODE's long-term vision for the future of digital trust and its potential impact on industries.",
        url: "/blog-ovcode-insights-securing-future"
    },
    {
        image: ovcodeImageFive,
        title: "OVCODE in the News: Latest Updates and Media Coverage",
        date: "October 09, 2023",
        description: "Compile and summarize recent news articles and media coverage featuring OVCODE to keep your audience informed.",
        url: "/blog-ovcode-insights-in-news"
    },
    {
        image: ovcodeImageSix,
        title: "Beyond PKI: How OVCODE Integrates Blockchain for Next-Level Authentication",
        date: "April 01, 2024",
        description: "Discover how OVCODE transcends traditional Public Key Infrastructure (PKI) by integrating blockchain technology to offer superior authentication services. This innovative approach provides enhanced security, ensuring that digital interactions are more reliable and verifiable than ever before.",
        url: "/blog-ovcode-insights-beyond-kpi"
    },
    {
        image: ovcodeImageSeven,
        title: "The Power of AATL  Certification in OVCODE's Trust Infrastructure",
        date: "April 01, 2024",
        description: "OVCODE harnesses the power of technologies certified by the Adobe Approved Trust List (AATL) through its partnership with Entrust, ensuring the highest level of security and trust in its infrastructure. This alignment with AATL-certified entities allows OVCODE to offer an unparalleled standard of authentication, giving users confidence in the integrity and reliability of their digital transactions. By prioritizing top-tier security certifications, OVCODE demonstrates its commitment to maintaining a robust and secure digital environment for all its users.",
        url: "/blog-ovcode-insights-power-of-AATL-certification"
    },
    {
        image: ovcodeImageEight,
        title: "OVCODE’s Blockchain-Powered PKI: A New Paradigm for Digital Trust",
        date: "April 01, 2024",
        description: "Experience a new standard in digital trust with OVCODE’s blockchain-powered PKI solutions. This novel approach brings the robust security of blockchain to the tried-and-tested PKI systems, creating a more dynamic and secure framework for digital identity management.",
        url: "/blog-ovcode-insights-powered-kpi"
    },
    {
        image: ovcodeImageNine,
        title: "OVCODE’s End-to-End Encryption: Securing Data with AATL-Certified Practices",
        date: "April 01, 2024",
        description: "Secure every piece of data from start to finish with OVCODE's end-to-end encryption, underpinned by practices certified by AATL members. Understand how this encryption fortifies the privacy and security of digital information across any platform.",
        url: "/blog-ovcode-insights-end-to-end-encryption"
    },
    {
        image: ovcodeImageTen,
        title: "The Significance of AATL Certification for OVCODE's Blockchain Solutions",
        date: "May 06, 2024",
        description: "Grasp the importance of AATL certification in reinforcing the trustworthiness of OVCODE's blockchain solutions. This endorsement is key for users seeking to leverage blockchain's potential with the assurance of recognized security standards.",
        url: "/blog-ovcode-insights-significance-of-certification"
    },
    {
        image: ovcodeImageEleven, 
        title: "OVCODE: Setting the Standard for Secure Digital Transactions", 
        date: "May 06, 2024", 
        description: "OVCODE is raising the bar for secure digital transactions. Explore how its commitment to security excellence sets a new standard for trust and reliability in the digital space, providing peace of mind for users engaging in various online activities.", 
        url: "/blog-ovcode-insights-setting-standard-for-digital-transactions"
    },
    // {
    //     image: ovcodeImageTwelve, 
    //     title: "How OVCODE's AATL-Certified Blockchain Technology Is Revolutionizing Secure Storage", 
    //     date: "April 01, 2024", 
    //     description: "Witness the revolution in secure storage with OVCODE’s blockchain technology, certified by AATL members. This post will detail the innovative ways OVCODE is providing users with secure, blockchain-backed storage solutions that set new industry benchmarks.", 
    //     url: ""
    // },
    // {
    //     image: ovcodeImageThirteen, 
    //     title: "Automating Compliance with OVCODE's Blockchain Tech", 
    //     date: "April 01, 2024", 
    //     description: "Compliance is a major challenge for businesses in regulated industries. OVCODE automates and simplifies this process with its blockchain technology, ensuring that companies can easily meet regulatory requirements while minimizing operational overhead.", 
    //     url: ""
    // },

];


export { insightsData }
import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Paper, Typography } from "@mui/material"
import React from "react";

import ovcodeImageOne from '../../../../images/eSign-in-depth/in-depth-1.png';
import ovcodeImageTwo from '../../../../images/eSign-in-depth/in-depth-2.png';

const ElectronicSignatureInDepthTopicsComponent = () => {

    return (
        <Box>
            <div class="container-blog">
                <Card 
                    sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', width: '90%', margin: 'auto' }}
                    onClick={() => window.location.href = "/blog-esign-document-workflow"}
                >
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'start' }}>
                                <img src={ovcodeImageOne} style={{ width: '80%' }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ textAlign: 'start' }}>
                                        <ListItemText primary={
                                            <React.Fragment>
                                                <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem' }}>
                                                    Transforming Document Workflows: OVCODE eSign Explained
                                                </Typography>
                                            </React.Fragment>
                                        } secondary={
                                            <React.Fragment>
                                                <Typography sx={{ fontSize: '1rem' }}>October 09, 2023</Typography>
                                            </React.Fragment>
                                        } />
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                                        <Typography>
                                            Provide insights into how OVCODE eSign streamlines
                                            document processes for businesses.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

            <Box sx={{ mt: 5 }} />

            <div class="container-blog" style={{ overflow: 'hidden' }}>
                <Card 
                    sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', width: '90%', margin: 'auto' }}
                    onClick={() => window.location.href = "/blog-esign-unlocking-efficiency"}
                >
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'start' }}>
                                <img src={ovcodeImageTwo} style={{ width: '80%' }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ textAlign: 'start' }}>
                                        <ListItemText primary={
                                            <React.Fragment>
                                                <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem' }}>
                                                    Unlocking Efficiency: A Guide to Implementing eSign in Your Workflow
                                                </Typography>
                                            </React.Fragment>
                                        } secondary={
                                            <React.Fragment>
                                                <Typography sx={{ fontSize: '1rem' }}>October 09, 2023</Typography>
                                            </React.Fragment>
                                        } />
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                                        <Typography>
                                            Offer practical tips and advice on integrating eSign into
                                            various business workflows.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Box>
    )
}

export default ElectronicSignatureInDepthTopicsComponent
import ovcodeImageOne from '../../../../images/support-and-community/support-1.png';
import ovcodeImageTwo from '../../../../images/support-and-community/support-2.png';
import ovcodeImageThree from '../../../../images/support-and-community/support-3.png';




const suportAndCommunityData = [
    {   
        image: ovcodeImageOne, 
        title: "Navigating OVCODE: A Comprehensive Guide to User Support", 
        date: "October 09, 2023", 
        description: "Provide an overview of the support services offered by OVCODE and guide users on how to access assistance.", 
        url: "/blog-support-navigating-ovcode"
    },
    {
        image: ovcodeImageTwo, 
        title: "Community Spotlight: Stories from the OVCODE User Community", 
        date: "October 09, 2023", 
        description: "Share success stories, experiences, and contributions from the OVCODE user community.", 
        url: "/blog-support-community-spotlight"
    },
    {
        image: ovcodeImageThree, 
        title: "Navigating Secure Storage Options with OVCODE: Cloud vs. On-Premises", 
        date: "May 06, 2024", 
        description: "With OVCODE, navigate the choices between cloud and on-premises storage confidently. This comparative insight provides clarity on how OVCODE's security measures are tailored to fit both storage options, ensuring data sovereignty and compliance.", 
        url: "/blog-support-navigating-secure-storage"
    },
    
    


];


export { suportAndCommunityData }
import environment from "../../../environment/environment";
import routes from "../../api/common/api-routes";
import {CustomHttpPost, CustomHttpPostParams, CustomHttpGet, HttpDownloadFile, CustomHttpGetParams} from "../common/http-request-builder";

const SUBSCRIPTION_KEY = environment.ovcode_website_service.subscriptionKey;

const uploadData = (hash) => {
    
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = CustomHttpPost(routes.HASH_VERIFICATION + "?hash=" + hash, subscriptionKey);

    return result;
}

const verifyReference = (reference) => {

    let query_params = {
        reference: reference
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = CustomHttpPostParams(routes.VERIFY_REFERENCE + "?reference=" + reference, query_params, subscriptionKey);

    return result;

}

const generateSharedKey = (reference) => {
    let query_params = {
        reference: reference
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = CustomHttpGet(routes.GENERATE_SHARED_KEY + "?reference=" + reference, query_params, subscriptionKey);

    return result;
}

const downloadUsingSharedKey = (sharedKey) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDownloadFile(routes.DOWNLOAD_USING_SHARED_KEY, sharedKey, subscriptionKey);

    return result;
}

const getDownloadedData = (sharedKey) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = CustomHttpGetParams(routes.DOWNLOAD_USING_SHARED_KEY, sharedKey, subscriptionKey);

    return result;
}


const VerificationService = {
    uploadData: uploadData,
    verifyReference: verifyReference,
    generateSharedKey: generateSharedKey,
    downloadUsingSharedKey: downloadUsingSharedKey,
    getDownloadedData: getDownloadedData
}

export default VerificationService
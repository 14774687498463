import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const integration_support = [
    {
        name: "Can I integrate OVCODE with my existing systems?  ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Yes, OVCODE offers integration capabilities through its API Gateway (Azure APIM), allowing you to securely connect OVCODE's solutions with your systems. 
                </Typography>
            </Box>
    },
    {
        name: "Is customization available for OVCODE solutions? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVCODE offers ready-to-use solutions, but additional customization may be considered upon request. Customization timelines and costs will be discussed based on your specific requirements. 
                </Typography>
            </Box>
    }

]

export default integration_support

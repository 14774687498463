import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Grid, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import CryptoJS from 'crypto-js';
import useVerification from '../../../hooks/useVerification';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import { ThreeDots } from 'react-loader-spinner';




function getHash(algorithm, data) {
    const main = async (msgUint8) => {
        const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    };

    if (data instanceof Blob) {
        return new Promise(async (resolve) => {
            const arrayBuffer = await data.arrayBuffer();
            const msgUint8 = new Uint8Array(arrayBuffer);
            resolve(await main(msgUint8));
        });
    }

    const encoder = new TextEncoder();
    const msgUint8 = encoder.encode(data);

    return main(msgUint8);
}



const DataUploaderComponent = (props) => {

    const { breakpoints, openUpload, setOpenUpload, setOpenUnverifiedData, setOpenVerifiedData } = props;

    const { uploadData, verifying_uploaded_data } = useVerification();

    const [file, setFile] = useState(null);


    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf('.') + 1;

        setFile(file);
    };


    useEffect(() => {
        const getFileHash = async (file) => {
            const hashHex = await getHash("SHA-256", file);
            console.log("hashHexxxxx :", hashHex);
            uploadData(hashHex, setOpenUpload, setOpenVerifiedData, setOpenUnverifiedData);
        };

        if (file) {
            getFileHash(file);
        }

    }, [file]);



    function PageLoader() {
        return (
            <Backdrop open={verifying_uploaded_data} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                {/* <ThreeDots height="80" width="80" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> */}
                <Button 
                    variant="text"
                    sx={{color: '#ffff', textTransform: 'none', fontSize: 24}}
                    startIcon={<ThreeDots height="80" width="80" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    Verifying document
                </Button>
            </Backdrop>
        )
    }


    return (

        <Grid container spacing={2} sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 18, pt: 0, pb: 0, justifyContent: 'center', alignItems: 'center' }}>

            {verifying_uploaded_data ?

                <PageLoader />

            :
                <Grid item xs={12}>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Drag and Drop file here or click here to upload"
                        children={
                            <>
                                {(file) ?
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            border: "dashed 2px #0178f2",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "280px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear"
                                        }}
                                    >
                                    </div>
                                    :
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            // border: "dashed 2px #8080809e",
                                            border: "dashed 2px #017eff",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "280px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear",
                                            backgroundColor: 'white'
                                        }}
                                    >

                                        <Grid container>
                                            <Grid item xs={12}>
                                                <CloudUploadOutlinedIcon sx={{ fontSize: 80, color: '#017eff' }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : 15 }}>
                                                    Drag any file here to verify
                                                </Typography>
                                                <Typography style={{ color: "#666", fontWeight: 500, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : 14 }}>
                                                    or click to select a file from your device
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </>
                        }
                    />
                </Grid>

            }

        </Grid>

    )

}

export default DataUploaderComponent

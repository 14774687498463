import {useState} from "react";
import JoinService from "../services/api/join";

const useJoin = () => {

    const [isJoining, setJoining] = useState(false);

    const joinOVCode = (firstName, lastName, companyName, inquiryDetails, email, contactNo, website, setOpenSuccessConfirmation) => {
        setJoining(true);

        JoinService.joinOVCode(firstName, lastName,companyName, inquiryDetails, email, contactNo, website).then((res) => {
            console.log("joinOVCode data xxxx", res);

            if (res.data.success) {
                setJoining(false);
                setOpenSuccessConfirmation(true);
            }

        }).catch((error) => {
            console.log("joinOVCode error xxxx", error);

            setJoining(false);
        })
    }

    return {isJoining, joinOVCode}
}

export default useJoin
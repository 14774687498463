import { Box, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import OVCODEGOVERNMENT from '../../../images/industries/government.png';
import OVCODECOVID from '../../../images/industries/healthcare.png';
import OVCODECONTRACT from '../../../images/industries/finance.png';
import OVCODESUPPLYCHAIN from '../../../images/industries/supply2.jpg';

const IndustriesWeServeComponent = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{ overflow: 'hidden' }}>

            <Box sx={{ mb: 2, mt: 5 }}>
                <Typography sx={{
                    fontSize: 37,
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: 'start',
                    color: "#000",
                    mb: '1.25rem'
                }}>
                    Industries We Serve
                </Typography>
            </Box>
            <section>
                <div class="columns is-gapless-mobile has-large-gaps" style={{ paddingBottom: '0px' }}>
                    <div class="column is-half display-flex" >
                        <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs">
                            {/* <figure class="image"> */}
                            <img alt="Government" data-linktype="absolute-path" src={OVCODEGOVERNMENT} style={{ width: '100%' }} />
                            {/* </figure> */}
                        </div>
                    </div>
                    <div class="column is-half display-flex">
                        <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs" style={{ textAlign: 'center' }}>
                            <h6 style={{ fontWeight: "bold" }} >Government</h6>
                            <p class="font-size-lg">Enhance citizen services, streamline operations, and secure sensitive data with OVCODE's solutions tailored for government agencies.</p>
                        </div>
                    </div>
                </div>

                <div class="uhf-container padding-top-lg" style={{ paddingBottom: '0px' }}>
                    <div class="columns is-gapless-mobile has-large-gaps">
                        <div class="column is-half display-flex">
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs" style={{ textAlign: 'center' }}>
                                <h6 style={{ fontWeight: "bold" }} >Finance</h6>
                                <p class="font-size-lg">From banks to fintech companies, our digital signature and authentication solutions are designed to optimize financial processes.</p>
                            </div>
                        </div>
                        <div class="column is-half display-flex">
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs">
                                {/* <figure class="image  "> */}
                                <img alt="Finance" data-linktype="absolute-path" src={OVCODECONTRACT} style={{ width: '100%' }} />
                                {/* </figure> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="uhf-container padding-block-lg" style={{ paddingBottom: '0px' }}>
                    <div class="columns is-gapless-mobile has-large-gaps">
                        <div class="column is-half display-flex">
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs">
                                {/* <figure class="image  "> */}
                                <img alt="Healthcare" data-linktype="absolute-path" src={OVCODECOVID} style={{ width: '100%' }} />
                                {/* </figure> */}
                            </div>
                        </div>
                        <div class="column is-half display-flex">
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs" style={{ textAlign: 'center' }}>
                                <h6 style={{ fontWeight: "bold" }} >Healthcare</h6>
                                <p class="font-size-lg">Protect patient data, improve security, and enhance healthcare operations with OVCODE's biometric verification and authentication services.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="uhf-container padding-top-lg" style={{ paddingBottom: '0px' }}>
                    <div class="columns is-gapless-mobile has-large-gaps">
                        <div class="column is-half display-flex">
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs" style={{ textAlign: 'center' }}>
                                <h6 style={{ fontWeight: "bold" }} >Supply Chain</h6>
                                <p class="font-size-lg">Combat counterfeit products, ensure product provenance, and boost consumer trust in supply chain management.</p>
                            </div>
                        </div>
                        <div class="column is-half display-flex" style={{ opacity: 0.8 }}>
                            <div class="is-full-width display-flex flex-direction-column flex-grow-1 justify-content-center  padding-block-xs">
                                {/* <figure class="image  "> */}
                                <img alt="Supply Chain" data-linktype="absolute-path" src={OVCODESUPPLYCHAIN} style={{ width: '100%', height: "240px", objectFit: "cover" }} />
                                {/* </figure> */}
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* <div class="container" style={{ marginBottom: 5 }}>
                <div class="content-card carouselbase carousel aem-GridColumn aem-GridColumn--default--12">
                    <section id="content-card-uid55d6">
                        <div class="row row-cols-1   row-cols-sm-2 row-cols-lg-4">
                            <div class="col  mb-5 mb-lg-0">
                                <div class="card">
                                   
                                    <div class="card-body  pt-3">
                                        <h2 class="h3" style={{ textAlign: 'start' }} >
                                        Government
                                        </h2>
                                        <p style={{ textAlign: 'start' }}>Enhance citizen services, streamline operations, and secure sensitive data with OVCODE's solutions tailored for government agencies.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col mb-5 mb-lg-0">
                                <div class="card">
                                   

                                    <div class="card-body  pt-3">
                                        <h2 class="h3" style={{ textAlign: 'start' }} >
                                        Finance
                                        </h2>
                                        <p style={{ textAlign: 'start' }}>From banks to fintech companies, our digital signature and authentication solutions are designed to optimize financial processes.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col mb-5 mb-sm-0" >
                                <div class="card">
                                   
                                    <div class="card-body  pt-3">
                                        <h2 class="h3" style={{ textAlign: 'start' }} >
                                        Healthcare
                                        </h2>
                                        <p style={{ textAlign: 'start' }}>Protect patient data, improve security, and enhance healthcare operations with OVCODE's biometric verification and authentication services.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="col" >
                                <div class="card">
                                  
                                    <div class="card-body  pt-3">
                                        <h2 class="h3" style={{ textAlign: 'start' }} >
                                        Supply Chain
                                        </h2>

                                        <p style={{ textAlign: 'start' }}>Combat counterfeit products, ensure product provenance, and boost consumer trust in supply chain management.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div > */}
        </Box>
    )
}

export default IndustriesWeServeComponent
import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import JoinNowBanner from "../../../../../icons/join-now-banner2.png";


const WhyDigitalTrustMatterComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Why Digital Trust Matters in Today's World
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Friday, 12 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={JoinNowBanner} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the vast landscape of the digital age, trust has become the cornerstone of our online
                                interactions. Whether it's financial transactions, healthcare records, or supply chain
                                management, the reliability of digital systems is paramount. Enter OVCODE—a beacon in the
                                digital trust revolution.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Foundation of Digital Interactions</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In a world where digital transactions are ubiquitous, trust forms the bedrock of our daily
                                interactions. Consumers, businesses, and institutions alike depend on the assurance that
                                their digital engagements are secure, transparent, and tamper-proof.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Vulnerabilities of the Digital Realm</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The digital landscape, while immensely convenient, is not without its vulnerabilities. Cyber
                                threats, data breaches, and identity theft loom large, emphasizing the critical need for
                                robust mechanisms to safeguard digital assets. OVCODE steps into this breach as a
                                formidable guardian of digital trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE: Safeguarding Every Transaction</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE isn't just a solution; it's a shield against the vulnerabilities that plague digital
                                transactions. By leveraging cutting-edge blockchain technology, OVCODE introduces an
                                unalterable and transparent layer of security to every interaction.
                            </Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0 }}>
                                <ul>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Financial Transactions:</Typography>
                                        <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                            In the financial sector, where the stakes are high, OVCODE ensures that each
                                            transaction is verifiable, secure, and resistant to manipulation. This is not just about
                                            securing financial assets; it's about safeguarding the trust between parties.
                                        </Typography>
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Healthcare Records:</Typography>
                                        <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                            In healthcare, where sensitive patient data is at play, OVCODE guarantees the
                                            integrity of records. Patients and healthcare providers can rely on the fact that the
                                            information they access is accurate, unaltered, and trustworthy.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography sx={{ fontWeight: 'bold' }}>Supply Chain Management:</Typography>
                                        <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                            For industries reliant on intricate supply chains, OVCODE's tamper-proof technology
                                            ensures the authenticity of each link in the chain. From manufacturing to delivery,
                                            trust is embedded in every step.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>A Global Paradigm Shift</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The need for digital trust isn't confined to a particular sector or region; it's a global imperative.
                                OVCODE's impact resonates across borders, offering a standardized solution to a universal
                                challenge. In a world interconnected by digital threads, the assurance of trust is a currency
                                that knows no boundaries.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE: Bridging the Trust Gap</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE emerges not just as a platform but as a solution to bridge the trust gap in the digital
                                era. It stands as a testament to the belief that digital interactions should be synonymous with
                                security, transparency, and integrity.
                            </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                In conclusion, digital trust is not a luxury but a necessity. It forms the backbone of a reliable
                                and resilient digital ecosystem. OVCODE, with its commitment to secure and transparent
                                transactions, is not just meeting this need; it's redefining the very essence of digital trust.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-trailblazer-blockchain"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE: A Trailblazer in Blockchain Technology
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Showcase how OVCODE leverages blockchain technology to provide a robust and secure solution for digital trust.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550  }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-securing-future"}     
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Securing the Future: A Look into OVCODE's Vision
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss OVCODE's long-term vision for the future of digital trust and its potential impact on industries.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550   }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    )
}

export default WhyDigitalTrustMatterComponent
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 50px;
  // bottom: ${({ isScrollButtonVisible, isChatbotOpen }) => isScrollButtonVisible && isChatbotOpen ? '330px' : '50px'};
  // bottom: 32px;
  // bottom: ${({ isScrollButtonVisible, isAtTop }) => isScrollButtonVisible && isAtTop ? '32px' : '6498px'};
  // bottom: ${({ isAtTop }) => (isAtTop ? '32px' : '6827px')};
  right: 25px;
  align-items: center;
  height: 25px;
  width: 100px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  padding: .625rem .75rem;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: #e2e2e2;
  color: #000;
  opacity: 1;
  font-weight: 400px;
  border-radius: 4px;
  font-size: 15px;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? 'flex' : 'none'};
`;

const BackToTopButton = (props) => {  

  const { isChatbotOpen } = props;
  const [showButton, setShowButton] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  console.log("isChatbotOpenxx", isChatbotOpen)

  useEffect(() => {
    const checkScrollHeight = () => {
      const yOffset = window.pageYOffset;

      console.log("yOffsetxxx", yOffset)

      if (yOffset < 6498) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }

      if (!showButton && yOffset > 400) {
        setShowButton(true);
      } else if (showButton && yOffset <= 400) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollHeight);
    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ButtonContainer isScrollButtonVisible={showButton} isAtTop={isAtTop} onClick={scrollToTop} isChatbotOpen={isChatbotOpen} >
      <ExpandLessIcon />Back to top
    </ButtonContainer>
  );
};

export default BackToTopButton;

import { Box, Grid, Typography } from "@mui/material";
import electronicSignatureOne from "../../../../images/electronic-signature/one.png";
import electronicSignatureTwo from "../../../../images/electronic-signature/two.png";
import electronicSignatureThree from "../../../../images/electronic-signature/three.png";
import useWidth from "../../../../hooks/useWidth";

const ExploreElectronicSignatureComponent = () => {

    const breakpoints = useWidth();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: 6, pt: 3, pb: 3 }}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: 29,
                        margin: 0,
                        lineHeight: 1.2,
                        fontWeight: 700,
                        textAlign: 'center',
                        color: "#000",
                    }}>
                       Explore the eSign Platform
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mt: breakpoints === 'xs' ? 1 : 5 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                            {/* <Typography sx={{
                                fontSize: 29,
                                margin: 0,
                                lineHeight: 1.2,
                                fontWeight: 700,
                                textAlign: 'start',
                                color: "#000"
                            }}>
                                Introducing Electronic Signature
                            </Typography> */}
                            <Grid container spacing={2} sx={{ mt: 5 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '80%',
                                        ml: (breakpoints == "xl") ? "71px !important" : (breakpoints == "lg") ? "42px !important" : {},
                                    }}>
                                        Upload files
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '80%',
                                        ml: (breakpoints == "xl") ? "71px !important" : (breakpoints == "lg") ? "42px !important" : {},
                                        mt: "-5px !important",
                                        mb: "8px !important",
                                    }}>
                                        Effortlessly upload files, rename them, and easily replace the uploaded documents as needed.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <img alt="" src={electronicSignatureOne} style={{width: '80%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={1} sx={{ justifyContent: 'center!important', display: 'flex!important' }}>
                            <div style={{
                                borderBottomWidth: '.0625rem!important',
                                borderBottomStyle: 'solid',
                                borderBottomColor: '#d2d2d2'
                            }}></div>
                            <div
                                style={{
                                    borderRightWidth: '.0625rem!important',
                                    borderRightStyle: 'solid',
                                    borderRightColor: '#d2d2d2'
                                }}></div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        Add elements 
                                    </Typography>
                                    <br />
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        Unleash your creativity by seamlessly integrating elements through a user-friendly drag-and-drop feature.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img src={electronicSignatureTwo} style={{width: '100%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                                <div style={{
                                    borderBottomWidth: '.0625rem!important',
                                    borderBottomStyle: 'solid',
                                    borderBottomColor: '#d2d2d2'
                                }}></div>
                                <div
                                    style={{
                                        borderRightWidth: '.0625rem!important',
                                        borderRightStyle: 'solid',
                                        borderRightColor: '#d2d2d2'
                                    }}></div>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        Sign documents
                                    </Typography>
                                    <br />
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        Signing documents is a breeze – just click where you want to sign and personalize your signature with ease.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img src={electronicSignatureThree} style={{width: '100%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    )
}

export default ExploreElectronicSignatureComponent;
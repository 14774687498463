import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { PlayArrow, Pause, NavigateBefore, NavigateNext } from '@mui/icons-material';
import LandingBannerComponent from './landing-banner.component';
import LandingEmpoweringComponent from './landing-empowering.component';
// import LandingProductsComponent from './landing-products.component';
import LandingSolutionsComponent from './landing-solutions.component';
import AboutUsComponent from '../../about/about-us.component';

const images = [
  <LandingBannerComponent />,
  // <AboutUsComponent />,
  <LandingEmpoweringComponent />,
  <LandingSolutionsComponent />,
];

const LandingSlider = (props) => {
  const { setCurrentImage } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
      }, 20000); // Change slide
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying]);

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Card sx={{ boxShadow: 'none', border: 'none' }}>
      {images[currentImageIndex]}
      <CardActions style={{ justifyContent: 'center', marginLeft: '0px !important' }}>
        <Button onClick={handlePlayPause}>
          {isPlaying ? <Pause /> : <PlayArrow />}
        </Button>
        <Button onClick={handlePrevious} sx={{padding: 0, marginLeft: '0px !important'}}>
          <NavigateBefore />
        </Button>
        <Box
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            align="center"
            style={{ color: '#fff', margin: 0, lineHeight: 1 }}
          >
            {currentImageIndex + 1}
          </Typography>
        </Box>
        <Button onClick={handleNext} sx={{padding: 0, marginLeft: '0px !important'}}>
          <NavigateNext />
        </Button>
      </CardActions>
    </Card>
  );
};

export default LandingSlider;

import React from "react";
import {Box} from "@mui/material";
import useWidth from "../hooks/useWidth";
import AboutUsComponent from "../components/about/about-us.component";

const AboutUsScreen = () => {
    
    const breakpoints = useWidth();

    return (
        <Box>
            <AboutUsComponent breakpoints={breakpoints} />
        </Box>     
    )
}

export default AboutUsScreen
import LandingScreen from "../screens-v2/LandingScreen";
import JoinNowScreen from "../screen/JoinNowScreen";
import AboutUsScreen from "../screen/AboutUsScreen";
import ProductsComponent from "../components/landing/products/products.component";
import SolutionsComponent from "../components/landing/solutions/solutions.component";
import UsecaseComponent from "../components/landing/usecase/usecase.component";
import PrivacyPolicyComponent from "../components/ovcode-website-v2/resources/privacy-policy.component";
import VerifyComponent from "../components/landing/verify/verify.component";
import FAQComponent from "../components/ovcode-website-v2/support/faq/faq.component";
import PDFViewerComponent from "../components/landing/verify/pdf-viewer.component";
import JSONViewerComponent from "../components/landing/verify/json-viewer.component";
import TextViewerComponent from "../components/landing/verify/text-viewer.component";
import VerifyReferenceLinkScreen from "../screen/VerifyReferenceLinkScreen";
import ElectronicSignatureComponent from "../components/ovcode-website-v2/products/electronic-signature/electronic-signature.component";
import ElectronicIdentificationComponent from "../components/ovcode-website-v2/products/electronic-identification/electronic-identification.component";
import VerificationComponent from "../components/ovcode-website-v2/enterprise/verification/verification.component";
import ComingSoonPage from "../components/ovcode-website-v2/landing/coming-soon.component";
import VersionHistoriesComponent from "../components/ovcode-website-v2/enterprise/version-histories.component";
import ContactUsComponent from "../components/ovcode-website-v2/support/contact-us.component";
import EnterpriseSupportComponent from "../components/ovcode-website-v2/support/enterprise-support.component";
import ProductSupportComponent from "../components/ovcode-website-v2/support/product-support.component";
import ElectronicMarkComponent from "../components/ovcode-website-v2/products/electronic-mark/electronic-mark.component";
import TermsOfServiceComponent from "../components/ovcode-website-v2/resources/terms-of-services2.component";
import ElectronicMarkLandingScreen from "../screens-v2/eMarkLandingScreen";
import OVCODEInsightsScreen from "../screens-v2/OVCODEInsightsScreen";
import ElectronicIdentificationInDepthScreen from "../screens-v2/eIDInDepthScreen";
import ElectronicSignInDepthScreen from "../screens-v2/eSignInDepthScreen";
import ElectronicMarkAsAssetScreen from "../screens-v2/eMarkAsAssetScreen";
import SupportAndCommunityScreen from "../screens-v2/SupportAndCommunityScreen";
import PartnersScreen from "../screens-v2/PartnersScreen";
import IndustryInsightsScreen from "../screens-v2/IndustryInsightsScreen";
import ClippedDrawer from "../screens-v2/VersionHistoriesScreen";

import OvcodeReleaseNotesScreen from "../screens-v2/OvcodeReleaseNotesScreen";
import ElectronicIdentificationReleaseNotesScreen from "../screens-v2/ElectronicIdentificationReleaseNotesScreen";
import ElectronicIdentificationAppBindingReleaseNotesScreen from "../screens-v2/ElectronicIdentificationAppBindingReleaseNotesScreen";
import ElectronicIdentificationAndroidAppReleaseNotesScreen from "../screens-v2/ElectronicIdentificationAndroidAppReleaseNotesScreen";
import ElectronicIdentificationWebAppReleaseNotesScreen from "../screens-v2/ElectronicIdentificationWebAppReleaseNotesScreen";
import ElectronicIdentificationRegClientReleaseNotesScreen from "../screens-v2/ElectronicIdentificationRegClientReleaseNotesScreen";
import NationalIdentificationAdminWebPortalReleaseNotesScreen from "../screens-v2/NationalIdentificationAdminWebPortalReleaseNotesScreen";
import ElectronicSignatureReleaseNotesScreen from "../screens-v2/ElectronicSignatureReleaseNotesScreen";
import VersionHistoriesAllScreen from "../screens-v2/VersionHistoriesAllScreen";
import TheEvolutionOfDigitalTrustComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/the-evolution-of-digital-trust.component";
import WhyDigitalTrustMatterComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/why-digital-trust-matter.component";
import TrailblazerBlockchainComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/trailblazer-blockchain-technology.component";
import SecuringTheFutureComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/securing-the-future.component";
import LatestNewsComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/latest-news.component";
import IdentityVerificationComponent from "../components/ovcode-website-v2/blog/content/eid-insights/identity-verification.component";
import SuccessStoriesComponent from "../components/ovcode-website-v2/blog/content/eid-insights/success-stories.component";
import DocumentWorkflowComponent from "../components/ovcode-website-v2/blog/content/esign-insights/document-workflow.component";
import GuideImplementingComponent from "../components/ovcode-website-v2/blog/content/esign-insights/guide-implementing.component";
import VisualContentComponent from "../components/ovcode-website-v2/blog/content/emark-asset/visual-content.component";
import BlockchainComponent from "../components/ovcode-website-v2/blog/content/emark-asset/blockhain.component";
import NavigatingOvcodeComponent from "../components/ovcode-website-v2/blog/content/support-and-community/navigating-ovcode.component";
import CommunitySpotlightComponent from "../components/ovcode-website-v2/blog/content/support-and-community/community-spotlight.component";
import UnlockGrowthComponent from "../components/ovcode-website-v2/blog/content/partner/unlock-growth.component";
import OvcodeDistributorComponent from "../components/ovcode-website-v2/blog/content/partner/ovcode-distributor.component";
import WhatToKnowComponent from "../components/ovcode-website-v2/blog/content/industry-insight/what-to-know.component";
import OvcodeRoleComponent from "../components/ovcode-website-v2/blog/content/industry-insight/ovcode-role.component";
import OVCDisclaimerScreen from "../screens-v2/OVCDisclaimerScreen";
import BeyondKPIComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/beyond-kpi.component";
import PoweredKPIComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/powered-kpi.component";
import EndtoEndEncryptionComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/end-to-end-encryption.component";
import PowerofAATLCertificationComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/power-of-aatl-certification.component";
import EnhancingUserExperienceComponent from "../components/ovcode-website-v2/blog/content/industry-insight/enhancing-user-experience.component";
import SafetyandAuthenticityComponent from "../components/ovcode-website-v2/blog/content/industry-insight/safety-and-authenticity.component";
import RealtimeVerificationComponent from "../components/ovcode-website-v2/blog/content/industry-insight/real-time-verification.component";
import BlockchainSynergyComponent from "../components/ovcode-website-v2/blog/content/industry-insight/blockchain-synergy.component";
import BuildingTrustComponent from "../components/ovcode-website-v2/blog/content/industry-insight/building-trust.component";
import DecodingOvcodeSecurityComponent from "../components/ovcode-website-v2/blog/content/industry-insight/decoding-ovcode-security.component";
import FutureProofingComponent from "../components/ovcode-website-v2/blog/content/industry-insight/future-proofing.component";
import SecureStorageComponent from "../components/ovcode-website-v2/blog/content/industry-insight/secure-storage.component";
import HybridApproachComponent from "../components/ovcode-website-v2/blog/content/industry-insight/hybrid-approach.component";
import SecurePaymentsComponent from "../components/ovcode-website-v2/blog/content/eid-insights/secure-payments.component";
import CYbergemsBlogScreen from "../screens-v2/CybergemsBlogScreen";
import CybergemsAuthenticatingDigitalTreasuresComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-authenticating-digital-treasures.component";
import CybergemsUnveiledComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-unveiled.component";
import NavigatingSecureStorageComponent from "../components/ovcode-website-v2/blog/content/support-and-community/navigating-secure-storage.component";
import TheSignificanceOfAATLCertificationComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/the-significance-of-aatl-certification.component";
import SettingForStandardDigitalTransactionsComponent from "../components/ovcode-website-v2/blog/content/ovcode-insights/setting-standard-for-digital-transactions.component";
import CybergemsArtOfAuthenticityComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-art-of-authenticity.component";
import CybergemsTechnologyThatSecuresMemoriesComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-technology-that-secures-memories.component";
import CybergemsFutureOfCollectingComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-future-of-collecting.component";
import CybergemsOvcodeRoleNftComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-ovcode-role-in-nft.component";
import CybergemsInvestmentInFutureEraComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-investment-in-future-era.component";
import CybergemsLegacyInPixelsComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-legacy-in-pixels.component";
import CybergemsEvolutionOfDigitalCollectiblesComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-evolution-of-digital-collectibles.component";
import CybergemsIntersectingPathsComponent from "../components/ovcode-website-v2/blog/content/cybergems/cybergems-intersecting-paths.component";
import AccountDeletionInstructionScreen from "../screens-v2/AccountDeletionInstructionScreen";

export const routes = [
    {
        path: "/",
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },

    {
        path: "/electronic-mark-landing",
        name: 'ElectronicMarkLandingScreen',
        element: <ElectronicMarkLandingScreen />,
        isPrivate: false
    },

    {
        path: "/about-us",
        name: 'AboutUsScreen',
        element: <AboutUsScreen />,
        isPrivate: false
    },

    {
        path: "/coming-soon",
        name: 'ComingSoonPage',
        element: <ComingSoonPage />,
        isPrivate: false
    },

    {
        path: "/version-histories",
        name: 'ClippedDrawer',
        element: <ClippedDrawer/>,
        isPrivate: false
    },

    {
        path: "/version-histories-all",
        name: 'VersionHistoriesAllScreen',
        element: <VersionHistoriesAllScreen />,
        isPrivate: false
    },

    {
        path: "/version-histories-ovcode-enterprise",
        name: 'OvcodeReleaseNotesScreen',
        element: <OvcodeReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-sign",
        name: 'ElectronicSignatureReleaseNotesScreen',
        element: <ElectronicSignatureReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-admin",
        name: 'ElectronicIdentificationReleaseNotesScreen',
        element: <ElectronicIdentificationReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-android",
        name: 'ElectronicIdentificationAndroidAppReleaseNotesScreen',
        element: <ElectronicIdentificationAndroidAppReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-app-binding",
        name: 'ElectronicIdentificationAppBindingReleaseNotesScreen',
        element: <ElectronicIdentificationAppBindingReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-web-app",
        name: 'ElectronicIdentificationWebAppReleaseNotesScreen',
        element: <ElectronicIdentificationWebAppReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-regClient",
        name: 'ElectronicIdentificationRegClientReleaseNotesScreen',
        element: <ElectronicIdentificationRegClientReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-national-identification-admin",
        name: 'NationalIdentificationAdminWebPortalReleaseNotesScreen',
        element: <NationalIdentificationAdminWebPortalReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/enterprise-support",
        name: 'EnterpriseSupportComponent',
        element: <EnterpriseSupportComponent />,
        isPrivate: false
    },

    {
        path: "/product-support",
        name: 'ProductSupportComponent',
        element: <ProductSupportComponent />,
        isPrivate: false
    },

    {
        path: "/system-policies-and-procedures",
        name: 'ComingSoonPage',
        element: <ComingSoonPage />,
        isPrivate: false
    },


    {
        path: "/contact-us",
        name: 'ContactUsComponent',
        element: <ContactUsComponent />,
        isPrivate: false
    },

    {
        path: "/join",
        name: 'JoinNowScreen',
        element: <JoinNowScreen />,
        isPrivate: false
    }, 

    {
        path: "/products",
        name: 'ProductsComponent',
        element: <ProductsComponent />,
        isPrivate: false
    },

    {
        path: "/electronic-identification",
        name: 'ElectronicIdentificationComponent',
        element: <ElectronicIdentificationComponent />,
        isPrivate: false
    },

    {
        path: "/electronic-signature",
        name: 'ElectronicSignatureComponent',
        element: <ElectronicSignatureComponent />,
        isPrivate: false
    },

    
    {
        path: "/electronic-mark",
        name: 'ElectronicMarkComponent',
        element: <ElectronicMarkComponent />,
        isPrivate: false
    },

    {
        path: "/verification",
        name: 'VerificationComponent',
        element: <VerificationComponent />,
        isPrivate: false
    },

    {
        path: "/solutions",
        name: 'SolutionsComponent',
        element: <SolutionsComponent />,
        isPrivate: false
    },

    {
        path: "/use-case",
        name: 'UsecaseComponent',
        element: <UsecaseComponent />,
        isPrivate: false
    },

    {
        path: "/privacy-policy",
        name: 'PrivacyPolicyComponent',
        element: <PrivacyPolicyComponent />,
        isPrivate: false
    },

    {
        path: "/verify",
        name: 'VerifyComponent',
        element: <VerifyComponent />,
        isPrivate: false
    },

    {
        path: "/faq",
        name: 'FAQComponent',
        element: <FAQComponent />,
        isPrivate: false
    },

    {
        path: "/terms-of-service",
        name: 'TermsOfServiceComponent',
        element: <TermsOfServiceComponent />,
        isPrivate: false
    },

    {
        path: "/view/pdf",
        name: 'PDFViewerComponent',
        element: <PDFViewerComponent />,
        isPrivate: false
    },

    {
        path: "/view/json",
        name: 'JSONViewerComponent',
        element: <JSONViewerComponent />,
        isPrivate: false
    },

    {
        path: "/view/text",
        name: 'TextViewerComponent',
        element: <TextViewerComponent />,
        isPrivate: false
    },

    {
        path: "/v/:referenceCode",
        name: 'VerifyReferenceLinkScreen ',
        element: <VerifyReferenceLinkScreen />,
        isPrivate: false
    },

    
    {
        path: "/blog-ovcode-insights",
        name: 'OVCODEInsightsScreen ',
        element: <OVCODEInsightsScreen />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems",
        name: 'CYbergemsBlogScreen',
        element: <CYbergemsBlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eID-in-depth",
        name: 'ElectronicIdentificationInDepthScreen ',
        element: <ElectronicIdentificationInDepthScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eSign-in-depth",
        name: 'ElectronicSignInDepthScreen ',
        element: <ElectronicSignInDepthScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eMark-as-asset",
        name: 'ElectronicMarkAsAssetScreen ',
        element: <ElectronicMarkAsAssetScreen />,
        isPrivate: false
    },

    {
        path: "/blog-support-and-community",
        name: 'SupportAndCommunityScreen ',
        element: <SupportAndCommunityScreen />,
        isPrivate: false
    },

    {
        path: "/blog-partners",
        name: 'PartnersScreen ',
        element: <PartnersScreen />,
        isPrivate: false
    },

    {
        path: "/blog-industry-insights",
        name: 'IndustryInsightsScreen ',
        element: <IndustryInsightsScreen />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-evolution",
        name: 'TheEvolutionOfDigitalTrustComponent ',
        element: <TheEvolutionOfDigitalTrustComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-digital-trust",
        name: 'WhyDigitalTrustMatterComponent ',
        element: <WhyDigitalTrustMatterComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-trailblazer-blockchain",
        name: 'TrailblazerBlockchainComponent ',
        element: <TrailblazerBlockchainComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-securing-future",
        name: 'SecuringTheFutureComponent ',
        element: <SecuringTheFutureComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-in-news",
        name: 'LatestNewsComponent',
        element: <LatestNewsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-identity-verification",
        name: 'IdentityVerificationComponent',
        element: <IdentityVerificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-success-stories",
        name: 'SuccessStoriesComponent',
        element: <SuccessStoriesComponent />,
        isPrivate: false
    },

    {
        path: "/blog-esign-document-workflow",
        name: 'DocumentWorkflowComponent',
        element: <DocumentWorkflowComponent />,
        isPrivate: false
    },

    {
        path: "/blog-esign-unlocking-efficiency",
        name: 'GuideImplementingComponent',
        element: <GuideImplementingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-emark-visual-content",
        name: 'VisualContentComponent',
        element: <VisualContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-emark-blockhain",
        name: 'BlockchainComponent',
        element: <BlockchainComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-navigating-ovcode",
        name: 'NavigatingOvcodeComponent',
        element: <NavigatingOvcodeComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-community-spotlight",
        name: 'CommunitySpotlightComponent',
        element: <CommunitySpotlightComponent />,
        isPrivate: false
    },

    {
        path: "/blog-partner-unlock-growth",
        name: 'UnlockGrowthComponent',
        element: <UnlockGrowthComponent />,
        isPrivate: false
    },

    {
        path: "/blog-partner-ovcode-distributor",
        name: 'OvcodeDistributorComponent',
        element: <OvcodeDistributorComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-what-to-know",
        name: 'WhatToKnowComponent',
        element: <WhatToKnowComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-ovcode-role",
        name: 'OvcodeRoleComponent',
        element: <OvcodeRoleComponent />,
        isPrivate: false
    },

    // {
    //     path: "/ovc-disclaimer",
    //     name: 'OVCDisclaimerScreen',
    //     element: <OVCDisclaimerScreen />,
    //     isPrivate: false
    // },

    {
        path: "/blog-ovcode-insights-beyond-kpi",
        name: 'BeyondKPIComponent',
        element: <BeyondKPIComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-powered-kpi",
        name: 'PoweredKPIComponent',
        element: <PoweredKPIComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-end-to-end-encryption",
        name: 'EndtoEndEncryptionComponent',
        element: <EndtoEndEncryptionComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-power-of-AATL-certification",
        name: 'PowerofAATLCertificationComponent',
        element: <PowerofAATLCertificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-user-experience",
        name: 'EnhancingUserExperienceComponent',
        element: <EnhancingUserExperienceComponent />,
        isPrivate: false
    },

    // {
    //     path: "/blog-industry-safety-and-authenticity",
    //     name: 'SafetyandAuthenticityComponent',
    //     element: <SafetyandAuthenticityComponent />,
    //     isPrivate: false
    // },

    {
        path: "/blog-industry-real-time-verification",
        name: 'RealtimeVerificationComponent',
        element: <RealtimeVerificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-blockchain-synergy",
        name: 'BlockchainSynergyComponent',
        element: <BlockchainSynergyComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-building-trust",
        name: 'BuildingTrustComponent',
        element: <BuildingTrustComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-decoding-security",
        name: 'DecodingOvcodeSecurityComponent',
        element: <DecodingOvcodeSecurityComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-future-proofing",
        name: 'FutureProofingComponent',
        element: <FutureProofingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-flexible-and-secure-storage",
        name: 'SecureStorageComponent',
        element: <SecureStorageComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-hybrid-approach",
        name: 'HybridApproachComponent',
        element: <HybridApproachComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-secure-payments",
        name: 'SecurePaymentsComponent',
        element: <SecurePaymentsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-authenticating-digital-treasure",
        name: 'CybergemsAuthenticatingDigitalTreasuresComponent',
        element: <CybergemsAuthenticatingDigitalTreasuresComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-unveiled",
        name: 'CybergemsUnveiledComponent',
        element: <CybergemsUnveiledComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-navigating-secure-storage",
        name: 'NavigatingSecureStorageComponent',
        element: <NavigatingSecureStorageComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-significance-of-certification",
        name: 'TheSignificanceOfAATLCertificationComponent',
        element: <TheSignificanceOfAATLCertificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-setting-standard-for-digital-transactions",
        name: 'SettingForStandardDigitalTransactionsComponent',
        element: <SettingForStandardDigitalTransactionsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-art-of-authenticity",
        name: 'CybergemsArtOfAuthenticityComponent',
        element: <CybergemsArtOfAuthenticityComponent />,
        isPrivate: false
    },


    {
        path: "/blog-cybergems-secures-memories",
        name: 'CybergemsTechnologyThatSecuresMemoriesComponent',
        element: <CybergemsTechnologyThatSecuresMemoriesComponent />,
        isPrivate: false
    },
   
    {
        path: "/blog-cybergems-future-collecting",
        name: 'CybergemsFutureOfCollectingComponent',
        element: <CybergemsFutureOfCollectingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-ovcode-role-nft",
        name: 'CybergemsOvcodeRoleNftComponent',
        element: <CybergemsOvcodeRoleNftComponent />,
        isPrivate: false
    },
   
    {
        path: "/blog-cybergems-investment-era",
        name: 'CybergemsInvestmentInFutureEraComponent',
        element: <CybergemsInvestmentInFutureEraComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-pixel-legacy",
        name: 'CybergemsLegacyInPixelsComponent',
        element: <CybergemsLegacyInPixelsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-evolution-of-digital-collectibles",
        name: 'CybergemsEvolutionOfDigitalCollectiblesComponent',
        element: <CybergemsEvolutionOfDigitalCollectiblesComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-intersecting-paths",
        name: 'CybergemsIntersectingPathsComponent',
        element: <CybergemsIntersectingPathsComponent />,
        isPrivate: false
    },

    {
        path: "/account-deletion-instruction",
        name: 'AccountDeletionInstructionScreen',
        element: <AccountDeletionInstructionScreen />,
        isPrivate: false
    },
   
   
   

]




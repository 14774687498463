import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import NavigatingOvcode from "../../../../../images/blog/navigating-ovcode.png";


const NavigatingOvcodeComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Navigating OVCODE: A Comprehensive Guide to User Support
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={NavigatingOvcode} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                At OVCODE, we are committed to providing a seamless and supportive experience for
                                our users. Whether you're a business integrating our solutions or an individual exploring
                                our platforms, our user support services are designed to assist you every step of the
                                way.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Overview of OVCODE Support Services:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Knowledge Base:</Typography>
                                    <Typography>
                                        Explore our extensive Knowledge Base, a repository of articles, guides, and FAQs covering
                                        a wide range of topics. This self-service resource is a valuable tool for finding answers to
                                        common queries and learning more about OVCODE's features.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Ticket-Based Support:</Typography>
                                    <Typography>
                                        For personalized assistance, our ticket-based support system allows users to submit
                                        specific queries or issues. Our support team will promptly review and respond to your
                                        tickets, ensuring a tailored and efficient resolution to your concerns.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>c. Community Forums:</Typography>
                                    <Typography>
                                        Connect with other OVCODE users, share insights, and seek advice on our community
                                        forums. Our forums are a collaborative space where users can engage with each other,
                                        share best practices, and learn from the experiences of the OVCODE community.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>d. Email Support:</Typography>
                                    <Typography>
                                        For more direct communication, our email support channel is available for users to
                                        reach out with detailed inquiries. Simply send an email to support@ovcode.com, and
                                        our dedicated support team will assist you.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. How to Access Assistance:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Knowledge Base:</Typography>
                                    <Typography>
                                        Visit our website and navigate to the "Support" or "Help Center" section. Here, you'll
                                        find access to the Knowledge Base, where you can browse articles and guides based on
                                        relevant categories.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Ticket-Based Support:</Typography>
                                    <Typography>
                                        If you have a specific question or encounter an issue, log in to your OVCODE account
                                        and visit the "Support" or "Help" section. Here, you can submit a support ticket by
                                        providing details about your query. Our support team will respond to your ticket
                                        promptly.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>c. Community Forums:</Typography>
                                    <Typography>
                                        Join our community forums by visiting the "Community" section on our website. Engage
                                        with other users, ask questions, and contribute to discussions. The community forums
                                        are a valuable resource for collaborative problem-solving
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>d. Email Support:</Typography>
                                    <Typography>
                                        For direct email support, send your inquiries to support@ovcode.com. Our support team
                                        will receive your email and respond to assist you with any issues or questions you may
                                        have.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Additional Resources:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Video Tutorials:</Typography>
                                    <Typography>
                                        Explore our library of video tutorials available on our official YouTube channel. These
                                        tutorials provide step-by-step guidance on using OVCODE's features and solutions.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Webinars and Events:</Typography>
                                    <Typography>
                                        Stay informed about upcoming webinars and events hosted by OVCODE. These
                                        interactive sessions provide opportunities to learn directly from experts, ask questions,
                                        and stay updated on the latest developments.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Stay Connected:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Social Media:</Typography>
                                    <Typography>
                                        Follow OVCODE on social media platforms such as Twitter, LinkedIn, and Facebook. Our
                                        social channels are not only a source of news and updates but also avenues for direct
                                        communication with our community.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Newsletter:</Typography>
                                    <Typography>
                                        Subscribe to our newsletter to receive regular updates, feature highlights, and
                                        announcements directly in your inbox. Stay informed about the latest advancements in
                                        OVCODE's technologies.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography>
                                At OVCODE, we are dedicated to empowering our users with the knowledge and support
                                they need. Whether you're a seasoned user or just getting started, our comprehensive
                                support services are here to ensure a smooth and enriching experience with OVCODE.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                    onClick={() => window.location.href = "/blog-support-community-spotlight"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Community Spotlight: Stories from the OVCODE User Community
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Share success stories, experiences, and contributions from the OVCODE user community.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-partner-unlock-growth"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Unlock Growth: A Guide to Becoming an OVCODE Partner
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Outline the benefits and steps for individuals or businesses interested in becoming OVCODE partners.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NavigatingOvcodeComponent
import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import LatestNews from "../../../../../images/blog/latest-news.png";


const LatestNewsComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE in the News: Latest Updates and Media Coverage
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Friday, 12 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={LatestNews} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the fast-paced world of technology and digital innovation, staying abreast of the latest
                                updates and media coverage is crucial. Here's a curated compilation of the most recent news
                                articles and media highlights featuring OVCODE.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Keeping You Informed
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                At OVCODE, transparency is paramount. We strive to keep our community and stakeholders
                                well-informed about our latest ventures, accomplishments, and contributions to the digital
                                trust landscape. These news articles serve as a testament to our ongoing commitment to
                                innovation, security, and the future of trustworthy digital interactions.
                            </Typography>
                            <Typography sx={{ mt: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                For a more in-depth exploration of each news piece, we invite you to follow the provided
                                links to the full articles. Stay tuned for more updates as we continue our journey towards
                                reshaping the digital trust paradigm.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-identity-verification"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Identity Verification: An In-Depth Look at OVCODE e
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-beyond-kpi"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Beyond PKI: How OVCODE Integrates Blockchain for Next-Level Authentication
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discover how OVCODE transcends traditional Public Key Infrastructure (PKI) by integrating blockchain technology to offer superior authentication services. 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 01, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default LatestNewsComponent
import { Box, Grid, Link, Typography } from "@mui/material"


const using_ovcode = [
    {
        name: "How do I verify digital data using OVCODE?",
        panel: "Panel1",
        description:
            <Typography>
                Simply upload the digital data or document to the OVCODE platform. The platform's technology will create a tamper-proof digital signature that can be verified by anyone with access to the OVCODE network.
            </Typography>

    },
    {
        name: "Why Do I need OVCODE Account?",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Having an OVCODE Account opens doors to enhanced data security and trusted interactions. With an account, you gain access to our advanced tools for identity verification and secure document handling. This means your digital interactions become more credible, tamper-proof, and efficient.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    By subscribing, you're tapping into a higher level of data integrity and protection. Our platform offers seamless integration of blockchain, digital signature, and cryptography to ensure the accuracy and immutability of your data. This level of security is particularly crucial for businesses that handle sensitive information or require authenticated documents.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    So, while subscription is a choice, having an OVCODE Account empowers you with a trusted foundation for digital transactions, strengthening the credibility and reliability of your interactions
                </Typography>
            </Box>
    },
    {
        name: "Is OVCODE free? ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVCODE offers a range of service plans designed to cater to the diverse needs of our users, including enterprise clients. While we provide free access to the platform for basic usage, our offerings have expanded to include various plans to accommodate larger-scale usage and additional features.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b>Enterprise Plans and Usage</b>
                    <br />
                    For enterprise clients with larger user bases and more complex requirements, OVCODE provides customized plans tailored to your specific needs. The creation of user accounts for populating Electronic ID wallets is determined by the terms of the contract, ensuring flexibility and scalability to match your organizational demands.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b>Authentication and Usage Charges </b>
                    <br />
                    Please note that OVCODE's authentication services involve a charge per authenticated document. We've structured our pricing model to offer arrangements that align with different usage scenarios. For instance, the first 5 authenticated documents may be offered at no cost, with subsequent documents available under subscription plans. These subscription plans can be customized for end users, project clients, or enterprise clients.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    <b>Your Path to Trusted Transactions </b>
                    At OVCODE, we're committed to providing a transparent and adaptable pricing structure that caters to your enterprise needs. Our goal is to empower your organization with secure, trusted transactions while ensuring that our pricing remains flexible and tailored to your unique requirements.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    If you have specific questions about pricing, subscription plans, or how our services align with your enterprise goals, please don't hesitate to contact us at <Link href="/join" underline="none">Link</Link>. We're here to assist you every step of the way in building a secure and trusted digital future.
                </Typography>

            </Box>
    }
]

export default using_ovcode

import React, { useEffect, useState } from "react";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import DrawerHeader from "../../layout/drawer-header";
import VerifyBannerComponent from "./verify-banner.component";
import DataUploaderComponent from "./data-uploader.component";
import ScanQRComponent from "./scan-qr.component";
import VerifiedDataDialog from "../../dialog/verified-data-dialog.component";
import UnverifiedDataDialog from "../../dialog/unverified-data-dialog.component";
import useWidth from "../../../hooks/useWidth";
import uploadGIF from "../../../icons/upload-v1.gif";
import scanGIF from "../../../icons/scanner-v2.gif";
import useVerification from "../../../hooks/useVerification";
import { useParams } from "react-router";
import { ColorRing } from 'react-loader-spinner';


const VerifyLinkComponent = () => {
     const breakpoints = useWidth();

    const { verifyReference } = useVerification();
    const { referenceCode } = useParams();

    const [openUpload, setOpenUpload] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenScanComponent = () => {
        setOpenScan(true);
        setOpenUpload(false);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        if (event.type === "unload") {
            localStorage.removeItem("isVerifiedDialog");
            localStorage.removeItem("isUnverifiedDialog");
        }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (referenceCode === "undefined" || referenceCode === undefined) {
        // do nothing
        } else if (referenceCode) {
        const fetchData = async () => {
            setIsLoading(true);

            try {
            await verifyReference(
                referenceCode,
                setOpenScan,
                setOpenVerifiedData,
                setOpenUnverifiedData
            );
            } catch (error) {
            // Handle the error
            }

            setIsLoading(false);
        };

        fetchData();
        }
    }, [referenceCode]);

    return (
        <Box sx={{ overflowX: "hidden" }}>
            {isLoading && 
                <>
                    <ColorRing
                        visible={true}
                        height="120"
                        width="120"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#01beff', '#017eff', '#3333cf','#01beff', '#017eff',]}
                    />
                    <Typography sx={{fontWeight: '600', fontSize: 20, textAlign: 'center', color: '#fff'}}>Verifying</Typography>
                </>
            }

            {openUpload && (
                <DataUploaderComponent
                breakpoints={breakpoints}
                setOpenUpload={setOpenUpload}
                setOpenVerifiedData={setOpenVerifiedData}
                setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}

            {openScan && (
                <ScanQRComponent
                breakpoints={breakpoints}
                setOpenScan={setOpenScan}
                setOpenVerifiedData={setOpenVerifiedData}
                setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}

            {openVerifiedData && (
                <VerifiedDataDialog
                breakpoints={breakpoints}
                openVerifiedData={openVerifiedData}
                setOpenVerifiedData={setOpenVerifiedData}
                />
            )}

            {openUnverifiedData && (
                <UnverifiedDataDialog
                breakpoints={breakpoints}
                openUnverifiedData={openUnverifiedData}
                setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}
        </Box>
    );
};

export default VerifyLinkComponent;


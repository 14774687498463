import React, { useEffect, useState } from "react";
import { CssBaseline, Box } from "@mui/material";
import Appbar from "./appbar.component";
import static_variable from "./static-variable.component";
import DrawerHeader from "./drawer-header";
import FooterScreen from "../../../screen/FooterScreen";
import useWidth from "../../../hooks/useWidth";
import ViewerHeader from "./viewer-header.component";
import { useLocation } from "react-router-dom";
import ElectronicMarkViewerHeader from "./emark-viewer-header.component";
import ClippedDrawer from "../../../screens-v2/VersionHistoriesScreen";

function Main({ children }) {
    const url = window.location.pathname;
    const breakpoints = useWidth();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: url === "/view/pdf" ? "#80808021" : {},
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth:
                        url === "/join" || url === "/solutions" || url === "/products" || url === "/view/pdf" || url === "/" || url === "/electronic-signature" || url === "/electronic-identification"
                            || url === "/verification" || url === "/electronic-mark" || url === "/electronic-mark-landing"  || url === "/enterprise-support" || url === "/product-support" || url === "/privacy-policy" 
                            || url === "/faq" || url === "/terms-of-service" || url === "/blog-ovcode-insights" || url === "/blog-eID-in-depth" || url === "/blog-eSign-in-depth" || url === "/blog-eMark-as-asset"
                            || url === "/blog-support-and-community" || url === "/blog-partners" || url=== "/blog-industry-insights"  || url === "/blog-cybergems" || url === "/account-deletion-instruction"
                            ? {}
                            : static_variable.mainContainer_maxWidth,
                    overflowY: breakpoints === "xs" || breakpoints === "sm" ? {} : "hidden",
                    height: url === "/join" ? "100vh" : "100%",
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

function MainLayout({ children }) {
    const location = useLocation();
    const { pathname } = location;

    const [hideAppbar, setHideAppbar] = useState(false);

    useEffect(() => {
        setHideAppbar(pathname.startsWith("/v/"));
    }, [pathname]);

    return (
        <>
            <CssBaseline />

            {!hideAppbar && (
                <>
                    {(
                        pathname === "/view/pdf" ||
                            pathname === "/view/json" ||
                            pathname === "/view/docx" ||
                            pathname === "/view/text" ? 
                        (
                            <ViewerHeader />
                        ) 
                    :
                        (
                            <Appbar />
                        )
                    )}
                </>
            )}

            <Main>
                <Box>
                    <DrawerHeader />
                    {children}
                    
                </Box>
            </Main>

            {!hideAppbar && (
                <>
                    {(
                        pathname !== "/join" &&
                        pathname !== "/view/pdf" &&
                        pathname !== "/view/json" &&
                        pathname !== "/view/docx" &&
                        pathname !== "/view/text" &&
                        pathname !== "/electronic-mark-landing" &&
                        pathname !== "/version-histories-all" &&
                        pathname !== "/version-histories-ovcode-enterprise" &&
                        pathname !== "/version-histories-electronic-identification-admin" && 
                        pathname !== "/version-histories-electronic-identification-android" && 
                        pathname !== "/version-histories-electronic-identification-app-binding" && 
                        pathname !== "/version-histories-electronic-identification-web-app" && 
                        pathname !== "/version-histories-electronic-identification-regClient" &&
                        pathname !== "/version-histories-national-identification-admin" &&
                        pathname !== "/version-histories-electronic-sign" &&
                        <>
                            <br />

                            <hr style={{ margin: 0, flexShrink: 0, borderWidth: ' 0px 0px thin', borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)' }} />

                            <br />

                            <FooterScreen />
                        </>
                    )}
                </>
            )}

        </>
    );
}

export default MainLayout;

import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const ovc = [
    {
        name: "What is the utility of OVC?",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVC is a utility token and is the only medium of payment for OVCODE transactions.  OVC will be used for the following: 
                </Typography>
                <ol>
                    <li>Asset Registration</li>
                    <li>OVCODE Blockchain Publishing</li>
                    <li>OVCODE Verification</li>
                    <li>Advertisement Sponsored Features</li>
                </ol>
                <Typography sx={{ mb: 2 }}>
                    OVC is an ERC20 token and has a finite supply.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    During registration to OVCODE, the user is created a loadable OVC wallet. Upon completion of function processes, users will be redirected to the payment page and will be prompted to pay via OVC. OVCODE will deduct the payment amount to the user’s OVC wallet. Verification costs are fixed for every publishing institution. OVC payment will be converted to the fixed payment depending on the current OVC market value.
                </Typography>
            </Box>
    },
    {
        name: "How is OVC Classified?",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                OVC is classified as a utility token with additional payment token characteristics. Utility tokens are tokens, which are intended to provide access digitally to an application or service by means of a Blockchain-based infrastructure. Payment tokens are tokens, which are intended to be used now or in the future, as a means of payment or acquiring goods or services or as a means of money or value transfer.
                </Typography>
            </Box>
    },
    {
        name: "What are OVC'S differentiators?",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Listed below are OVC Differentiators:
                </Typography>
                <ol>
                    <li>OVC was designed with price stability and low volatility thus there are only 83,875,000 tokens available. A low token volume supply.</li>
                    <li>OVC is a utility token by design; it is a fundamental component to run the OVCODE infrastructure. OVC’s demand is supported by the OVCODE Blockchain technology platform.</li>
                    <li>OVC payment for publishing and verification will be constant since OVCODE uses its own private Blockchain network. Other technologies have erratic rate value because their applications are built on top of public Blockchain.</li>
                    <li>OVC will never split or fork. Stability is the focus of OVC.</li>
                    <li>The OVC is focused on full compliance based on stringent financial rules and regulation of Switzerland.</li>
                </ol>
            </Box>
    }
    ,
    {
        name: "How will OVC cope with inflation?",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVC will not have any inflation as the OVC tokens are pre-mined and no new tokens will be minted.<br/>
                    As more transactions are serviced by the OVCODE infrastructure, an increased number of tokens are paid back to OVCODE thus decreasing the total available supply and further deflating the market. <br/>
                    With decreasing supply and increasing demand for OVCODE services, OVC is projected to be immune to inflation.
                </Typography>
            </Box>
    }

]

export default ovc

import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import awards_images from "./awards-images.component";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import { useState } from "react";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()} style={{ background: 'transparent', border: 'none' }}>{children}</button>;
};


const AwardsComponent = () => {

    const breakpoints = useWidth();
    const [swiper, setSwiper] = useState(null);

    return (
        <Box >
            <Box sx={{ mb: 4 }}>
                <Typography sx={{
                    fontSize: 37,
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: 'start',
                    color: "#000",
                    mb: '1.25rem'
                }}>
                    Awards
                </Typography>
            </Box>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '80%', margin: 'auto' }}>
                <button onClick={() => swiper.slidePrev()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowBackIosIcon />
                </button>
                <Swiper pagination={{ clickable: true }} onSwiper={setSwiper} loop={true} modules={[Pagination]} className="mySwiper">
                    {awards_images.map((value) => {
                        return (
                            <SwiperSlide>
                                <Card sx={{ boxShadow: 'none', border: '1px solid rgba(0,0,0,0.15)', padding: 5, borderRadius: 4, width: '50%', margin: 'auto' }} >
                                    <SwiperButtonNext>
                                        <CardMedia
                                            component="img"
                                            image={value.icon}
                                            style={{ objectFit: 'contain', margin: 'auto' }}
                                        />
                                    </SwiperButtonNext>
                                </Card>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <button onClick={() => swiper.slideNext()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowForwardIosIcon />
                </button>
            </div>
        </Box>

    )

}

export default AwardsComponent
import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';

const ElectronicIdentificationRegClientReleaseNotes = () => {


    return (
        <Card sx={{
            borderRadius: '4px',
            marginBottom: '10px',
            transition: '.5s',
            boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
            '&:hover': {
                boxShadow: '0 0 12px 2px rgba(0,0,0,0.16)',
            },
        }}>
            <div style={{ padding: '40px' }}>
                <header style={{ textAlign: 'start' }}>
                    <Typography variant="h5" component="div"  sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}>
                        eID RegClient Version 1
                    </Typography>
                    <div style={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#999'
                    }}>
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{background: '#00800061'}} />
                            </ListItem>
                        </List>
                        <Typography variant="body2" component="span" className="posted-time">
                            <time dateTime="2020-04-23">August 25, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>

                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Registration</Typography>}
                                    secondary="Admin can create a new eID record for walk-in."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Biometrics Capture</Typography>}
                                    secondary="Admin can scan or input the reference no. from the user registration and capture 
                                    photo and signature of the user."
                                />
                            </ListItem>
                        </List>
                    </div>
                </CardContent>
            </div>
        </Card>
    )
}

export default ElectronicIdentificationRegClientReleaseNotes
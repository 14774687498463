import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const functionality_support = [
    {
        name: "API - How is data handling and security via API?  ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Using API Gateway (Azure APIM) - Think of APIs as secure bridges that let data travel between places. We use a special gateway through Azure API Management just to make sure your data travels safely. This gateway checks every piece of data before allowing it to pass, like a security guard making sure only authorized guests enter. It's all part of how we ensure your data is handled safely and securely through our APIs.)
                </Typography>
            </Box>
    },
    {
        name: "What are the types of reports available? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Monthly Reports (We offer a suite of available features with ongoing enhancements in the pipeline. Rest assured that we're committed to enhance and expand our solutions.) – Logs
                </Typography>
            </Box>
    },
    {
        name: "Auto logout time for Data Privacy  ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    We take data privacy seriously. While specific logout times can be defined by policies, as a standard measure, we implement a 5-minute auto logout. This ensures that your data remains secure by automatically logging you out after a short period of inactivity.
                </Typography>
            </Box>
    },
    {
        name: "Retention of data - Is it perpetual?  ",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (The retention of data varies based on specific policies and agreements. We ensure data retention aligns with the terms outlined in our policies and contracts. This clarity guarantees that your data is handled in accordance with your requirements.)
                </Typography>
            </Box>
    },
    {
        name: "How fast is the query of data per account and per authenticated file? ",
        panel: "Panel5",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Min. Less Than 5 Sec. depending on the process/factors
                </Typography>
            </Box>
    },
    {
        name: "Min. and Max size of data? ",
        panel: "Panel6",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    a. Signature - Less or equal to 1mb  <br />
                    b. Image (IDs) - Less or equal to 1mb  <br />
                    c. Template - Less than or equal to 10mb  <br />
                    d. Data - Less than or equal to 10mb
                </Typography>
            </Box>
    },
    {
        name: "How will the system handle identity theft?",
        panel: "Panel7",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Our approach to prevent identity theft is outlined in policies and contracts). By adhering to strict guidelines, we work to mitigate the risks associated with identity theft.
                </Typography>
            </Box>
    },
    {
        name: "Digital signature forgery handling? ",
        panel: "Panel8",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Our solution utilizes digital signatures embedded within documents, acting like a special seal. If anyone tries to alter even a tiny part of the document, our system instantly detects it. Any modified document won't pass our verification process, ensuring that forged signatures are automatically rejected.
                </Typography>
            </Box>
    },
    {
        name: "How fast is the deactivation of an account once reported?  ",
        panel: "Panel9",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Turn around action and service level? Depend on: <br />
                    Service Level Agreement - Using Standard SLA  <br />
                    Level 1 – Client / Agency IT Support  <br />
                    Level 2 – Distributor IT Support  <br />
                    Level 3 – OVCODE Team Support  <br />
                    Level 4 – Stakeholders
                </Typography>
            </Box>
    },
    {
        name: "Do you have customer support 24/7? ",
        panel: "Panel10",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Our policies ensure that customer support is provided in accordance with our commitment to meet your needs)
                </Typography>
            </Box>
    },
    {
        name: "How often will be the version update, release notes, or system maintenance? ",
        panel: "Panel11",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based, our policies ensure that updates, release notes, and maintenance are carried out in alignment with agreements. You will be informed about these activities to ensure transparency.
                </Typography>
            </Box>
    },
    {
        name: "How will the application of updates per client be managed? ",
        panel: "Panel12",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (The application of updates for each client follows our policies and agreements. Whether updates are deployed globally or tailored to specific client accounts, our approach is rooted in clear terms.)
                </Typography>
            </Box>
    }


]

export default functionality_support

import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';

const ElectronicIdentificationWebAppReleaseNotes = () => {

    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card sx={{
            borderRadius: '4px',
            marginBottom: '10px',
            transition: '.5s',
            boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
            '&:hover': {
                boxShadow: '0 0 12px 2px rgba(0,0,0,0.16)',
            },
        }}>
            <div style={{ padding: '40px' }}>
                <header style={{ textAlign: 'start' }}>
                    <Typography variant="h5" component="div"  sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}>
                        eID Web App Version 1
                    </Typography>
                    <div style={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#999'
                    }}>
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{background: '#00800061'}} />
                            </ListItem>
                        </List>
                        <Typography variant="body2" component="span" className="posted-time">
                            <time dateTime="2020-04-23">August 25, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>

                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Access</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <List>
                                                <ListItemText>User can access the app and may have the option to skip or setup their M-Pin.</ListItemText>
                                                <ListItemText>User can reset their Pin in case they forgot it. Simply follow the instructions in their email.</ListItemText>
                                            </List>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>National ID</Typography>}
                                    secondary="User can see their National ID Details."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>eID</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <List>
                                                <ListItemText>User can add eID by following the instructions sent from the App Binding Process.</ListItemText>
                                                <ListItemText>User can see their registered eID Details and download a copy of their eID in PDF format.</ListItemText>
                                            </List>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>

                            {showFullDetails && (
                                <>

                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Profile</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItemText>User can view their profile and update the profile picture.</ListItemText>
                                                        <ListItemText>User can also see their session logs.</ListItemText>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Valid ID's</Typography>}
                                            secondary="User can upload, view and delete multiple Valid IDs from the list given."
                                        />
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    )
}

export default ElectronicIdentificationWebAppReleaseNotes
import React from "react";
import {Grid} from "@mui/material";
import JoinNowBanner from "../../icons/join-now-banner2.png";
import FormComponent from "./form.component";

const JoinNowComponent = (props) => {
    
    const {breakpoints} = props;

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img alt="" src={JoinNowBanner} style={{width: "100%", objectFit: "cover", 
                    height: (breakpoints == "xs" || breakpoints == "sm") ? "" : (breakpoints == "xl") ? "100vh" : "93vh"}} 
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormComponent breakpoints={breakpoints} />
            </Grid>
        </Grid>
    )
}

export default JoinNowComponent
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import ovcode_network from './ovcode-network.component';

function FAQAccordionNetworkComponent() {

  const [expanded, setExpanded] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div>
        {ovcode_network.map((value)=>{
            return(
                <Accordion expanded={expanded === value.panel} onChange={handleChange(value.panel)} sx={{border: 'none', boxShadow: 'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{border: 'none', backgroundColor: 'transparent'}}
                    >
                        <Typography sx={{ width: '80%', flexShrink: 0 , textAlign: 'start',fontSize: "13px", margin: 0 , color:'#000000CC', fontWeight: 400}}>
                        {value.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{fontSize: "1.1rem", margin: 0 , color:'#000000CC', fontWeight: 400, textAlign: 'start'}}>
                        {value.description}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )
        })}
    </div>
  );
}

export default FAQAccordionNetworkComponent
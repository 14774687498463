import { Box, Card, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material";
import testlogo from '../../../icons/eMarkSuperFinalLogoSize.png';
import ovsign from '../../../icons/eSignSuperFinalLogoSize.png';
import ovi from '../../../icons/eIDSuperFinalLogoSize.png';
import environment from "../../../environment/environment";
import { useState } from "react";
import OvsignDemoVideoDialog from "../../dialog/ovsign-demo-video-dialog.component";
import OviDemoVideoDialog from "../../dialog/ovi-demo-video-dialog.component";
import PastelThree from "../../../images/pastel-three.jpg";

function SolutionsShowCaseComponent(props) {

    const { breakpoints } = props;
    const apiDocURL = environment.apiDocURL

    console.log("breakpoints", breakpoints)

    const [openOVSIGN, setOpenOVSIGN] = useState(false);
    const [openeID, setOpeneID] = useState(false);

    const handleOpenOVSIGN = () => {
        setOpenOVSIGN(true);
    }

    const handleCloseOVSIGNs = () => {
        setOpenOVSIGN(false);
    }

    const handleOpeneID = () => {
        setOpeneID(true);
    }

    const handleCloseeID = () => {
        setOpeneID(false);
    }

    return (
        <div class="link-list parent-list aem-GridColumn aem-GridColumn--default--12">
            <nav data-componentname="link-list-uid905a" aria-label="product categories">
                <ul class="text-center d-flex flex-wrap list-inline justify-content-center">
                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0  "
                        data-automation-test-id="LinkListItem0-link-list-uid905a" aria-expanded="false"
                        clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverxa0d472c0cb444d8191e13213146f69d1" data-placement="bottom">
                        <img src={ovi}
                            loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="Experience eID"
                            data-bi-ecn="Experience eID" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href="https://eid.ovcode.com" aria-label="Experience eID"
                            target="_self" data-bi-cn="Experience eID" data-bi-ecn="Experience eID"
                            data-bi-bhvr="0" data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri="https://eid.ovcode.com">
                            Discover eID
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0  "
                        aria-expanded="false" clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverx687df4f49869465f9d105ba1ab7dcc29" data-placement="bottom">
                        <img src={ovsign} loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="Experience eSign"
                            data-bi-ecn="Experience eSign" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href="https://esign.ovcode.com" aria-label="Experience eSign"
                            target="_self" data-bi-cn="Experience eSign" data-bi-ecn="Experience eSign" data-bi-bhvr="0"
                            data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri="https://esign.ovcode.com">
                            Use eSign
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0" aria-expanded="false"
                        clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverxbc0585204b1147bca8b4d4bcdca77282" data-placement="bottom">
                        <img src={testlogo} loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="xplore eMark"
                            data-bi-ecn="xplore eMark" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href={apiDocURL}
                            aria-label="xplore eMark"
                            target="_self" data-bi-cn="xplore eMark" data-bi-ecn="xplore eMark" data-bi-bhvr="0"
                            data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri={apiDocURL}>
                            Explore eMark
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                </ul>
            </nav>
        </div>

        //     <div>
        //         <section>
        //             <div class="row row-cols-1   row-cols-sm-2 row-cols-lg-3">
        //                 <div class="col-11 col-lg-4">
        //                     <div class="card" style={{ height: '100%'}}>
        //                         <div class="card-body" style={{ textAlign: 'start', padding: '1.5rem', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
        //                             <img data-src={ovi} class="d-block me-auto lazyloaded" role="presentation" alt="" src={ovi} style={{ width: 100 }} />
        //                             <h3
        //                                 style={{
        //                                     fontSize: '1.25rem',
        //                                     lineGeight: '1.75rem',
        //                                     paddingTop: '1rem!important'
        //                                 }}
        //                             >
        //                                 eID
        //                             </h3>
        //                             <p style={{ marginBottom: '0!important' }}>Experience the seamless synthesis of conveniece and data security with OVI.</p>
        //                         </div>
        //                         {/* <div class="card-footer mt-4">
        //                             <Box sx={{ cursor: 'pointer', mt: 2, mb: 1 }}>
        //                                 <Grid item xs={12}>
        //                                     <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                         <Grid item xs={6} sx={{ textAlign: 'center' }}>
        //                                             <Link target="_blank" href="https://eid.ovcode.com" underline="none" >
        //                                                 <Typography sx={{ background: "#017eff", mb: 2, padding: "8px 10px", borderRadius: 2, mr: "10px", fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : "90%" }}>
        //                                                     Experience
        //                                                 </Typography>
        //                                             </Link>
        //                                         </Grid>
        //                                         <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                             <Typography sx={{ mb: 2, padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#017eff", fontWeight: "bold" }} onClick={handleOpeneID}>
        //                                                 View Demo
        //                                             </Typography>
        //                                         </Grid>
        //                                     </Grid>
        //                                 </Grid>
        //                             </Box>
        //                         </div> */}
        //                         {/* <img
        //                             data-src={PastelThree}
        //                             class="lazyloaded"
        //                             role="presentation"
        //                             alt=""
        //                             src={PastelThree}
        //                             style={{
        //                                 borderBottomLeftRadius: 12,
        //                                 borderBottomRightRadius: 12,
        //                                 maxHeight: 43,
        //                                 minHeight: 43
        //                             }}
        //                         /> */}
        //                     </div>
        //                 </div>
        //                 {/* <div class="col-11 col-lg-4">
        //                     <div class="card" style={{ height: '100%', borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)', background: '#fff', borderBottomRightRadius: 12, borderBottomLeftRadius: 12  }}>
        //                         <div class="card-body" style={{ textAlign: 'start', padding: '1.5rem', borderTopLeftRadius: 12, borderTopRightRadius: 12, }}>
        //                             <img data-src={ovsign} class="d-block me-auto lazyloaded" role="presentation" alt="" src={ovsign} style={{ width: 100 }} />
        //                             <h3
        //                                 style={{
        //                                     fontSize: '1.25rem',
        //                                     lineGeight: '1.75rem',
        //                                     paddingTop: '1rem!important'
        //                                 }}
        //                             >
        //                                 eSign
        //                             </h3>
        //                             <p style={{ marginBottom: '0!important' }}>Streamline your document approval process with eSign the ultimate solution for secure digital signing.</p>
        //                         </div>
        //                         <div class="card-footer mt-4">
        //                             <Box sx={{ cursor: 'pointer', mt: 2, mb: 1 }}>
        //                                 <Grid item xs={12}>
        //                                     <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                         <Grid item xs={6} sx={{ textAlign: 'center' }}>
        //                                             <Link target="_blank" href="https://esign.ovcode.com" underline="none" >
        //                                                 <Typography sx={{ background: "#017eff", mb: 2, padding: "8px 10px", borderRadius: 2, mr: "10px", fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : "90%" }}>
        //                                                     Experience
        //                                                 </Typography>
        //                                             </Link>
        //                                         </Grid>
        //                                         <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                             <Typography sx={{ mb: 2, padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#017eff", fontWeight: "bold" }} onClick={handleOpenOVSIGN}>
        //                                                 View Demo
        //                                             </Typography>
        //                                         </Grid>
        //                                     </Grid>
        //                                 </Grid>
        //                             </Box>
        //                         </div>
        //                         <img
        //                             data-src={PastelThree}
        //                             class="lazyloaded"
        //                             role="presentation"
        //                             alt=""
        //                             src={PastelThree}
        //                             style={{
        //                                 borderBottomLeftRadius: 12,
        //                                 borderBottomRightRadius: 12,
        //                                 maxHeight: 43,
        //                                 minHeight: 43
        //                             }}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div class="col-11 col-lg-4">
        //                     <div class="card" style={{ height: '100%', borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)', background: '#fff' , borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }}>
        //                         <div class="card-body" style={{ textAlign: 'start', padding: '1.5rem', borderTopLeftRadius: 12, borderTopRightRadius: 12, }}>
        //                             <img data-src={testlogo} class="d-block me-auto lazyloaded" role="presentation" alt="" src={testlogo} style={{ width: 100 }} />
        //                             <h3
        //                                 style={{
        //                                     fontSize: '1.25rem',
        //                                     lineGeight: '1.75rem',
        //                                     paddingTop: '1rem!important'
        //                                 }}
        //                             >
        //                                 eMark
        //                             </h3>
        //                             <p style={{ marginBottom: '0!important' }}>Build intelligent, collaborative apps that engage users in their flow of work with Teams.</p>
        //                         </div>
        //                         <div class="card-footer mt-4">
        //                             <Box sx={{ cursor: 'pointer', mt: 2, mb: 1 }}>
        //                                 <Grid item xs={12}>
        //                                     <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                         <Grid item xs={6} sx={{ textAlign: 'center' }}>
        //                                             <Link sx={{ cursor: 'pointer' }} target="_blank" href={apiDocURL} underline="none" >
        //                                                 <Typography sx={{ background: "#017eff", mb: 2, padding: "8px 10px", borderRadius: 2, mr: "10px", fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : "90%" }}>
        //                                                     Explore
        //                                                 </Typography>
        //                                             </Link>
        //                                         </Grid>
        //                                          <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                                 <Typography sx={{ mb: 2, padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#017eff", fontWeight: "bold" }} onClick={(e) => setOpenOVI(true)}>
        //                                                     View Demo
        //                                                 </Typography>
        //                                             </Grid> 
        //                                     </Grid>
        //                                 </Grid>
        //                             </Box>
        //                         </div>
        //                         <img
        //                             data-src={PastelThree}
        //                             class="lazyloaded"
        //                             role="presentation"
        //                             alt=""
        //                             src={PastelThree}
        //                             style={{
        //                                 borderBottomLeftRadius: 12,
        //                                 borderBottomRightRadius: 12,
        //                                 maxHeight: 43,
        //                                 minHeight: 43
        //                             }}
        //                         />
        //                     </div> 
        //                 </div>*/}
        //             </div>
        //         </section>
        //         {openOVSIGN &&
        //             <OvsignDemoVideoDialog
        //                 handleClose={handleCloseOVSIGNs}
        //                 handleCloseOVSIGNs={handleCloseOVSIGNs}
        //                 openOVSIGN={openOVSIGN}
        //             />
        //         }

        //         {openeID &&
        //             <OviDemoVideoDialog
        //                 handleClose={handleCloseeID}
        //                 handleCloseOVI={handleCloseeID}
        //                 openOVI={openeID}
        //                 setOpenOVI={setOpeneID}
        //             />
        //         }
        // </div>

        // <Box sx={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
        //     <div class="layout-container responsivegrid mt-2 pb-7 aem-GridColumn aem-GridColumn--default--12">
        //         <div class="aem-Grid aem-Grid--12 aem-Grid--default--12  grid-image-layout-container">
        //             <div class="container" >
        //                 <div class="content-card-list aem-GridColumn aem-GridColumn--default--12">
        //                     <section>
        //                         <div class="row row-cols-1 row-cols-md-3">
        //                             <div class="col mb-5 mb-md-0">
        //                                 <div class="card h-100 material-card overflow-hidden" style={{border: '1px solid #ededed',  boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)'}}>
        //                                     <div class="px-4"
        //                                         style={{
        //                                             backgroundImage: `url(${PastelThree})`,
        //                                             backgroundSize: 'cover',
        //                                             backgroundRepeat: 'no-repeat',
        //                                             minHeight: '30vh',
        //                                             maxHeight: '30vh',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <img class="card-img   pt-3 "
        //                                             width="300"
        //                                             srcset={ovi}
        //                                             src={ovi}
        //                                             style={{ width: '30%' }}
        //                                         />
        //                                     </div>

        //                                     <div class="card-body pt-3 px-4"
        //                                         style={{
        //                                             backgroundColor: '#fff',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <div class="card">
        //                                             <div class="card-body" style={{ width: '90%', margin: 'auto', mt: 2 }}>
        //                                                 <Typography sx={{ textAlign: 'start', fontWeight: 700, mt: 0, pt: 0, pb: 2, fontSize: '24px', lineHeight: 1.5 }}>eID</Typography>
        //                                                 <Box sx={{ height: 100 }}>
        //                                                     <Typography sx={{ margin: 0, fontWeight: 400, fontSize: '16px', lineHeight: 1.5, textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
        //                                                         Experience the seamless synthesis of conveniece and data security with OVI.
        //                                                     </Typography>
        //                                                 </Box>
        //                                             </div>
        //                                             <div class="card-footer mt-4">
        //                                                 <Box sx={{ cursor: 'pointer', mt: 1, mb: 1 }}>
        //                                                     <Grid item xs={12}>
        //                                                         <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                                             <Grid item xs={6} sx={{ textAlign: 'center' }}>
        //                                                                 <Link target="_blank" href="https://eid.ovcode.com/" underline="none" >
        //                                                                     <Typography sx={{ background: "#017eff", mb:2,  padding: "8px 10px", borderRadius: 2, mr: "10px", fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : "70%" }}>
        //                                                                         Experience
        //                                                                     </Typography>
        //                                                                 </Link>
        //                                                             </Grid>
        //                                                             <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                                                 <Typography sx={{ mb:2,  padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#017eff", fontWeight: "bold" }} onClick={(e) => setOpenOVI(true)}>
        //                                                                     View Demo
        //                                                                 </Typography>
        //                                                             </Grid>
        //                                                         </Grid>
        //                                                     </Grid>
        //                                                 </Box>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div class="col mb-5 mb-md-0">
        //                                 <div class="card h-100 material-card overflow-hidden" style={{border: '1px solid #ededed',  boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)'}}>
        //                                     <div class="px-4"
        //                                         style={{
        //                                             backgroundImage: `url(${PastelThree})`,
        //                                             backgroundSize: 'cover',
        //                                             backgroundRepeat: 'no-repeat',
        //                                             minHeight: '30vh',
        //                                             maxHeight: '30vh',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <img class="card-img   pt-3 "
        //                                             width="300"
        //                                             srcset={ovsign}
        //                                             src={ovsign}
        //                                             style={{ width: '30%' }}
        //                                         />
        //                                     </div>
        //                                     <div class="card-body pt-3 px-4"
        //                                         style={{
        //                                             backgroundColor: '#fff',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <div class="card " >
        //                                             <div class="card-body" style={{ width: '90%', margin: 'auto', mt: 2 }}>
        //                                                 <Typography sx={{ textAlign: 'start', fontWeight: 700, mt: 0, pt: 0, pb: 2, fontSize: '24px', lineHeight: 1.5 }}>eSign</Typography>

        //                                                 <Box sx={{ height: 100 }}>
        //                                                     <Typography sx={{ margin: 0, fontWeight: 400, fontSize: '16px', lineHeight: 1.5, textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
        //                                                         Streamline your document approval process with eSign the ultimate solution for secure digital signing.
        //                                                     </Typography>
        //                                                 </Box>
        //                                             </div>
        //                                             <div class="card-footer mt-4">
        //                                                 <Box sx={{ cursor: 'pointer', mt: 1, mb: 1 }}>
        //                                                     <Grid item xs={12}>
        //                                                         <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                                             <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                                                 <Link target="_blank" href="https://esign.ovcode.com/" underline="none" >
        //                                                                     <Typography sx={{ mb:2, background: "#017eff", padding: "8px 10px", borderRadius: 2, mr: "10px", fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : "70%" }}>
        //                                                                         Experience
        //                                                                     </Typography>
        //                                                                 </Link>
        //                                                             </Grid>
        //                                                             <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                                                 <Typography sx={{ mb:2, padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#017eff", fontWeight: "bold" }} onClick={(e) => setOpenOVSIGN(true)}>
        //                                                                     View Demo
        //                                                                 </Typography>
        //                                                             </Grid>
        //                                                         </Grid>
        //                                                     </Grid>
        //                                                 </Box>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div class="col ">
        //                                 <div class="card h-100 material-card overflow-hidden" style={{border: '1px solid #ededed',  boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)'}} >
        //                                     <div class="px-4"
        //                                         style={{
        //                                             backgroundImage: `url(${PastelThree})`,
        //                                             backgroundSize: 'cover',
        //                                             backgroundRepeat: 'no-repeat',
        //                                             minHeight: '30vh',
        //                                             maxHeight: '30vh',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <img class="card-img   pt-3 "
        //                                             width="300"
        //                                             srcset={testlogo}
        //                                             src={testlogo}
        //                                             style={{ width: '30%' }}
        //                                         />
        //                                     </div>
        //                                     <div class="card-body pt-3 px-4"
        //                                         style={{
        //                                             backgroundColor: '#fff',
        //                                             width: '100%',
        //                                             margin: 'auto',
        //                                             display: 'flex',
        //                                             alignItems: 'center',
        //                                             justifyContent: 'center'
        //                                         }}
        //                                     >
        //                                         <div class="card " >
        //                                             <div class="card-body" style={{ width: '90%', margin: 'auto', mt: 2 }}>
        //                                                 <Typography sx={{ textAlign: 'start', fontWeight: 700, mt: 0, pt: 0, pb: 2, fontSize: '24px', lineHeight: 1.5 }}>eMark</Typography>

        //                                                 <Box sx={{ height: 100 }}>
        //                                                     <Typography sx={{ margin: 0, fontWeight: 400, fontSize: '16px', lineHeight: 1.5, textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
        //                                                         Provides a secure and efficient way to authenticate digital assets using blockchain technology.
        //                                                     </Typography>
        //                                                 </Box>
        //                                             </div>
        //                                             <div class="card-footer mt-4">
        //                                                 <Box sx={{ cursor: 'pointer', mt: 1, mb: 1 }}>
        //                                                     <Grid item xs={12}>
        //                                                         <Grid container spacing={2} sx={{ margin: 'auto' }}>
        //                                                             <Grid item xs={6} sx={{ textAlign: 'center', margin: 'auto' }}>
        //                                                                 <Link sx={{ cursor: 'pointer' }} target="_blank" href={apiDocURL} underline="none" >
        //                                                                     <Typography sx={{ mb:2, background: "#017eff", padding: "8px 10px", borderRadius: 2, fontSize: 16, color: "#fff", fontWeight: "bold", width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : "70%" }}>
        //                                                                         Explore
        //                                                                     </Typography>
        //                                                                 </Link>
        //                                                             </Grid>
        //                                                             <Grid item xs={6} sx={{ mb:2, textAlign: 'center', margin: 'auto' }}>
        //                                                             </Grid>
        //                                                         </Grid>
        //                                                     </Grid>
        //                                                 </Box>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </section>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </Box > */}
    )

}

export default SolutionsShowCaseComponent
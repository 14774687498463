import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../../../hooks/useWidth";
import VerificationBannerComponent from "./verification-banner.component";
import ExploreVerificationComponent from "./explore-verification.component";
import AboutVerificationComponent from "./about-verification.component";
import VerifyComponent from "../../../landing/verify/verify.component";
import BackToTopButton from "../../../layout/scroll-up.component";
import { useEffect, useState } from "react";
import Chatbot from "../../../layout/chatbot.component";


const VerificationComponent = () => {

    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);




    return (
        <Box>

            <VerificationBannerComponent />
   
            {/* <div class="container">
                { isChatbotOpen ?
                    null
                :
                    <BackToTopButton isChatbotOpen={isChatbotOpen} />
                }
                <Chatbot setIsChatbotOpen={setIsChatbotOpen} />
            </div> */}

         
            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '93%', display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                <VerifyComponent />
            </Box>

            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '93%', display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                <ExploreVerificationComponent />
            </Box>

            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '93%', display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                <AboutVerificationComponent />
            </Box>
         
        </Box>
    )
}

export default VerificationComponent;

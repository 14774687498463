
import React, { useRef, useState } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import DigitalSignatureImage from '../../../images/products/ovcode-digital-signature.jpg';
import VerifyAuthenticityImage from '../../../images/products/ovcode-verify-authenticity.jpg';
import CreateNFTImage from '../../../images/products/ovcode-create-nft.jpg';


const ServicesOneComponent = () => {

    const breakpoints = useWidth();

    return (

        <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: 6 }}>
            <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <img src={DigitalSignatureImage} style={{ width: '80%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }} />
                <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>Document Authentication</Typography>
                <Typography sx={{ width: '85%', margin: 'auto', fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>OVCODE offers straightforward solutions for your agreement and approval needs. The simple yet innovative design of the digital signature tool allows all stakeholders to participate, and eliminates the complexities of workflows attached to digital signing.</Typography>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={VerifyAuthenticityImage} style={{ width: '80%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }} />
                        <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>Verify Authenticity</Typography>
                        <Typography sx={{ width: '85%', margin: 'auto', fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>OVCODE creates an offline and online verification engine to keep the integrity of data in digital forms. OVCODE's identification and authentication tools inform the owner of one's data origin and history.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={CreateNFTImage} style={{ width: '80%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }} />
                        <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>Create NFT</Typography>
                        <Typography sx={{ width: '85%', margin: 'auto', fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>OVCODE provides the NFT infrastructure to create unique and immutable representations of your digital assets using OVCODE NFT, with easy-to-use tools to unlock the economic value of your digital assets</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    );

}

export default ServicesOneComponent

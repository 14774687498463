import axios from 'axios';
import {QueryParamsFormat} from "./http-utils";
import environment from "../../../environment/environment";

const SUBSCRIPTION_KEY = environment.ovcode_website_service.subscriptionKey;


const CustomHttpPost = (path, payload) => {
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualPost(path, payload);

        resolve(result);
    })

    return promise
}

const CustomHttpActualPost = (path, payload) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    let apiEndpoint = path;
    let result = axios.post(apiEndpoint, payload, headerParameters);

    console.log("CustomHttpActualPost final params xxxx", apiEndpoint , payload, headerParameters);
    console.log("CustomHttpActualPost result xxxx", result);

    return result;
}

const CustomHttpPostParams = (path, parameters, subscriptionKey) => {
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualPostParams(path, parameters, subscriptionKey);

        resolve(result);
    })

    return promise
}

const CustomHttpActualPostParams = (path, parameters, subscriptionKey) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    let apiEndpoint = path;
    let result = axios.post(apiEndpoint, parameters, headerParameters);

    console.log("CustomHttpActualPostParams final params xxxx", apiEndpoint, headerParameters, subscriptionKey);
    console.log("CustomHttpActualPostParams result xxxx", result);

    return result;
}

const CustomHttpGet = (path, parameters, subscriptionKey) => {
    console.log("CustomHttpGet xxx", path, parameters, subscriptionKey)
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualGet(path, parameters, subscriptionKey);

        resolve(result);
    })

    return promise
}

const CustomHttpActualGet = (path, parameters, subscriptionKey) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    let apiEndpoint = path;
    let result = axios.get(apiEndpoint, headerParameters);

    console.log("CustomHttpActualGet final params xxxx", apiEndpoint, headerParameters, subscriptionKey);
    console.log("CustomHttpActualGet result xxxx", result);

    return result;
}

const CustomHttpGetParams = (path, sharedKey, subscriptionKey) => {
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualGetParams(path, sharedKey, subscriptionKey);

        resolve(result);
    })

    return promise
}

const CustomHttpActualGetParams = (path, sharedKey, subscriptionKey) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Shared-Key": sharedKey
        }
    }

    let apiEndpoint = path;
    let result = axios.get(apiEndpoint, headerParameters);

    console.log("CustomHttpActualGetParams final params xxxx", apiEndpoint, headerParameters, subscriptionKey);
    console.log("CustomHttpActualGetParams result xxxx", result);

    return result;
}

const HttpDownloadFile = (path, sharedKey, subscriptionKey) => {
    var promise = new Promise((resolve, reject) => {
        const result = HttpActualDownloadFile(path, sharedKey, subscriptionKey);

        resolve(result);
    })

    return promise
}

const HttpActualDownloadFile = (path, sharedKey, subscriptionKey) => {
    
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Shared-Key": sharedKey
        },
        responseType: "blob",
    }

    let apiEndpoint = path;
    let result = axios.get(apiEndpoint, headerParameters);

    console.log("HttpActualDownloadFile final params xxxx", apiEndpoint, headerParameters, subscriptionKey);
    console.log("HttpActualDownloadFile result xxxx", result);

    return result;
}

const HttpPost = (path, payload) => {

    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
 

            // if (isAuthenticated) {
            //   AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualPost(path, payload, accessToken)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpPost error xxxx", err)
                  
            //     })
            //     .catch((err) => {})
            // } else {
            //     const result = HttpActualPost(path, payload)
            //     resolve(result)
            // }
    })

    return promise

}


const HttpPostMultipart = (path, payload) => {

    // let isAuthenticated = AuthService.isUserLoggedIn();


    var promise = new Promise((resolve, reject) => {

            // if (isAuthenticated) {
            //    AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualPostMultipart(path, payload, accessToken)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpPostMultipart error xxxx", err)

            //     })
            //     .catch((err) => {})
                
            // } else {
            //     const result = HttpActualPostMultipart(path, payload)
            //     resolve(result)
            // }
    })

    return promise

}

const HttpActualPost = (path, payload, accessToken = null) => {

    let token = accessToken;
    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token, 
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    let apiEndpoint = path;

    console.log("HttpPost final params xxxx", apiEndpoint , payload, headerParameters);

    let result = axios.post(apiEndpoint, payload, headerParameters);

    console.log("HttpActualPost result xxxx", result);
    return result
}

const HttpActualPostMultipart = (path, payload, accessToken = null) => {

    let token = accessToken;
    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token, 
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
            'Content-Type': 'multipart/form-data'
        }
    }
    console.log("headerParameters", headerParameters)
    let apiEndpoint = path;
    let result = axios.post(apiEndpoint, payload, headerParameters);

    console.log("HttpActualPostMultipart result xxxx", result);

    return result
}


const HttpGet = (path, parameters = '', subscriptionKey, loaderType) => {

    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   
            // if (isAuthenticated) {
            //      AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken);

            //         const result = HttpActualGet(path, parameters, accessToken, subscriptionKey, loaderType)
                    
            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpGet error xxxx", err)
            //     })
            // } else {
            //     const result = HttpActualGet(path, parameters, "", subscriptionKey, loaderType)
            //     resolve(result)
            // }

        } catch (error) {
            reject(error)
        }
    })

    return promise

}

const HttpActualGet = (path, parameters = '', accessToken = null, subscriptionKey, loaderType) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("HttpActualGetheaderParameters", headerParameters);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    let result = axios.get(apiEndpoint + queryParams, headerParameters);

    console.log("HttpActualGet result xxxx", result);
    
    return result
}

const HttpRawGet = (path, parameters = '', subscriptionKey, loaderType) => {

    // let isAuthenticated = AuthService.isUserLoggedIn();


    var promise = new Promise((resolve, reject) => {
        try {   
            // if (isAuthenticated) {

            //    AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualRawGet(path, parameters, accessToken, subscriptionKey, loaderType)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpRawGet error xxxx", err)

            //     })
            // } else {
            //     const result = HttpActualRawGet(path, parameters, "", subscriptionKey, loaderType)
            //     resolve(result)
            // }

        } catch (error) {
            reject(error)
        }
    })

    return promise

}

const HttpActualRawGet = (path, parameters = '', accessToken = null, subscriptionKey, loaderType) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("headersHere", headerParameters)

    let apiEndpoint = path + "?" + parameters;

    console.log("GetURL:", apiEndpoint);

    return axios.get(apiEndpoint, headerParameters)
}



const HttpPut = (path, payload) => {


    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            // if (isAuthenticated) {
            //    AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualPut(path, payload, accessToken)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpPut error xxxx", err)

            //     })
            //     .catch((err) => {})

            // } else {
            //     const result = HttpActualPut(path, payload)
            //     resolve(result)
            // }

        } catch (error) {
            reject(error)
        }
    })

    return promise

}

const HttpActualPut = (path, payload, accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        }
    }

    let apiEndpoint = path;

    let result = axios.put(apiEndpoint , payload, headerParameters);

    console.log("HttpActualPut result xxxx", result);
    return result;
    
}


const HttpPut1 = (path, parameters="", payload) => {

    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            // if (isAuthenticated) {
            //    AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualPut1(path, parameters, payload, accessToken)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpPut1 error xxxx", err)
            //     })
            // } else {
            //     const result = HttpActualPut1(path, parameters = '', payload, )
            //     resolve(result)
            // }

        } catch (error) {
            reject(error)
        }
    })

    return promise

}

const HttpActualPut1 = (path, parameters = "", payload, accessToken = null) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    console.log("HttpPutheaderParameters xxxx", headerParameters, path, parameters, payload, accessToken);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    let result = axios.put(apiEndpoint + queryParams, payload, headerParameters);

    console.log("HttpActualPut1 result xxxx", result);
    return result;
}

const HttpDelete = (path, parameters) => {


    // const MsalAuthenticate = window.MsalAuthenticate
    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            // if (isAuthenticated) {
            //    AuthService.getAuthToken().then(({accessToken})=>{
            //         setAuthToken(accessToken)
            //         const result = HttpActualDelete(path, parameters, accessToken)

            //         resolve(result)
            //     }, (err)=>{
            //         console.log("HttpDelete error xxxx", err);

            //         MsalAuthenticate.getTokenPopup().then(({accessToken})=>{
            //             const result = HttpActualDelete(path, parameters, accessToken)

            //             resolve(result)
            //         }, (err)=>{
            //             console.log("errrrrrr xxx: ", err)
            //             // logout
            //             localStorage.clear()
            //             window.location.reload()
            //         })
            //     })
            //     .catch((err) => {})

            // } else {
            //     const result = HttpActualDelete(path, parameters)
            //     resolve(result)
            // }

        } catch (error) {
            console.log("HTTP_PUT", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualDelete = (path, parameters = '', accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        }
    }

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters)
    if(queryParams)
        queryParams =  "?" + queryParams;
    else
        queryParams = '';

    let result = axios.delete(apiEndpoint + queryParams, headerParameters)

    return result;
}

const HttpPatch = (path, payload) => {
    // let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        // if (isAuthenticated) {
        //     AuthService.getAuthToken().then(({accessToken}) => {
        //         setAuthToken(accessToken);

        //         const result = HttpActualPatch(path, payload, accessToken);
        //         resolve(result);

        //     },  (error) => {
        //         console.log("getAuthToken1 error xxxx", error);
                  
        //     }).catch((error) => {
        //         console.log("getAuthToken2 error xxxx", error);
        //     })

        // } else {
        //     const result = HttpActualPatch(path, payload);

        //     resolve(result);
        // }
    })

    return promise
}

const HttpActualPatch = (path, payload, accessToken = null) => {
    let token = accessToken;

    if (token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token, 
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    console.log("headerParameters", headerParameters);

    let apiEndpoint = path;

    console.log("HttpPatch final params xxxx", apiEndpoint , payload, headerParameters);

    let result = axios.patch(apiEndpoint, payload, headerParameters);

    console.log("HttpActualPatch result xxxx", result);

    return result
}

const HttpPublicGet = (path, accessToken, subscriptionKey) => {

    var promise = new Promise((resolve, reject) => {
        try {   
            const result = HttpActualPublicGet(path, accessToken, subscriptionKey);
            resolve(result);

        } catch (error) {
            console.log("HttpPublicGet error xxxx", error);
            reject(error);
        }
    })

    return promise

}

const HttpActualPublicGet = (path, accessToken, subscriptionKey) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("HttpActualPublicGet headerParameters xxxxx", headerParameters);

    let apiEndpoint = path;

    console.log("apiEndpoint xxxx", apiEndpoint);

    let result = axios.get(apiEndpoint, headerParameters);

    console.log("HttpActualPublicGet result xxxx", result);
    
    return result
}

const CustomHttpPostMultipart = (path, payload, token) => {

    var promise = new Promise((resolve, reject) => {
        const result = HttpActualPostMultipart(path, payload, token);

        resolve(result);
    })
     
    return promise
}




export { HttpRawGet,HttpPost, HttpActualPost, HttpGet, HttpActualGet, HttpPut, HttpActualPut, HttpDelete, HttpActualDelete, 
    HttpDownloadFile,HttpPostMultipart,HttpActualPostMultipart, HttpPut1, HttpActualPut1, HttpPatch, HttpPublicGet, HttpActualPublicGet, 
    CustomHttpPostMultipart, CustomHttpPost, CustomHttpActualPost, CustomHttpPostParams, CustomHttpActualPostParams,
    CustomHttpGet, CustomHttpActualGet, CustomHttpGetParams, CustomHttpActualGetParams
}
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const technology_security = [
    // {
    //     name: "What is the technology behind OVCODE?",
    //     panel: "Panel1",
    //     description:
    //         <Box>
    //             <Typography sx={{ mb: 2 }}>
    //                 OVCODE integrates blockchain, digital signature, cryptography, and permanent storage. Our patented zero cycle latency algorithms ensure security, accuracy, and immutability of data on the OVCODE Blockchain network. Each hash has an equivalent entry in permanent storage, guaranteeing integrity. Both blockchain and permanent storage entries serve as proof of existence and integrity.
    //             </Typography>
    //         </Box>
    // },
    {
        name: "What are OVCODE’s Patents, Copyright, and Trademark?",
        panel: "Panel1",
        description:
            <Box>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        Patents:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>OVCODE Patent – Server Computer System for Implementing Transformation of an Original Entity into a Verifiably Authenticable Entity in a Heterogeneous Communications Network Environment
                                &nbsp;<i>Registration No. 4/2017/00006317 (Philippines)</i>
                            </Typography>
                        </li>
                        <li><Typography>OVCODE Patent Pending (Korea)</Typography></li>
                        <li><Typography>OVCODE Patent Pending (USA)</Typography></li>
                        <li><Typography>OVCODE Global WIPO PCT, Geneva Switzerland – patent reservation date for 155 Countries globally</Typography></li>
                        <li>
                            <Typography>One-click Patent – Method of Conducting a Workflow in an Electronic Transaction Platform System with Payment Distribution (Philippines)</Typography>
                        </li>
                    </ul>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        Copyright:
                    </Typography>
                    <ul>
                        <li><Typography>One-click Distribution – Certificate of Copyright Registration and Deposit </Typography></li>
                    </ul>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        Trademark:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>Zero-Cycle Latency – Apparatus, Instruments and Equipment primarily comprising data processing components for authentication and verification in a heterogeneous communications network environment
                            </Typography>
                        </li>
                    </ul>
                </Box>

            </Box>
    }

]

export default technology_security

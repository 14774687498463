import {Box, CardMedia, Grid, Typography} from "@mui/material";
import useWidth from "../../../../hooks/useWidth";


function FAQBannerComponent() {

    const breakpoints = useWidth();
    
    return(


        <Box sx={{mb: (breakpoints == "xs" || breakpoints == "sm") ? {} : 4}}>
            <Grid container spacing={2} sx={{justifyContent: "center", alignItems: "center"}}>
                <Grid item xs={12} sx={{backgroundColor: "#f2f2f2", paddingTop: "27px !important", paddingBottom: "8px !important"}}>   
                    <Box>
                    <Typography sx={{
          fontSize: 34,
          margin: 0,
          lineHeight: 1.2,
          fontWeight: 600,
          textAlign: 'center',
          color: "#000",
        //   mb: '1.25rem'
        }}>
          Frequently asked questions
        </Typography>
                    </Box>

                    <Box sx={{mb: 4, padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px 20px" : {}}}>
                        <Typography sx={{mt: "6px !important", fontSize: "15px", margin: 0, lineHeight: 1.6, color:'#000', fontWeight: 400, textAlign: 'center' }}>
                            Welcome to the OVCODE Trust Platform's FAQ section, designed to provide you with clarity and insights about our innovative trust solutions: 
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>


    )

}

export default FAQBannerComponent
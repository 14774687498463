import React from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import partners_images from "./partners-images.component";

const PartnersComponent = () => {

    const breakpoints = useWidth();
    
    return (
        <Box>
            <Box sx={{mb: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontSize: "37px",
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: "start",
                    mb: "1.25rem",
                }}>
                    Partners
                </Typography>
            </Box>
           
            <Grid container spacing={2}>
                {partners_images.map((value) => { 
                    return (
                        <Grid item xs={6} sm={4}>                       
                            <Card sx={{
                                boxShadow: 'none', 
                                border: 'none',  
                                width: "100%", 
                                margin: "auto",
                                padding: "25px",
                            }}>
                                <img alt="" src={value.icon} style={{width: (value.name == "cybersoft") ? "42%" : "50%"}} />
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>   
        </Box>    
    )  
}

export default PartnersComponent
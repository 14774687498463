import React from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import backgroundContactUs from "../../../images/contact-us.png";
import useWidth from '../../../hooks/useWidth';
import DrawerHeader from '../layout/drawer-header';


const ContactUsComponent = () => {

  const breakpoints = useWidth();

  const departments = [
    { name: 'Sales & Marketing', contact: 'info@ovcode.com' },
    { name: 'Helpdesk Support', contact: 'helpdesk@ovcode.com' },
    { name: 'Billing', contact: 'info@ovcode.com' },
    // { name: 'Development', contact: 'dev@ovcode.com' },
    { name: 'Project Management', contact: 'info@ovcode.com' },
  ];

  return (
    <div class="contaienr">

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundContactUs})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 320,
        maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 320,
        height: '100%',
        backgroundPosition: 'top',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        margin: 'auto 0',
        width: ' 100%',
        // opacity: 0.8
      }}>
        <Grid container sx={{padding: 3}}>
          <Grid item xs={5}>
          <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontSize: 37,
              margin: 0,
              lineHeight: 1.2,
              fontWeight: 600,
              textAlign: 'start',
              color: "#fff",
              textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"
            }}
          >
            Contact Us
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography sx={{
            fontSize: 14,
            margin: 0,
            lineHeight: 1.6,
            fontWeight: 400,
            textAlign: 'start',
            color: "#fff",
            textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"
          }}>
            Have questions or ready to explore our solutions? Contact us anytime. We're here to assist you on your digital trust journey.
          </Typography>
        </Box>
          </Grid>
        </Grid>
       
      </Box>
      <DrawerHeader />
      <Grid container spacing={3} sx={{ justifyContent: 'center', textAlign: 'center', alignSelf: 'center' }}>
        {departments.map((department, index) => (
          <Grid item xs={10} md={5} key={index} >
            <Paper elevation={1} sx={{ padding: 3, border: '1px solid #017eff94' }}>
              <Email color="primary" fontSize="large" />
              <Typography variant="h6" gutterBottom >
                {department.name}
              </Typography>
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  textAlign: 'center', // Center the text within the button
                  display: 'flex',   // Center the button horizontally
                  margin: 'auto'      // Center the button horizontally
                }}
                endIcon={<EastIcon />}
              >
                <a href={`mailto:${department.contact}`} style={{ textDecoration: 'none' }}>Email Us</a>
              </Button>

              {/* <Typography variant="body1">
                   Chat now <a href={`mailto:${department.contact}`} style={{textDecoration: 'none'}} />
                  </Typography> */}
              {/* {department.name === 'Sales & Marketing' || department.name === 'Helpdesk Support' ? 
                  <Typography variant="body1">
                    Email: <a href={`mailto:${department.contact}`} style={{textDecoration: 'none'}}>{department.contact}</a>
                  </Typography>
                :
                  <a href={`mailto:${department.contact}`} style={{textDecoration: 'none'}} />
                }
                 */}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ContactUsComponent;

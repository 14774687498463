import { AppBar, Container, Grid, Toolbar } from "@mui/material"
import FooterComponent from "../components/footer/footer.component"


function FooterScreen() {

    const url = window.location.pathname;


    return(

        <AppBar position="static" sx={{ position: 'relative', backgroundColor: '#fff', boxShadow: 'none', pb: 3, pt: url === "/version-histories" ? 3 : 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ padding: '0px !important' }}>
                <FooterComponent />
            </Toolbar>
        </AppBar>

    )
    
}

export default FooterScreen
import { Box, Card, CardMedia, Grid, Link, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import OVSignLogo from '../../../images/usecase/ovsign-logo.png'
import DrawerHeader from "../../layout/drawer-header";

function UsecaseComponent() {

    const breakpoints = useWidth();

    return(
        <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? '90%' : {}, margin: 'auto'}}>
            <DrawerHeader/>
            <Box sx={{mb: 4}}>
                <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
                    UseCases
                </Typography>
            </Box>

            <Link target="_blank" href="https://esign.ovcode.com/" underline="none">
                <Card sx={{ boxShadow: 'none', cursor:'pointer'}} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} sx={{alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                            <CardMedia
                                component="img"
                                image={OVSignLogo}
                                style={{objectFit: 'contain', margin:'auto', width: '50%'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography sx={{textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'start',fontWeight: 600, mt: 1, pt: 1,pb: 2,fontSize: 20,lineHeight: 1.6}}>
                                OVSign Document Workflow
                            </Typography>
                            <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', lineHeight: 1.5,  textAlign: 'start',  color: 'rgb(100, 110, 115)'}}>
                                Simplify your document approval process with OVSign - the ultimate solution for easy and secure digital signing. Our intuitive interface simplifies the process, eliminating complex workflows. Sign agreements, contracts, and authenticate documents with ease and security using OVSign.
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Link>
            

        </Box>
    )
    
}

export default UsecaseComponent
import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';



const ElectronicSignatureReleaseNotesv2 = () => {

    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card sx={{
            borderRadius: '4px',
            marginBottom: '10px',
            transition: '.5s',
            boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
            '&:hover': {
                boxShadow: '0 0 12px 2px rgba(0,0,0,0.16)',
            },
        }}>
            <div style={{ padding: '40px' }}>
                <header style={{ textAlign: 'start' }}>
                    <Typography variant="h5" component="div"  sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}>
                        eSign Version 1.0.0
                    </Typography>
                    <div style={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#999'
                    }}>
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{background: '#00800061'}} /> &nbsp;
                                <Chip label={"FIXES"} sx={{background: '#ff000057'}} /> 
                            </ListItem>
                        </List>
                        <Typography variant="body2" component="span" className="posted-time">
                            <time dateTime="2023-11-24">November 24, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>

                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Display CC Emails on Signing and Document Detailed View</Typography>}
                                    secondary="Added a new feature to display CC emails during the signing process and in the detailed 
                                    view of the document."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Tooltips for Buttons</Typography>}
                                    secondary="Introduced tooltips for buttons to provide users with information about the functions of 
                                    each button, improving user guidance and interaction."
                                />
                            </ListItem>
                        </List>
                            <br/>
                            <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                           Fixes
                         </Typography>
                         <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Debugging Issue on Signing Reflecting on Activity Logs</Typography>}
                                    secondary="Fixed a debugging issue related to signing that was not accurately reflecting in the 
                                    activity logs."
                                />
                            </ListItem>
                          
                            {showFullDetails && (
                                <>
                                   
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon sx={{ width: 12, height: 12 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Counter on Signing Mismatch on Number of Signatures to Sign</Typography>}
                                        secondary="Addressed a counter mismatch issue during signing, ensuring it accurately reflects the 
                                        number of signatures required."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon sx={{ width: 12, height: 12 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Auto Logout on Session Expiry</Typography>}
                                        secondary="Resolved the auto logout issue that occurred when the session expired, providing a 
                                        smoother user experience."
                                    />
                                </ListItem>
                           
                            </>
                            )}
                            </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    )
}

export default ElectronicSignatureReleaseNotesv2
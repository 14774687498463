import React, {useState, useEffect} from "react";
import {Container, Typography, Card} from "@mui/material"; 

const TextViewerComponent = () => {

    const [textData, setTextData] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem("textData");

        if (storedData) {
            setTextData(storedData);
        }
    }, []);

    return (
        <>
            {textData && 
                <Container maxWidth="md" sx={{mt: 5}}> 
                    <Card sx={{padding: 3.5, border: "1px solid rgba(0, 0, 0, 0.26)", borderRadius: "8px", boxShadow: "none"}}>
                        <Typography sx={{textAlign: "justify", fontSize: "16px"}}>
                            {textData}
                        </Typography>
                    </Card>
                </Container>    
            }
        </>
    )
}

export default TextViewerComponent
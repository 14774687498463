import React from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import awards_images from "./awards-images.component";

const AwardsComponent = () => {

    const breakpoints = useWidth();
    
    return (
        <Box>
            <Box sx={{mb: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontSize: "37px",
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: "start",
                    mb: "1.25rem",
                }}>
                    Awards
                </Typography>
            </Box>
           
            <Grid container spacing={2}>
                {awards_images.map((value) => { 
                    return (
                        <Grid item xs={12} sm={6}>                       
                            <Card sx={{
                                boxShadow: 'none', 
                                border: 'none', 
                                // borderRadius: 4, 
                                width: "100%", 
                                margin: "auto",
                                padding: "25px",
                                // padding: 5, 
                            }}>
                                <img alt="" src={value.icon} style={{width: "50%"}} />
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>   
        </Box>    
    )  
}

export default AwardsComponent
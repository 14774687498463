import AWS from "../../../images/partners/partners-aws.jpg";
import CYBERSOFT from "../../../images/partners/partners-cybersoft.png";
import EURO from "../../../images/partners/partners-euro.jpg";
import IMERGEX from "../../../images/partners/partners-imergex.jpg";
import MICROSOFT from "../../../images/partners/partners-microsoft.jpg";
import MINDSTYLE from "../../../images/partners/partners-mindstyle.jpg";
import NEXTIX from "../../../images/partners/partners-nextix.jpg";
import PACI from "../../../images/partners/partners-paci.jpg";
import WEF from "../../../images/partners/partners-wef.jpg";


const partners_images = [
    {
        name: 'nextix',
        icon: NEXTIX,
        icon_hover: NEXTIX
    },

    {
        name: 'microsoft',
        icon: MICROSOFT,
        icon_hover: MICROSOFT
    },

    {
        name: 'aws',
        icon: AWS,
        icon_hover: AWS
    },

    {
        name: 'mindstyle',
        icon: MINDSTYLE,
        icon_hover: MINDSTYLE
    },

    {
        name: 'wef',
        icon: WEF,
        icon_hover: WEF
    },

    {
        name: 'paci',
        icon: PACI,
        icon_hover: PACI
    },

    {
        name: 'imergex',
        icon: IMERGEX,
        icon_hover: IMERGEX
    },

    {
        name: 'cybersoft',
        icon: CYBERSOFT,
        icon_hover: CYBERSOFT
    },
    {
        name: 'euro',
        icon: EURO,
        icon_hover: EURO
    }
]


export default partners_images
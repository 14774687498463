import React, { useRef, useState } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import partners_images from "./partners-images.component";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation, HashNavigation } from "swiper";
import "swiper/css/effect-cards";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function PartnersComponent() {

    const breakpoints = useWidth();
    const [swiper, setSwiper] = useState(null);

    return (
        <Box>
            <Box>
                <Typography sx={{
                    fontSize: 37,
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: 'start',
                    color: "#000",
                    mb: '1.25rem'
                }}>
                    Partners
                </Typography>
            </Box>

            <Grid container spacing={2}>
                {partners_images.map((value) => {
                    return (
                        <Grid item xs={4}>
                            <Card sx={{ boxShadow: 'none' }}>
                                <CardMedia
                                    component="img"
                                    image={value.icon}
                                    style={{ objectFit: 'contain', margin: 'auto', height: 80 }}
                                />
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>

            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <button onClick={() => swiper.slidePrev()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowBackIosIcon />
                </button>
                <Swiper
                    pagination={{ clickable: true }}
                    className="mySwiper"
                    onSwiper={setSwiper}
                    slidesPerView={(breakpoints == "xs" || breakpoints == "sm") ? 1 : 5}
                    spaceBetween={5}
                    modules={[Pagination]}
                >
                    {partners_images.map((value) => {
                        return (
                            <SwiperSlide>
                                <div style={{ display: "flex", height: "100%", width: "90%" }}>
                                    <Card sx={{ boxShadow: 'none' }} >
                                        <Card sx={{ boxShadow: 'none' }} >
                                            <CardMedia
                                                component="img"
                                                image={value.icon}
                                                style={{ objectFit: 'contain', margin: 'auto', height: 120 }}
                                            />
                                        </Card>
                                    </Card>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <button onClick={() => swiper.slideNext()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
                    <ArrowForwardIosIcon />
                </button>
            </div> */}
        </Box>
    )

}

export default PartnersComponent
import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import LatestNews from "../../../../../images/blog/pwered-kpi.png";


const PoweredKPIComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE’s Blockchain-Powered PKI: <br />  A New Paradigm for Digital Trust
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 01 April 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={LatestNews} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the era of digital transformation, the traditional models of establishing trust and verifying identities online are evolving.
                                OVCODE is at the forefront of this evolution, pioneering a new paradigm for digital trust with its blockchain-powered Public Key Infrastructure (PKI).
                                This innovative approach is redefining security, reliability, and trust for the digital age.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Reinventing PKI with Blockchain
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Public Key Infrastructure has long been the standard for secure digital communications.
                                OVCODE’s integration of blockchain into PKI takes this to the next level, combining the established trust mechanisms of PKI with the immutability and transparency of blockchain technology.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Immutable Digital Identities
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Blockchain enables OVCODE to create a tamper-proof record of digital identities, ensuring that once an identity is verified, it cannot be altered or falsified.
                                This immutable aspect of blockchain complements the PKI’s authentication, providing an extra layer of security and trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Decentralized Trust Model
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                By leveraging a decentralized architecture, OVCODE’s blockchain-powered PKI eliminates single points of failure and potential targets for cyber-attacks, ensuring a more resilient and robust framework for digital trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Advantages of OVCODE’s Blockchain-Powered PKI
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The unique combination of blockchain with PKI offers several distinct advantages, positioning OVCODE as a leader in secure digital interactions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhanced Security
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The blockchain layer adds an additional security dimension to the PKI, making it exponentially more secure against a range of cyber threats.
                                Users can engage in digital activities with confidence, knowing their transactions and communications are protected.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Greater Efficiency
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE’s blockchain-powered PKI streamlines the verification process, allowing for faster and more efficient digital interactions.
                                This efficiency does not come at the cost of security but rather enhances it, providing a seamless user experience without compromising on trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Future-Ready Infrastructure
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word', wordSpacing: '9px' }}>
                                As digital demands continue to grow and evolve, OVCODE’s infrastructure is designed to grow with them.
                                This <br/> blockchain-powered PKI is not just a solution for today’s security challenges but a future-ready foundation for the unknowns of tomorrow.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion: Trusting the Future with OVCODE
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE’s blockchain-powered PKI is more than an innovation; it is a testament to the company’s vision for a more secure and trustworthy digital world.
                                In this new paradigm, trust is not a by-product but the main product, embedded in every layer of communication and transaction.
                                With OVCODE, users and businesses can navigate the digital landscape with confidence, knowing they are supported by the most advanced trust infrastructure available today.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                    // onClick={() => window.location.href = "/blog-eid-identity-verification"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Power of AATL  Certification in OVCODE's Trust Infrastructure
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        OVCODE harnesses the power of technologies certified by the Adobe Approved Trust List (AATL) through its partnership with Entrust, ensuring the highest level of security and trust in its infrastructure.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                April 01, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-end-to-end-encryption"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE’s End-to-End Encryption: Securing Data with AATL-Certified Practices 
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Secure every piece of data from start to finish with OVCODE's end-to-end encryption, underpinned by practices certified by AATL members.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 01, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PoweredKPIComponent
import { Grid, Typography } from "@mui/material";
import ovcodeLogo from "../../icons/1.png";
import exploreOvcodeOne from "../../images/explore-ovcode/one.png";
import useWidth from "../../hooks/useWidth";
import aboutOvcode from "../../images/about-ovcode.png";
import aboutOvcodeLg from "../../images/about-ovcode-lg.png";

const ExploreOVCODEComponent = () => {

    const breakpoints = useWidth();

    return (
        <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: '0px !important' }}  className="explore-ovcode-container">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{
                    color: "#000",
                    fontSize: 22,
                    margin: 0,
                    lineHeight: 1.6,
                    fontWeight: "bold",
                }}>
                    Welcome to OVCODE: Your Reliable Partner in Digital Trust Solutions
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mt: 5 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={5.5}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <h2 class="h3" style={{ textAlign: 'start'}} >
                                    About Us
                                </h2>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    width: "100%"
                                }}>
                                    At OVCODE, we're at the forefront of the digital trust revolution.
                                    Our mission is to empower organizations and individuals with
                                    cutting-edge technologies that enhance security, transparency,
                                    and trust in the digital world. With our suite of innovative
                                    solutions, we're reshaping the way you verify, authenticate,
                                    and secure your digital assets.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1.5 }}>
                                <img alt="" src={breakpoints === 'lg' ? aboutOvcodeLg : aboutOvcode}
                                    style={{ width: breakpoints === 'xs' ? "100%" : "100%", height: breakpoints === 'xs' ? "100%" : breakpoints === 'lg' ? '350px' : '250px' ,  objectFit: "cover" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0.5}></Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2 class="h3" style={{ textAlign: 'start'}}>
                                    What is OVCODE ?
                                </h2>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                }}>
                                    Backed by its award-winning patented technology, incorporating electronic identity,
                                    blockchain, permanent storage, and artificial intelligence,
                                    OVCODE ensures utmost integrity and reliability throughout the authentication and verification process.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <h2 class="h3" style={{ textAlign: 'start'}} >
                                    Why OVCODE?
                                </h2>

                                <br />

                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    mt: 1
                                }}>
                                    <ul>
                                        <li style={{ marginBottom: 1, marginTop: -30 }}>
                                            <span style={{ fontWeight: "bold", color: "#000" }}>
                                                Security at the Core:
                                            </span>
                                            &nbsp;OVCODE leverages blockchain and advanced encryption to safeguard your
                                            digital documents, identity, and transactions.
                                        </li>

                                        <li style={{ marginBottom: 1 }}>
                                            <span style={{ fontWeight: "bold", color: "#000" }}>
                                                Transparency and Trust:
                                            </span>
                                            &nbsp;Blockchain-based solutions provide immutable records,
                                            ensuring the authenticity of your digital assets. Say goodbye to
                                            doubts and uncertainties.
                                        </li>

                                        <li style={{ marginBottom: 1 }}>
                                            <span style={{ fontWeight: "bold", color: "#000" }}>
                                                User-Friendly Experience:
                                            </span>
                                            &nbsp;Intuitive interfaces and tools make it easy for anyone, regardless of technical expertise, to utilize our solutions seamlessly.
                                        </li>

                                        <li style={{ marginBottom: 1 }}>
                                            <span style={{ fontWeight: "bold", color: "#000" }}>
                                                Versatile Solutions:
                                            </span>
                                            &nbsp;From document authentication to identity verification for individuals, businesses, or government agencies; we've got you covered.
                                        </li>

                                        <li>
                                            <span style={{ fontWeight: "bold", color: "#000" }}>
                                                Global Reach:
                                            </span>
                                            &nbsp;We serve clients worldwide, offering our expertise
                                            in borderless digital trust solutions.
                                        </li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ExploreOVCODEComponent
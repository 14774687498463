import React, {useEffect, useState} from "react";
import ReactJson from "react-json-view";
import {Box} from "@mui/material";

const JSONViewerComponent = () => {

    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem("jsonData");

        if (storedData) {
            setJsonData(JSON.parse(storedData));
        }
    }, []);

    return (
        <Box sx={{mt: 3}}>
            {jsonData && 
                <ReactJson src={jsonData} theme="summerfruit:inverted" iconStyle="triangle" displayDataTypes={false}
                    enableClipboard={false} displayObjectSize={false} name={false} collapsed={false} indentWidth={8}
                />
            }

            {/* simple json file renderer using pre tags */}
            {/* <pre>{JSON.stringify(jsonData, null, 6)}</pre> */}
        </Box>
    )
}

export default JSONViewerComponent
import './App.css';
import MainScreen from "./components/ovcode-website-v2/layout/main-layout";
import { BrowserRouter } from 'react-router-dom';
import Routes from './router';
import { history } from "./router/history";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createMuiTheme } from '@mui/material';
import ClippedDrawer from './screens-v2/VersionHistoriesScreen';
import useWidth from './hooks/useWidth';
import BackToTopButton from './components/layout/scroll-up.component';
import Chatbot from './components/layout/chatbot.component';
import { useState } from 'react';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Segoe UI",
      "SegoeUI",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(','),
  }
});

function App() {

  const url = window.location.pathname;
  const breakpoints = useWidth();

  const [isChatbotOpen, setIsChatbotOpen] = useState(false);



  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter history={history}>
            {(url !== "/version-histories-all" &&
              url !== "/version-histories-ovcode-enterprise" &&
              url !== "/version-histories-electronic-identification-admin" &&
              url !== "/version-histories-electronic-identification-android" &&
              url !== "/version-histories-electronic-identification-app-binding" &&
              url !== "/version-histories-electronic-identification-web-app" &&
              url !== "/version-histories-electronic-identification-regClient" &&
              url !== "/version-histories-national-identification-admin") ?
              <MainScreen>
                <Routes />
              </MainScreen>
              :
              <MainScreen>
                {breakpoints === 'xs' || breakpoints === 'sm' ?
                  null
                  :
                  <ClippedDrawer />
                }
                <Routes />
              </MainScreen>
            }
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
      <div class="container">
        {isChatbotOpen ?
          null
        :
          <BackToTopButton isChatbotOpen={isChatbotOpen} />
        }
        {url.includes("/v/")  ?
          null
        :
          <Chatbot setIsChatbotOpen={setIsChatbotOpen} />
        }
      </div>

    </div>
  )
}

export default App

import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../../../hooks/useWidth"
import DrawerHeader from "../../layout/drawer-header";
import ElectronicMarkBannerComponent from "./electronic-mark-banner.component";
import AboutElectronicMarkComponent from "./about-electronic-mark.component";
import BackToTopButton from "../../../layout/scroll-up.component";
import Chatbot from "../../../layout/chatbot.component";
import { useState } from "react";


const ElectronicMarkComponent = () => {

    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);


    return (
        <Box>
            {/* <div class="container">
                { isChatbotOpen ?
                    null
                :
                    <BackToTopButton isChatbotOpen={isChatbotOpen} />
                }
                <Chatbot setIsChatbotOpen={setIsChatbotOpen} />
            </div> */}
            
            <ElectronicMarkBannerComponent />

            <Box sx={{backgroundColor: '#E2E2E6', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <AboutElectronicMarkComponent />
            </Box>
           
        </Box>
    )
}

export default ElectronicMarkComponent;

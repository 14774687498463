import React from 'react';
import { Box, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import ComingSoonIcon from '../../../icons/coming-soon.png';

const ComingSoonPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box textAlign="center">
        {/* <AccessTime style={{width: 100}} color="primary" /> */}
        <img src={ComingSoonIcon} style={{width: '30%'}} />
        <Typography variant="h2" color="textPrimary" gutterBottom>
          Content Coming Soon
        </Typography>
        <Typography variant="h5" color="textSecondary">
          We're working on creating amazing content for you. Stay tuned!
        </Typography>
      </Box>
    </Box>
  );
};

export default ComingSoonPage;

import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';



const ElectronicSignatureReleaseNotes = () => {

    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card sx={{
            borderRadius: '4px',
            marginBottom: '10px',
            transition: '.5s',
            boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
            '&:hover': {
                boxShadow: '0 0 12px 2px rgba(0,0,0,0.16)',
            },
        }}>
            <div style={{ padding: '40px' }}>
                <header style={{ textAlign: 'start' }}>
                    <Typography variant="h5" component="div"  sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}>
                        eSign Beta Version 
                    </Typography>
                    <div style={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#999'
                    }}>
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{background: '#00800061'}} />
                            </ListItem>
                        </List>
                        <Typography variant="body2" component="span" className="posted-time">
                            <time dateTime="2020-04-23">August 25, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>

                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>SignIn using NID accounts</Typography>}
                                    secondary="eSign can now be access using registered NID accounts for one registration process. This will
                                    allow users to seamlessly and securely log in to their accounts using their National ID (NID) credentials, 
                                    streamlining the authentication process while maintaining a high level of security."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Dashboard</Typography>}
                                    secondary="Comprehensive overview of Document activities, document metrics per status, all in one 
                                    centralized location."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Profile Management</Typography>}
                                    secondary="Users can have more control over their information, logo, personal signature ensuring that 
                                    their online presence accurately represents their persona."
                                />
                            </ListItem>
                            {showFullDetails && (
                                <>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Sign-in Logs</Typography>}
                                            secondary="Keep track of all sign-in activities for enhanced security and monitoring."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Documents</Typography>}
                                            secondary="Can submit PDF documents to PDF editor for document signing process "
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>PDF Editor</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Drop and Drag Elements"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Signature</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Text field</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Date</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>QR Code</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Assign Signee</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Signee Management"
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <List>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Edit Signee</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Delete Signee</ListItemText>
                                                                            </ListItem>
                                                                            <ListItem>
                                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Drag and Drop signee based on order</ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Files</Typography>}
                                            secondary="Empowers user to effortlessly organize and navigate through documents by providing distinct 
                                            filters for different statuses, such as Pending, Draft, and Completed."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Analytics</Typography>}
                                            secondary="A comprehensive understanding of document management workflows by visualizing document 
                                            statuses – All, Draft, Pending, and Completed – while also allowing you to filter data by year"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Reports</Typography>}
                                            secondary="Generate comprehensive reports on your documents while gaining insights through year-based, 
                                            monthly-based and status-based filters."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>File Converter</Typography>}
                                            secondary="Upload various file types, including images and documents, and effortlessly convert them into 
                                            PDF files for document signatory process."
                                        />
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    )
}

export default ElectronicSignatureReleaseNotes
import KOREA from '../../../images/patents/patent-korea.jpg';
import PHILIPPINES from '../../../images/patents/patent-philippines.jpg';
import WIPRO from '../../../images/patents/patent-wipro.jpg';


const patents_images = [

    {
        name: 'korea',
        icon: KOREA,
        icon_hover: KOREA,
    },
    {
        name: 'philippines',
        icon: PHILIPPINES,
        icon_hover: PHILIPPINES,
    },
    {
        name: 'wipro',
        icon: WIPRO,
        icon_hover: WIPRO,
    },
]

export default patents_images
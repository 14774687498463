import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const account_access = [
    {
        name: "How can I assist users with forgotten passwords or locked accounts?",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    For users with forgotten passwords or locked accounts, you can guide them through the password reset process or unlock their account as per our protocols.
                </Typography>
            </Box>
    },
    {
        name: "How do I address issues related to account access or authentication? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    If users are having trouble accessing their accounts or encountering issues during authentication, you can troubleshoot the problem by following the relevant procedures.
                </Typography>
            </Box>
    }

]

export default account_access


import NEXTIX from '../../../images/customer/partners-nextix.jpg';
import FIRSTAIDE from '../../../images/customer/firstaide.jpg';
import MURAD from '../../../images/customer/murad.png';
import BENDECIO from '../../../images/customer/bendecio.png';
import SHE from '../../../images/customer/she.png';


const customer_stories_images = [
    {
        name : "E. Apitana, HR Manager, NEXTIX",
        icon : NEXTIX,
        icon_hover : NEXTIX,
        description : "OVCODE has helped us securely streamline our HR processes. Employees can easily apply for leaves and route approvals conveniently. We also use OVCODE to authenticate employee payslips. "
    },
    {
        name : "K. Go, FirstAide",
        icon : FIRSTAIDE,
        icon_hover : FIRSTAIDE,
        description : "With OVCODE, our RT-PCR results are tamper-proof. OVCODE authenticates test results and convert them to verifiable documents. OVCODE password protects the test results for the privacy of our clients."
    },
    {
        name : "S. Murad , Trade Facilitation Consultant",
        icon : MURAD,
        icon_hover : MURAD,
        description : "As a project consultant, I find OVCODE's user-friendly interface and powerful features have significantly enhanced my productivity and efficiency together with the project team."
    },
    {
        name : "A. Bendicio, IT Manager ",
        icon : BENDECIO,
        icon_hover : BENDECIO,
        description : "I'm impressed by OVCODE's impact. During the free trial, it transformed my work approach and created cutting-edge digital solutions. OVCODE's user-friendly interface, advanced features, and seamless integration capabilities drive innovation and efficiency. Highly recommended for IT professionals and developers seeking exceptional outcomes."
    },
    {
        name : "A. Tomas, BSBA Major in Marketing Management",
        icon : SHE,
        icon_hover : SHE,
        description : "As a Marketing student pursuing a Bachelor of Science degree in Business Administration, OVCODE made document signing a breeze. It's like having a simple and convenient tool on my phone to sign and verify documents. OVCODE saves me time and helps me stay organized in my studies. I highly recommend it for hassle-free document signing."
    }
]

export default customer_stories_images
import React, { useState } from "react";
import { Box, Card, CardContent, Grid, Typography, Pagination, } from "@mui/material";
import { industryInsightData } from "./industry-insights-data.component";



const IndustryInsightsTopicsComponent = () => {
    const [page, setPage] = useState(1);

    const handleClick = (url) => {
        if (url.trim() === "") {
            alert("Content is on the way!");
        } else {
            window.location.href = url;
        }
    };

    const itemsPerPage = 3;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = industryInsightData?.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box>
            {paginatedData.map((item, index) => (
                <div className="container-blog" key={index}>
                    <Card
                        sx={{
                            cursor: "pointer",
                            boxShadow: "2px 2px 8px rgba(0,0,0,.1)",
                            border: "1px solid rgba(0,0,0,.1)",
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            padding: "10px",
                            width: "90%",
                            margin: "auto",
                        }}
                        onClick={() => handleClick(item.link)}
                    >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{ textAlign: "start" }}>
                                    <img src={item.image} style={{ width: "80%" }} alt="" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ textAlign: "start" }}>
                                            <Typography
                                                sx={{
                                                    color: "#017eff",
                                                    fontWeight: 550,
                                                    fontSize: "1.25rem",
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: "start", mt: 1 }}>
                                            <Typography sx={{ fontSize: "1rem" }}>
                                                {item.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 3, width: '90%', textAlign: 'justify' }}>
                                            <Typography>{item.description}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(industryInsightData?.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                />
            </Box>
        </Box>
    );
};

export default IndustryInsightsTopicsComponent;

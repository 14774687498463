import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const security_privacy = [
    {
        name: "Security - aside from Azure, what is secondary security? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Cloud flare & TLS Cert.
                </Typography>
            </Box>
    },
    {
        name: "Data privacy - who will handle it? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Rest assured, data privacy is a joint effort. We collaborate closely with you and your clients to ensure data protection. Our team will assist in formulating tailored privacy policies, clauses, and contracts that align with industry standards and regulations. Your data privacy is our priority.)
                </Typography>
            </Box>
    },
    {
        name: "What is encryption used to avoid data interception and hacks? What hash is used? ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    RSA encryption using azure key vault | RSA Algorithms (using Advanced Encryption Standard (AES) with RSA OAEP algorithm and SHA-256 hashing and Store the key in Azure Key Vault)
                </Typography>
            </Box>
    },
    {
        name: "Who will have a copy of the data? ",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Rest assured, data distribution follows a secure and controlled process, in accordance with our policy and contract commitments.)
                </Typography>
            </Box>
    },
    {
        name: "Does the distributor have access to all data, including personal info and signatures? ",
        panel: "Panel5",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Access to data is managed through Role-Based Access Control (RBAC) as defined in our policy framework. Distributors are granted specific roles with controlled data access. While operational data may be accessible, sensitive information like personal data and signatures are restricted based on the assigned roles, ensuring privacy and security.)
                </Typography>
            </Box>
    },
    {
        name: "How is my personal data handled by OVCODE? ",
        panel: "Panel6",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    We are committed to protecting your personal data. Our data handling practices are guided by strict policies and contracts that prioritize your data privacy.
                </Typography>
            </Box>
    },
    {
        name: "What will be the surety of clients that data will not be interconnected or accessible by others? ",
        panel: "Panel7",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    With the OTP and verified eID account and this will be policy and contract based (Client data is strictly segregated and inaccessible to others. Our system uses OTP and verifies eID accounts to control access. This practice is grounded in our policy and contract framework.)
                </Typography>
            </Box>
    },
    {
        name: "How does OVCODE ensure the security of my data?",
        panel: "Panel8",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVCODE employs top-tier security measures, including encryption, blockchain technology, and Azure Key Vault, to safeguard your data against unauthorized access and tampering.
                </Typography>
            </Box>
    },
    {
        name: "How will you make me feel secure using OVCODE products? ",
        panel: "Panel9",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    By delivering security and peace of mind:

                    imagine your data is like a precious treasure. We protect it with special locks – strong encryptions that only you and authorized people can open. We also use special technology (blockchain) to make sure nobody can change or fake anything. And just like having a trusted friend, we watch over your data all the time to stop any trouble. We promise to follow strict rules and promises in our agreements to keep your treasure safe.
                </Typography>
            </Box>
    },
    {
        name: "Downtime – Data redundancy, how long will it be? What will be the standard uptime?",
        panel: "Panel10",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Rest assured, we're committed to minimizing downtime and maximizing uptime so that you can enjoy our services without disruption. Our agreements and policies lay out the specifics to give you clarity and peace of mind)
                </Typography>
            </Box>
    },


]

export default security_privacy

import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import SecuringFuture from "../../../../../images/blog/technology.png";


const SecuringTheFutureComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Securing the Future: A Look into OVCODE's Vision
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Friday, 12 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={SecuringFuture} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the ever-evolving landscape of digital trust, where technology shapes the future, OVCODE
                                stands as a visionary force, charting a course towards a future where trust is not just a
                                concept but an assurance woven into the very fabric of our digital interactions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE's Foundational Beliefs</Typography>
                            <Typography sx={{ mt: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                At the core of OVCODE's vision lies a set of foundational beliefs that serve as beacons guiding
                                its trajectory into the future:
                            </Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1. Universal Access to Trust:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE envisions a world where every individual, business, and institution has universal
                                        access to trustworthy and secure digital transactions. Digital trust should not be a privilege;
                                        it should be a right.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Technological Inclusivity:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE believes in making advanced technologies, especially blockchain, accessible to all.
                                        The democratization of technology is not just a goal; it's a commitment to ensuring that the
                                        benefits of secure digital transactions reach every corner of the globe.

                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Continuous Innovation:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        In the dynamic world of technology, stagnation is not an option. OVCODE's vision includes a
                                        commitment to continuous innovation—pushing the boundaries of what's possible and
                                        staying ahead of emerging challenges.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Reshaping Industries Through Trust</Typography>
                            <Typography sx={{ mt: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                OVCODE's long-term vision extends beyond being a solution; it's a catalyst for transformative
                                change across industries:

                            </Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1. Finance:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        In the financial sector, OVCODE envisions a future where secure and transparent
                                        transactions are not just an expectation but a standard. It foresees a financial ecosystem
                                        where trust is embedded in every transaction, fostering financial inclusion and integrity.

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Healthcare:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE sees a healthcare landscape where patient data is not just secure but seamlessly
                                        integrated for better patient outcomes. The future involves a healthcare system where trust
                                        in digital records translates to improved diagnostics, treatment, and overall healthcare
                                        quality.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Supply Chain:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE's vision for supply chains is one where the entire journey of a product, from
                                        manufacturing to delivery, is traced with unassailable authenticity. This transparency
                                        ensures consumer confidence, ethical sourcing, and streamlined logistics.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>4. Governance and Identity:</Typography>
                                    <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE envisions a future where digital identities are not only secure but are the
                                        foundation of robust governance systems. The potential impact includes reduced fraud,
                                        increased civic participation, and efficient public services.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>A Commitment to Global Impact</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's vision is not confined by geographical boundaries. It's a commitment to making a
                                global impact, transforming digital transactions into a universally trusted experience. The
                                company understands that trust knows no borders, and its vision is aligned with creating a
                                secure digital future for everyone.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Embracing the Uncharted Future</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As OVCODE looks towards the future, it does so with a pioneering spirit—ready to
                                embrace the uncharted territories of technology, security, and trust. The vision isn't just
                                about securing the future; it's about leading the charge towards a future where digital
                                trust is not just a necessity but a seamless and integral part of our digital lives.
                            </Typography>
                            <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                In conclusion, OVCODE's vision for the future is not just a roadmap; it's a commitment to
                                reshaping industries, fostering inclusivity, and ensuring that the promise of digital trust
                                becomes a reality for all.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-in-news"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE in the News: Latest Updates and Media Coverage
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Compile and summarize recent news articles and media coverage featuring OVCODE to keep your audience informed.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Identity Verification: An In-Depth Look at OVCODE e
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}

export default SecuringTheFutureComponent
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const disruption_downtime = [
    {
        name: "What should I do if a user reports a service disruption? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    If a user reports a disruption in service, you can check our system status page for any ongoing issues Link. If the issue is not listed, you can document the user's report and escalate it to the appropriate team.
                </Typography>
            </Box>
    },
    {
        name: "How should I communicate about planned maintenance or downtime to users? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Planned maintenance or downtime information should be communicated to users through our official channels (channel information). You can direct users to our announcements or maintenance schedule.
                </Typography>
            </Box>
    }

]

export default disruption_downtime

import CITIT4I from '../../../images/awards/citit4i.jpg';
import MICROSOFTPARTNER from '../../../images/awards/microsoft-partner-awards.jpg';
import WEF from '../../../images/awards/partners-wef.jpg';


const awards_images = [
    {
        icon: CITIT4I,
        icon_hover: CITIT4I,
    },
    {
        icon: MICROSOFTPARTNER,
        icon_hover: MICROSOFTPARTNER,
    },
    // {
    //     icon: WEF,
    //     icon_hover: WEF,
    // }
]

export default awards_images
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const technical_issues = [
    {
        name: "How can I troubleshoot common technical issues? ",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    We have a comprehensive knowledge base that includes troubleshooting guides for common technical issues. You can access these guides Link.
                </Typography>
            </Box>
    },
    {
        name: "What should I do if I encounter a technical issue that isn't covered in the knowledge base? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    If you can't find a solution in the knowledge base, please contact our support team for personalized assistance.
                </Typography>
            </Box>
    },
    {
        name: "How do I report a bug or technical glitch? ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    If you come across a bug or technical glitch, please report it to our support team. Include a detailed description of the issue, steps to reproduce it, and any relevant error messages.
                </Typography>
            </Box>
    }


]

export default technical_issues

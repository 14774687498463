import React, {useEffect} from "react";
import { Box, Card, CardMedia, Grid, ListItemText, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import DrawerHeader from "../layout/drawer-header";
import LandingBannerComponent from "./landing-banner.component";
import productlogo1 from "../../icons/productlogo1.png";
import productlogo2 from "../../icons/productlogo2.png";
import productlogo3 from "../../icons/productlogo3.png";
import productlogo4 from "../../icons/productlogo4.png";
import landing_products_images from "./landing-products-images.component";


function LandingComponent() {

    const breakpoints = useWidth();

    useEffect(() => {
        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog");
    }, [])
    

    return (

        <Box >
            {/* <Box sx={{pt: 8, pb: 8}}>
                <LandingBannerComponent/>
            </Box> */}

            {/* <DrawerHeader /> */}

             <Box sx={{mb: 4}}>
                <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? '2rem' : '3.3333rem', margin: 0, lineHeight: 1.2, color: 'rgb(45, 55, 72)', fontWeight: 700}}>
                    Core Technologies
                </Typography>
            </Box>

            <Box>
                <Grid container spacing={3} sx={{alignItem: 'center', justifyContent: 'center'}}>
                    {landing_products_images.map((value)=>{
                        return(
                            <Grid item xs={12} sm={6} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5} sx={{alignItems: 'center',paddingLeft: 0, paddingRight: 0, mt: 5}}>
                                        <CardMedia
                                            component="img"
                                            image={value.icon}
                                            style={{objectFit: 'contain', margin:'auto', width: 100, height: 100}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{alignItems: 'center',paddingLeft: 0}}>
                                        <Typography sx={{textAlign: 'start',fontWeight: 700, mt: 1, pt: 1,pb: 2,fontSize: '1.1rem',lineHeight: 1.5}}>
                                            {value.name}
                                        </Typography>
                                        {(breakpoints == "xs" || breakpoints == "sm") ?
                                            <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', textAlign: 'justify', textJustify: 'inter-word',  color: 'rgb(100, 110, 115)'}}>
                                                {value.description}
                                            </Typography>
                                        :
                                            <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', textAlign: 'justify', textJustify: 'inter-word',  color: 'rgb(100, 110, 115)'}}>
                                                &nbsp;{value.description}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
             
        </Box>

    )
    
}

export default LandingComponent
import React, {useEffect, useState} from "react";
import {Box, CardMedia, Grid, Typography} from "@mui/material";
import DrawerHeader from "../../layout/drawer-header";
import VerifyBannerComponent from "./verify-banner.component";
import DataUploaderComponent from "./data-uploader.component";
import ScanQRComponent from "./scan-qr.component";
import VerifiedDataDialog from "../../dialog/verified-data-dialog.component";
import UnverifiedDataDialog from "../../dialog/unverified-data-dialog.component";
import useWidth from "../../../hooks/useWidth";
import uploadGIF from "../../../icons/upload-v1.gif";
import scanGIF from "../../../icons/scanner-v2.gif";

const VerifyComponent = () => {


    const breakpoints = useWidth();

    const [openUpload, setOpenUpload] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    
    const handleOpenScanComponent = () => {
        setOpenScan(true);
        setOpenUpload(false);
    }

    const handleOpenUploadDataComponent = () => {
        setOpenScan(false);
        setOpenUpload(true);
    }

    useEffect(() => {
        if (localStorage.getItem("isComingFromUpload")) {
            localStorage.removeItem("isComingFromUpload");
        
            // Use JavaScript to scroll to the upload-data-section
            const uploadDataSection = document.getElementById("upload-data-section");
            if (uploadDataSection) {
                uploadDataSection.scrollIntoView({ behavior: "smooth" });
            }

            handleOpenUploadDataComponent();
        }
    },[])

    return(
        <Box sx={{overflow : 'hidden'}}>

            <DrawerHeader />

            <VerifyBannerComponent />

            <div id="upload-data-section"></div>
            <Grid container spacing={2} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                <Grid item xs={6} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                    <Box>
                        <CardMedia
                            onClick={(e) => handleOpenScanComponent(e)}
                            component="img"
                            image={scanGIF}
                            style={{cursor: "pointer", objectFit: 'contain', margin:'auto', width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "60%"}}
                        />
                        <Box sx={{mb: 4}}>
                            <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}
                                // onClick={(e) => handleVerifyReference()}
                            >
                                SCAN CODE
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                    <Box onClick={(e) => handleOpenUploadDataComponent(e) }>
                        <CardMedia
                            component="img"
                            image={uploadGIF}
                            style={{cursor: "pointer", objectFit: 'contain', margin:'auto', width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "60%"}}
                        />
                        <Box sx={{mb: 4}}>
                            <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
                                UPLOAD DATA
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <br/>

            {openUpload && 
                <DataUploaderComponent breakpoints={breakpoints} setOpenUpload={setOpenUpload}  openUpload={openUpload}
                    setOpenVerifiedData={setOpenVerifiedData} setOpenUnverifiedData={setOpenUnverifiedData}
                />
            }

            {openScan && 
                <ScanQRComponent breakpoints={breakpoints} setOpenScan={setOpenScan} 
                    setOpenVerifiedData={setOpenVerifiedData} setOpenUnverifiedData={setOpenUnverifiedData}
                />
            }

            {openVerifiedData && 
                <VerifiedDataDialog breakpoints={breakpoints}
                    openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData} 
                />
            }

            {openUnverifiedData && 
                <UnverifiedDataDialog breakpoints={breakpoints}
                    openUnverifiedData={openUnverifiedData} setOpenUnverifiedData={setOpenUnverifiedData}
                />
            }
        </Box>
      
    )
    
}

export default VerifyComponent
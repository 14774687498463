import React, {useState} from "react";
import {Link, Box, Typography, Divider, Grid, TextField, Button, FormGroup, Checkbox, FormControlLabel, InputAdornment} from "@mui/material";
import useJoin from "../../hooks/useJoin";
import {ThreeDots} from "react-loader-spinner";
import SuccessConfirmationDialog from "../dialog/success-confirmation.dialog";

const FormComponent = (props) => {

    const {breakpoints} = props;

    const {isJoining, joinOVCode} = useJoin();

    const [companyName, setCompanyName] = useState("");
    const [inquiryDetails, setInquirDetails] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [website, setWebsite] = useState("");

    const [checked, setChecked] = useState(false);
    const [focusedName, setFocusedName] = useState(false);
    const [focusedDesc, setFocusedDesc] = useState(false);
    const [focusedEmail, setFocusedEmail] = useState(false);
    const [focusedFirst, setFocusedFirst] = useState(false);
    const [focusedLast, setFocusedLast] = useState(false);
    const [focusedNo, setFocusedNo] = useState(false);
    const [focusedwebsite, setFocusedwebsite] = useState(false);
    const [isFirstError, setFirstError] = useState(false);
    const [isLastError, setLastError] = useState(false);
    const [isNameError, setNameError] = useState(false);
    const [isDescError, setDescError] = useState(false);
    const [isEmailError, setEmailError] = useState(false);
    const [isWebsiteError, setWebsiteError] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);

    const [openSuccessConfirmation, setOpenSuccessConfirmation] = useState(false);

    const handleClick = (e, name) => {
        if (name == "firstName") {
            setFocusedName(false);
            setFocusedDesc(false);
            setFocusedEmail(false);
            setFocusedFirst(true);
            setFocusedLast(false);
            setFocusedNo(false);
            setFocusedwebsite(false);

        }else if (name == "lastName") {
            setFocusedName(false);
            setFocusedDesc(false);
            setFocusedEmail(false);
            setFocusedFirst(false);
            setFocusedLast(true);
            setFocusedNo(false);
            setFocusedwebsite(false);

        } else if (name == "companyName") {
            setFocusedName(true);
            setFocusedDesc(false);
            setFocusedEmail(false);
            setFocusedFirst(false);
            setFocusedLast(false);
            setFocusedNo(false);
            setFocusedwebsite(false);

        } else if (name == "inquiryDetails") {
            setFocusedDesc(true);
            setFocusedName(false);
            setFocusedEmail(false);
            setFocusedNo(false);
            setFocusedFirst(false);
            setFocusedLast(false);
            setFocusedwebsite(false);


        } else if (name == "email") {
            setFocusedEmail(true);
            setFocusedDesc(false);
            setFocusedName(false);
            setFocusedNo(false);
            setFocusedFirst(false);
            setFocusedLast(false);
            setFocusedwebsite(false);


        } else if (name == "contactNo") {
            setFocusedNo(true);
            setFocusedDesc(false);
            setFocusedName(false);
            setFocusedEmail(false);
            setFocusedFirst(false);
            setFocusedLast(false);
            setFocusedwebsite(false);


        }  else if (name == "website") {
            setFocusedwebsite(true);
            setFocusedNo(false);
            setFocusedDesc(false);
            setFocusedName(false);
            setFocusedEmail(false);
            setFocusedFirst(false);
            setFocusedLast(false);
        }
    }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleTextField = (e, name) => {
        const regex = /^[0-9\b]+$/;
    
        if (name == "firstName") {
            setFirstName(e.target.value);
            setFirstError(false);

        } else if (name == "lastName") {
            setLastName(e.target.value);
            setLastError(false);

        }else if (name == "companyName") {
            setCompanyName(e.target.value);
            setNameError(false);

        }else if (name == "inquiryDetails") {
            setInquirDetails(e.target.value);
            setDescError(false);

        } else if (name == "email") {
            let email = e.target.value;

            setEmail(email);
            setEmailError(false);

            if (email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }
           
        } else if (name == "contactNo") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setContactNo(e.target.value);
            }
        } else if (name == "website") {
            setWebsite(e.target.value);
            setWebsiteError(false);
        }
    }

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
        handleMouseOver();
    }

    const handleMouseOver = (e) => {
        setFocusedNo(false);
        setFocusedDesc(false);
        setFocusedName(false);
        setFocusedEmail(false);
        setFocusedFirst(false);
        setFocusedLast(false);
        setFocusedwebsite(false);
    }

    const handleSubmit = (e) => {
        handleMouseOver();

        if (!firstName) {
            setFirstError(true);
            setLastError(false);
            setNameError(false);
            setDescError(false);
            setEmailError(false);
            setWebsiteError(false);

        } else if (!lastName) {
            setFirstError(false);
            setLastError(true);
            setDescError(false);
            setNameError(false);
            setEmailError(false);
            setWebsiteError(false);

        }else if (!companyName) {
            setNameError(true);
            setDescError(false);
            setEmailError(false);
            setFirstError(false);
            setLastError(false);
            setWebsiteError(false);

        }else if (!inquiryDetails) {
            setDescError(true);
            setNameError(false);
            setEmailError(false);
            setFirstError(false);
            setLastError(false);
            setWebsiteError(false);

        } else if (!email) {
            setEmailError(true);
            setNameError(false);
            setDescError(false);
            setFirstError(false);
            setLastError(false);
            setWebsiteError(false);

        } else if (!website) {
            setWebsiteError(true);
            setEmailError(false);
            setNameError(false);
            setDescError(false);
            setFirstError(false);
            setLastError(false);

        }else {
            setNameError(false);
            setDescError(false);
            setEmailError(false);
            setFirstError(false);
            setLastError(false);
            setWebsiteError(false);


            joinOVCode(firstName, lastName, companyName, inquiryDetails, email, contactNo, website,  setOpenSuccessConfirmation);
        }
    }


    return (
        <>
            <Box sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "30px 40px 0px 40px" :
                "5px 100px 0px 100px" 
            }}>
                <Typography variant="h6" sx={{mb: "4px", fontWeight: "bold", textAlign: "start"}}>
                    JOIN NOW!
                </Typography>

                <Divider sx={{borderBottom: "1px solid #262626"}} />
        
                <Grid container direction="column" sx={{mt: "8px"}}>
                    <Grid item>
                        <Typography variant="subtitle2" sx={{fontSize: 16, mb: "10px", fontWeight: "bold", textAlign: "start"}}>
                            Business Information
                        </Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", mt: "3px"}}>
                        <Typography sx={{textAlign:'justify', textJustify: 'inter-word', fontSize: 14}}>
                            Thank you for your interest in <b style={{fontWeight: 700, textTransform: 'uppercase'}}>OVCODE</b>.To get started, please fill out the form below. Our team will get in touch and provide you with the best solution for your needs.
                        </Typography>
                    </Grid>

                    <Grid container spacing={(breakpoints == "xs" || breakpoints == "sm") ? 0 : 2}>
                        <Grid item xs={12} sm={6}>
                            <TextField onClick={(e) => handleClick(e, "firstName")}
                                variant="standard" 
                                margin="normal"
                                fullWidth
                                style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                                InputProps={{disableUnderline: true}} 
                                inputProps={{maxLength: 100, style: {fontSize: 14}}}
                                placeholder={(isFirstError) ? "" : "First Name"}
                                value={firstName}
                                onChange={(e) => handleTextField(e, "firstName")}
                                sx={{
                                    borderRadius: 1,
                                    background: (focusedFirst || isFirstError) ? {} : "rgb(0 0 0 / 6%)",
                                    border: (focusedFirst) ? "1.5px solid #1976d2" : (isFirstError) ? "1.5px solid rgb(191, 90, 76)" : {}
                                }}
                                helperText={
                                    <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                        {(isFirstError) && "First Name is required"}
                                    </p>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onClick={(e) => handleClick(e, "lastName")}
                                variant="standard" 
                                margin="normal"
                                fullWidth
                                style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                                InputProps={{disableUnderline: true}} 
                                inputProps={{maxLength: 100, style: {fontSize: 14}}}
                                placeholder={(isLastError) ? "" : "Last Name"}
                                value={lastName}
                                onChange={(e) => handleTextField(e, "lastName")}
                                sx={{
                                    borderRadius: 1,
                                    background: (focusedLast || isLastError) ? {} : "rgb(0 0 0 / 6%)",
                                    border: (focusedLast) ? "1.5px solid #1976d2" : (isLastError) ? "1.5px solid rgb(191, 90, 76)" : {}
                                }}
                                helperText={
                                    <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                        {(isLastError) && "Last Name is required"}
                                    </p>
                                }
                            />
                        </Grid>
                    </Grid>

                    <TextField onClick={(e) => handleClick(e, "email")}
                        variant="standard" 
                        margin="normal"
                        fullWidth
                        style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                        InputProps={{disableUnderline: true}} 
                        inputProps={{maxLength: 100, style: {fontSize: 14}}}
                        placeholder={(isEmailError) ? "" : "Company Email"}
                        value={email}
                        onChange={(e) => handleTextField(e, "email")}
                        sx={{
                            borderRadius: 1,
                            background: (focusedEmail || isEmailError || !isValidEmail) ? {} : "rgb(0 0 0 / 6%)",
                            border: (focusedEmail) ? "1.5px solid #1976d2" : (isEmailError || !isValidEmail) ? "1.5px solid rgb(191, 90, 76)" : {}
                        }}
                        helperText={
                            <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                {(isEmailError) ? "Company Email is required" : (!isValidEmail) ? "Company Email is invalid" : ""}
                            </p>
                        }
                    />
    
                    <TextField onClick={(e) => handleClick(e, "companyName")}
                        variant="standard" 
                        margin="normal"
                        fullWidth
                        style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                        InputProps={{disableUnderline: true}} 
                        inputProps={{maxLength: 100, style: {fontSize: 14}}}
                        placeholder={(isNameError) ? "" : "Company Name"}
                        value={companyName}
                        onChange={(e) => handleTextField(e, "companyName")}
                        sx={{
                            borderRadius: 1,
                            background: (focusedName || isNameError) ? {} : "rgb(0 0 0 / 6%)",
                            border: (focusedName) ? "1.5px solid #1976d2" : (isNameError) ? "1.5px solid rgb(191, 90, 76)" : {}
                        }}
                        helperText={
                            <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                {(isNameError) && "Company Name is required"}
                            </p>
                        }
                    />

                    <Grid container spacing={(breakpoints == "xs" || breakpoints == "sm") ? 0 : 2}>
                        <Grid item xs={12} sm={6}>
                            <TextField onClick={(e) => handleClick(e, "contactNo")}
                                variant="standard" 
                                margin="normal"
                                fullWidth
                                style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                                InputProps={{disableUnderline: true}} 
                                inputProps={{
                                    maxLength: 100,
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    style: {fontSize: 14}
                                }}
                                placeholder="Contact Number (optional)"
                                value={contactNo}
                                onChange={(e) => handleTextField(e, "contactNo")}
                                sx={{
                                    borderRadius: 1,
                                    background: (focusedNo) ? {} : "rgb(0 0 0 / 6%)",
                                    border: (focusedNo) ? "1.5px solid #1976d2" : {}
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onClick={(e) => handleClick(e, "website")}
                                variant="standard" 
                                margin="normal"
                                fullWidth
                                style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                                InputProps={{disableUnderline: true}} 
                                inputProps={{
                                    style: {fontSize: 14}
                                }}
                                placeholder="Website"
                                value={website}
                                onChange={(e) => handleTextField(e, "website")}
                                sx={{
                                    borderRadius: 1,
                                    background: (focusedwebsite || isWebsiteError) ? {} : "rgb(0 0 0 / 6%)",
                                    border: (focusedwebsite) ? "1.5px solid #1976d2" : (isWebsiteError) ? "1.5px solid rgb(191, 90, 76)" : {}
                                }}
                                helperText={
                                    <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                        {(isWebsiteError) && "Website is required"}
                                    </p>
                                }
                            />
                        </Grid>
                    </Grid>
                    
                    <TextField onClick={(e) => handleClick(e, "inquiryDetails")}
                        variant="standard" 
                        margin="normal"
                        fullWidth
                        style={{marginBottom: 9, padding: "6px 8px 8px 12px", height: 40}}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <p style={{ fontSize: 11, margin: 0 }}>
                                  {inquiryDetails.length}/400
                                </p>
                              </InputAdornment>
                            ),
                          }}
                        inputProps={{
                            maxLength: 400, // Set the maximum length to 400
                            style: { fontSize: 14 },
                          }}
                        placeholder={(isDescError) ? "" : "Inquiry Details"}
                        value={inquiryDetails}
                        onChange={(e) => handleTextField(e, "inquiryDetails")}
                        sx={{
                            borderRadius: 1,
                            background: (focusedDesc || isDescError) ? {} : "rgb(0 0 0 / 6%)",
                            border: (focusedDesc) ? "1.5px solid #1976d2" : (isDescError) ? "1.5px solid rgb(191, 90, 76)" : {}
                        }}
                        helperText={
                            <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -7px"}}>
                                {(isDescError) && "Inquiry Details is required"}
                            </p>
                        }
                    />



                    <FormGroup sx={{ml: 0.2}}>
                        <FormControlLabel 
                            control={
                                <Checkbox size="small" checked={checked} onChange={(e) => handleCheckboxChange(e)} />
                            }
                            label={
                                <Typography sx={{fontSize: 14, mt: (breakpoints == "xs" || breakpoints == "sm") ? "10px" : {}}}>
                                    I have read and agree to the <Link href="/terms-of-service" underline="none" target="_blank" style={{fontSize: 14}}>terms</Link> and <Link 
                                        href= "/privacy-policy" underline="none" target="_blank"
                                        style={{fontSize: 14}}
                                    >
                                        privacy policy.
                                    </Link>
                                </Typography>
                            } 
                        />
                    </FormGroup>

                    <Button disableElevation variant="contained" 
                        sx={{
                            mt: "20px", 
                            cursor: (!checked) ? "default": "pointer", 
                            width: (breakpoints == "xs" || breakpoints == "sm") ? "40%" : "30%", ml: "auto", textTransform: "none", 
                            background: (!checked) ? "rgb(240 240 240)" : "rgb(1, 120, 242)", color: (!checked) ? "rgba(0, 0, 0, 0.38)" : "#fff",
                            "&:hover": {background: (!checked) ? "rgba(0, 0, 0, 0.04)" : "rgb(1, 120, 242)", color: (!checked) ? "rgba(0, 0, 0, 0.38)" : "#fff"}
                        }}
                        startIcon={isJoining && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                        onClick={(e) => (!checked) ? handleMouseOver(e) : handleSubmit(e)}
                    >
                        {(isJoining) ? "Sending" : "Send"}
                    </Button>
                </Grid>
            </Box>

            {openSuccessConfirmation &&
                <SuccessConfirmationDialog breakpoints={breakpoints} 
                    openSuccessConfirmation={openSuccessConfirmation} setOpenSuccessConfirmation={setOpenSuccessConfirmation}
                />
            }
        </>
    )
}

export default FormComponent
import ovcodeImageOne from '../../../../images/cybergems/one.jpg';
import ovcodeImageTwo from '../../../../images/cybergems/two.jpg';


const cybergemsData = [
    {
        title: "OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow",
        date: "May 02, 2024",
        description: "Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology. This blog post delves into how OVCODE's robust blockchain framework ensures the authenticity and permanence of Cybergems, the world's first digital gems. By embedding personal memories into these blockchain-secured gems, OVCODE not only enhances their value and uniqueness but also sets a new benchmark for integrity and trust in digital assets.",
        image: ovcodeImageOne,
        link: "/blog-cybergems-authenticating-digital-treasure"
    },
    {
        title: "Cybergems Unveiled - The Dawn of Digital Heirlooms",
        date: "May 02, 2024",
        description: "Explore how OVCODE's blockchain authentication is integral to the launch of Cybergems, marking the beginning of a new chapter in digital legacy creation.",
        image: ovcodeImageTwo,
        link: "/blog-cybergems-unveiled"
    },
    {
        title: "The Art of Authenticity - OVCODE's Blockchain Verifying Cybergems",
        date: "May 06, 2024",
        description: "Dive into the details of how OVCODE’s blockchain technology ensures the authenticity and uniqueness of each Cybergem, setting new standards in the NFT marketplace.",
        image: ovcodeImageTwo,
        link: "/blog-cybergems-art-of-authenticity"
    },
    {
        title: "Behind Every Gem - The Technology That Secures Memories",
        date: "May 06, 2024",
        description: "Discuss the technical underpinnings of OVCODE's blockchain that make it possible for Cybergems to securely embed and immortalize personal memories.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "The Future of Collecting - Blockchain-Certified Cybergems",
        date: "May 06, 2024",
        description: "Envision the future of collecting through the lens of OVCODE-certified Cybergems and how this transforms the way we think about ownership and value in the digital age.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "Crafting Trust in the Blockchain - OVCODE's Role in NFT Integrity",
        date: "May 06, 2024",
        description: "Delve into the concept of trust in the blockchain ecosystem and how OVCODE’s robust authentication protocols are critical for maintaining the integrity of NFTs like Cybergems.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "Cybergems: An Investment in the Future with OVCODE's Blockchain",
        date: "May 06, 2024",
        description: "Analyze the investment potential of Cybergems within the blockchain landscape, backed by OVCODE's secure technology, and discuss the factors that make them a sound investment.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "A Legacy in Pixels - OVCODE's Commitment to Secure Digital Legacies",
        date: "May 06, 2024",
        description: "Reflect on the importance of digital legacy and how OVCODE’s technology enables users to preserve their digital footprints for future generations with Cybergems.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "The Evolution of Digital Collectibles with OVCODE and Cybergems",
        date: "May 06, 2024",
        description: "Take a look at how the collaboration between OVCODE and Cybergems is pushing the boundaries of digital collectibles and leading an industry evolution.",
        image: ovcodeImageTwo,
        link: ""
    },

    {
        title: "Intersecting Paths - When Blockchain Meets Personal Memories",
        date: "May 06, 2024",
        description: "Examine the intersection of blockchain technology and personal memorabilia, and how OVCODE's secure platform makes Cybergems a reality.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "Embracing a New Era of NFTs with OVCODE's Trusted Technology",
        date: "May 06, 2024",
        description: "Discuss the broader implications of OVCODE’s technology in shaping the future of NFTs and digital assets, highlighting the success of Cybergems as a case study.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "The OVCODE Trust Network - Elevating Cybergems to Global Standards",
        date: "May 06, 2024",
        description: "Outline how OVCODE's global trust network plays a role in elevating Cybergems to meet and exceed global standards of digital authentication and trust.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "From Blockchain to Heirlooms - OVCODE's Journey with Cybergems",
        date: "May 06, 2024",
        description: "Share the story of OVCODE's journey in the blockchain space and how its partnership with Cybergems is a milestone in its mission to secure the digital future.",
        image: ovcodeImageTwo,
        link: ""
    },
    {
        title: "Securing Digital Heirlooms: How OVCODE Protects Your Cybergems",
        date: "May 06, 2024",
        description: "Delve into the technological intricacies of how OVCODE's blockchain solutions safeguard your Cybergems, transforming personal memories into secure, immutable digital heirlooms.",
        image: ovcodeImageTwo,
        link: ""
    },
];

export { cybergemsData }
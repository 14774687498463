import React from 'react';
import { Card, CardContent, Typography, Link, List, ListItem, ListItemText, Chip, Box, Container, ListItemIcon } from '@mui/material';
import BackToTopButton from '../components/layout/scroll-up.component';
import ElectronicIdentificationAndroidAppReleaseNotes from '../components/ovcode-website-v2/enterprise/eid-android-app-release-notes.component';
import VersionHistoriesComponent from '../components/ovcode-website-v2/enterprise/version-histories.component';
import OvcodeReleaseNotes from '../components/ovcode-website-v2/enterprise/ovcode-release-notes.component';
import ElectronicIdentificationReleaseNotes from '../components/ovcode-website-v2/enterprise/electronic-identification-release-notes.component';
import ElectronicIdentificationAppBindingAndRegistrationReleaseNotes from '../components/ovcode-website-v2/enterprise/eid-app-binding-and-registration-release-notes.component';
import ElectronicIdentificationWebAppReleaseNotes from '../components/ovcode-website-v2/enterprise/eid-web-app-release-notes.component';
import ElectronicIdentificationRegClientReleaseNotes from '../components/ovcode-website-v2/enterprise/eid-reg-client-release-notes.component';
import NationalIdentificationAdminWebPortalReleaseNotes from '../components/ovcode-website-v2/enterprise/nid-admin-portal-release-notes.component';
import ElectronicSignatureReleaseNotes from '../components/ovcode-website-v2/enterprise/electronic-signature-release-notes.component';
import OvcodeReleaseNotesV2 from '../components/ovcode-website-v2/enterprise/ovcode-release-notes-v2.component';
import ElectronicIdentificationAndroidAppReleaseNotesv2 from '../components/ovcode-website-v2/enterprise/eid-android-app-release-notes-v2.component';
import ElectronicIdentificationWebAppReleaseNotesv2 from '../components/ovcode-website-v2/enterprise/eid-web-app-release-notes-v2.component';
import ElectronicSignatureReleaseNotesv2 from '../components/ovcode-website-v2/enterprise/esign-release-notes-v2.component';
import useWidth from '../hooks/useWidth';



const VersionHistoriesAllScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box>

            <div class="container">
                <BackToTopButton />
            </div>

            <Box sx={{
                overflow: 'hidden',
                margin: 'auto 0',
                width: ' 100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Container maxWidth="md">
                   
                    <Box sx={{width: '100%', margin: 'auto', marginLeft: breakpoints === 'xs' || breakpoints === 'sm' ? '0px' : '230px'}}>
                        <Box sx={{width: breakpoints === 'xs' || breakpoints === 'sm' ? '100%' : '80%'}}>
                       
                            <Box sx={{ mb: 4 }}>
                                <Typography sx={{ fontSize: '40px', margin: 0, color: '#000000CC', fontWeight: 600, textTransform: 'none' }}>
                                    Official Release Notes
                                </Typography>
                            </Box>
                            <br/>
                            <OvcodeReleaseNotes />
                            <br />
                            <OvcodeReleaseNotesV2 />
                            <br/>
                            <ElectronicIdentificationReleaseNotes />
                            <br />
                            <ElectronicIdentificationAppBindingAndRegistrationReleaseNotes />
                            <br />
                            <ElectronicIdentificationAndroidAppReleaseNotes />
                            <br />
                            <ElectronicIdentificationAndroidAppReleaseNotesv2 />
                            <br/>
                            <ElectronicIdentificationWebAppReleaseNotes />
                            <br/>
                            <ElectronicIdentificationWebAppReleaseNotesv2 />
                            <br/>
                            <ElectronicIdentificationRegClientReleaseNotes />
                            <br/>
                            <NationalIdentificationAdminWebPortalReleaseNotes />
                            <br/>
                            <ElectronicSignatureReleaseNotes />
                            <br/>
                            <ElectronicSignatureReleaseNotesv2 />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>

    );
}

export default VersionHistoriesAllScreen;

import ovcodeImageOne from "../../../../images/industry-insights/industry-1.png";
import ovcodeImageTwo from "../../../../images/industry-insights/industry-2.png";
import ovcodeImageThree from "../../../../images/industry-insights/industry-3.png";
import ovcodeImageFour from "../../../../images/industry-insights/industry-4.png";
import ovcodeImageFive from "../../../../images/industry-insights/industry-5.png";
import ovcodeImageSix from "../../../../images/industry-insights/industry-6.jpg";
import ovcodeImageSeven from "../../../../images/industry-insights/industry-7.png";
import ovcodeImageEight from "../../../../images/industry-insights/industry-8.png";
import ovcodeImageNine from "../../../../images/industry-insights/industry-9.png";
import ovcodeImageTen from "../../../../images/industry-insights/industry-10.png";
import ovcodeImageEleven from "../../../../images/industry-insights/industry-11.png";
import ovcodeImageTwelve from "../../../../images/industry-insights/industry-12.png";
import ovcodeCGImageOne from '../../../../images/cybergems/one.jpg';
import ovcodeCGImageTwo from '../../../../images/cybergems/two.jpg';
import ovcodeCGImageThree from '../../../../images/cybergems/three.jpg';
import ovcodeCGImageFour from '../../../../images/cybergems/four.jpg';
import ovcodeCGImageFive from '../../../../images/cybergems/five.png';
import ovcodeCGImageSix from '../../../../images/cybergems/six.jpg';
import ovcodeCGImageSeven from '../../../../images/cybergems/seven.jpg';
import ovcodeCGImageEight from '../../../../images/cybergems/eight.jpg';
import ovcodeCGImageNine from '../../../../images/cybergems/nine.jpg';
import ovcodeCGImageTen from '../../../../images/cybergems/ten.jpg';
import ovcodeCGImageEleven from '../../../../images/cybergems/eleven.jpg';


    const industryInsightData = [
        {
            image: ovcodeImageOne,
            title: "Blockchain and Digital Trust Trends: What You Need to Know",
            date: "October 09, 2023",
            description: "Explore the latest trends in blockchain technology and their implications for digital trust.",
            link: "/blog-industry-what-to-know",
        },
        {
            title: "The Art of Authenticity - OVCODE's Blockchain Verifying Cybergems",
            date: "May 06, 2024",
            description: "Dive into the details of how OVCODE’s blockchain technology ensures the authenticity and uniqueness of each Cybergem, setting new standards in the NFT marketplace.",
            image: ovcodeCGImageThree,
            link: "/blog-cybergems-art-of-authenticity"
        },
        {
            image: ovcodeImageTwo,
            title: "The Role of OVCODE in Securing the Supply Chain",
            date: "October 09, 2023",
            description: "Discuss how OVCODE contributes to supply chain security and authenticity.",
            link: "/blog-industry-ovcode-role",
        },
        {
            title: "OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow",
            date: "May 02, 2024",
            description: "Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology. This blog post delves into how OVCODE's robust blockchain framework ensures the authenticity and permanence of Cybergems, the world's first digital gems. By embedding personal memories into these blockchain-secured gems, OVCODE not only enhances their value and uniqueness but also sets a new benchmark for integrity and trust in digital assets.",
            image: ovcodeCGImageOne,
            link: "/blog-cybergems-authenticating-digital-treasure"
        },
        {
            image: ovcodeImageThree,
            title: "Enhancing User Experience with OVCODE: How Seamless Integration Builds Trust",
            date: "April 02, 2024",
            description: "Discover how OVCODE revolutionizes user experience by offering seamless integration of its robust digital trust solutions. Learn how the platform's user-centric design not only simplifies verification processes but also reinforces user confidence. By merging advanced PKI with the immutability of blockchain technology, OVCODE ensures that each transaction is secure, transparent, and efficient. This integration is key to building and sustaining trust in digital interactions, making OVCODE an indispensable ally in the pursuit of uncompromising online security and convenience.",
            link: "/blog-industry-user-experience",
        },
        {
            title: "Behind Every Gem - The Technology That Secures Memories",
            date: "May 06, 2024",
            description: "Discuss the technical underpinnings of OVCODE's blockchain that make it possible for Cybergems to securely embed and immortalize personal memories.",
            image: ovcodeCGImageFour,
            link: "/blog-cybergems-secures-memories"
        },
        // {
        //     image: ovcodeImageFour,
        //     title: "Ensuring Medication Safety and Authenticity: OVCODE's Blockchain Solution for Pharmaceuticals and Consumers",
        //     date: "April 02, 2024",
        //     description: "OVCODE’s blockchain technology marks a revolution in pharmaceutical safety, providing an immutable ledger for tracking medications from production to consumption. This system guarantees the authenticity of drugs, preventing counterfeit products from reaching consumers and enhancing patient trust in healthcare providers and pharmaceutical companies.",
        //     link: "/blog-industry-safety-and-authenticity",
        // },
        {
            image: ovcodeImageFive,
            title: "Maximizing Efficiency with OVCODE's Real-Time Verification",
            date: "April 02, 2024",
            description: "Streamline operations and boost productivity with OVCODE’s real-time verification capabilities. This feature empowers businesses to validate transactions and credentials instantly, minimizing delays, and enhancing workflow efficiency. Real-time verification is key to maintaining a competitive edge in today’s fast-paced digital environment.",
            link: "/blog-industry-real-time-verification",
        },
        {
            title: "The Future of Collecting - Blockchain-Certified Cybergems",
            date: "May 06, 2024",
            description: "Envision the future of collecting through the lens of OVCODE-certified Cybergems and how this transforms the way we think about ownership and value in the digital age.",
            image: ovcodeCGImageFive,
            link: "/blog-cybergems-future-collecting"
        },
        {
            image: ovcodeImageSix,
            title: "OVCODE: Bridging Certified Trust with AATL and Blockchain Synergy",
            date: "April 02, 2024",
            description: "OVCODE redefines digital trust by integrating AATL-certified security with the unparalleled verification power of blockchain technology. This synergy enhances document security and integrity, streamlines global digital processes, and sets a new standard for trust in the digital age.",
            link: "/blog-industry-blockchain-synergy",
        },
        {
            title: "Crafting Trust in the Blockchain - OVCODE's Role in NFT Integrity",
            date: "May 07, 2024",
            description: "Delve into the concept of trust in the blockchain ecosystem and how OVCODE’s robust authentication protocols are critical for maintaining the integrity of NFTs like Cybergems.",
            image: ovcodeCGImageSix,
            link: "/blog-cybergems-ovcode-role-nft"
        },
        {
            image: ovcodeImageSeven,
            title: "Building Trust in the Cloud Era with Technology Certified by AATL Members",
            date: "April 02, 2024",
            description: "In the fast-evolving cloud computing landscape, OVCODE's use of technology certified by members of the Adobe Approved Trust List (AATL), like Entrust, marks a significant leap in establishing trust. Delve into how OVCODE's adherence to these distinguished security standards is crucial for fostering unshakeable confidence in cloud-based interactions and data storage.",
            link: "/blog-industry-building-trust",
        },
        {
            title: "Cybergems: An Investment in the Future with OVCODE's Blockchain",
            date: "May 07, 2024",
            description: "Analyze the investment potential of Cybergems within the blockchain landscape, backed by OVCODE's secure technology, and discuss the factors that make them a sound investment.",
            image: ovcodeCGImageSeven,
            link: "/blog-cybergems-investment-era"
        },
        {
            image: ovcodeImageEight,
            title: "Decoding OVCODE's Security: PKI, Blockchain, and AATL Certified Excellence",
            date: "April 02, 2024",
            description: "Unpack the layers of security that OVCODE provides, combining the strength of Public Key Infrastructure (PKI) with the unalterable record-keeping of blockchain technology, enhanced by AATL-certified solutions from Entrust. This multifaceted security approach ensures that each user’s data remains untampered and protected against evolving digital threats.",
            link: "/blog-industry-decoding-security",
        },
        {
            title: "Cybergems Unveiled - The Dawn of Digital Heirlooms",
            date: "May 02, 2024",
            description: "Explore how OVCODE's blockchain authentication is integral to the launch of Cybergems, marking the beginning of a new chapter in digital legacy creation.",
            image: ovcodeCGImageTwo,
            link: "/blog-cybergems-unveiled"
        },
        {
            image: ovcodeImageNine,
            title: "Future-Proofing Data Integrity: OVCODE’s Secure Storage Solutions",
            date: "April 02, 2024",
            description: "With OVCODE’s secure storage solutions, users benefit from a forward-looking approach that safeguards data integrity against the test of time. This prepares businesses and individuals alike for a future where data permanence and retrieval are critical, ensuring long-term access and reliability.",
            link: "/blog-industry-future-proofing",
        },
        {
            title: "A Legacy in Pixels - OVCODE's Commitment to Secure Digital Legacies",
            date: "May 07, 2024",
            description: "Reflect on the importance of digital legacy and how OVCODE’s technology enables users to preserve their digital footprints for future generations with Cybergems.",
            image: ovcodeCGImageEight,
            link: "/blog-cybergems-pixel-legacy"
        },
        {
            image: ovcodeImageTen,
            title: "From On-Prem to Cloud: OVCODE's Flexible and Secure Storage Explained",
            date: "April 02, 2024",
            description: "Transition with ease from on-premises to cloud storage without sacrificing security with OVCODE’s solutions. Flexibility doesn't come at the cost of protection; instead, OVCODE ensures that wherever your data resides, it's under the lock and key of industry-leading security standards.",
            link: "/blog-industry-flexible-and-secure-storage",
        },
        {
            title: "The Evolution of Digital Collectibles with OVCODE and Cybergems",
            date: "May 07, 2024",
            description: "Take a look at how the collaboration between OVCODE and Cybergems is pushing the boundaries of digital collectibles and leading an industry evolution.",
            image: ovcodeCGImageNine,
            link: "/blog-cybergems-evolution-of-digital-collectibles"
        },
        {
            image: ovcodeImageEleven,
            title: "OVCODE's Hybrid Approach: Combining Traditional PKI with Blockchain Innovation",
            date: "April 02, 2024",
            description: "OVCODE's hybrid security model merges the reliability of traditional PKI with the cutting-edge innovation of blockchain. Learn about this synergistic approach that offers users a comprehensive and advanced level of data protection and verification.",
            link: "/blog-industry-hybrid-approach",
        },
        {
            image: ovcodeCGImageTen,
            title: "Intersecting Paths - When Blockchain Meets Personal Memories",
            date: "May 07, 2024",
            description: "Examine the intersection of blockchain technology and personal memorabilia, and how OVCODE's secure platform makes Cybergems a reality.",
            link: "/blog-cybergems-intersecting-paths",
        },
        {
            image: ovcodeImageTwelve,
            title: "Exploring the Layers of OVCODE's Security: Certified Trust in a Digital World",
            date: "May 07, 2024",
            description: "Dive deep into the multilayered security framework of OVCODE, where every layer is fortified with AATL member-certified technologies. This in-depth look showcases how integrated security measures work in concert to protect digital assets and transactions.",
            link: "",
        },
        {
            image: ovcodeCGImageEleven,
            title: "Embracing a New Era of NFTs with OVCODE's Trusted Technology",
            date: "May 07, 2024",
            description: "Discuss the broader implications of OVCODE’s technology in shaping the future of NFTs and digital assets, highlighting the success of Cybergems as a case study.",
            link: "",
        },
        
        
        
        
    ];

    export { industryInsightData }

import React, { useRef, useState } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import services_images from "./products-images.component";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation, HashNavigation } from "swiper";
import "swiper/css/effect-cards";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ProductsServicesSlider from "./products-services-slider.component";

const ServicesSliderComponent = (props) => {

  const { static_variable } = props;
  const [swiper, setSwiper] = useState(null);

  const breakpoints = useWidth();

  return (

    <Box sx={{ overflow: 'hidden' }}>

      <Box sx={{ mb: 2, mt: 5 }}>
        <Typography sx={{
          fontSize: 37,
          margin: 0,
          lineHeight: 1.2,
          fontWeight: 600,
          textAlign: 'start',
          color: "#000",
          mb: '1.25rem'
        }}>
          Solutions
        </Typography>
      </Box>

      <Box sx={{ mb: 5, mt: 5 }}>
        <ProductsServicesSlider />
      </Box>
      {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <button onClick={() => swiper.slidePrev()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
          <ArrowBackIosIcon />
        </button>
        <Swiper
          pagination={{ clickable: true }}
          className="mySwiper"
          onSwiper={setSwiper}
          slidesPerView={(breakpoints == "xs" || breakpoints == "sm") ? 1 : 3}
          spaceBetween={5}
          modules={[Pagination]}
        >

          {services_images.map((value) => {
            return (
              <SwiperSlide>
                <div style={{ display: "flex", height: "100%", width: "98%", border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: 4 }}>
                  <Card sx={{ boxShadow: 'none', border: '1px solid rgba(0,0,0,0.15)', padding: 1, height: breakpoints == "md" ? 520 : 420 }} >
                    <CardMedia
                      component="img"
                      image={value.icon}
                      style={{ height: '50%', objectFit: 'contain', margin: 'auto', borderRadius: 10 }}
                    />
                    <Typography sx={{ color: "#007fff", textAlign: 'center', fontWeight: 600, pt: breakpoints == "md" ? 0 : 2, pb: 2, fontSize: 16 }}>
                      {value.name}
                    </Typography>
                    <Typography sx={{ margin: 0, fontWeight: 400, fontSize: '0.98rem', textAlign: 'justify', textJustify: 'inter-word', color: 'rgb(100, 110, 115)' }}>
                      {value.description}
                    </Typography>
                  </Card>
                </div>
              </SwiperSlide>
            )
          })}

        </Swiper>
        <button onClick={() => swiper.slideNext()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
          <ArrowForwardIosIcon />
        </button>
      </div> */}
    </Box>
  );
}

export default ServicesSliderComponent

import { Box, Grid, Typography } from "@mui/material";
import verificationOne from "../../../../images/verify/Artificial.png";
import verificationTwo from "../../../../images/verify/10.png";
import verificationThree from "../../../../images/verify/three.png";
import useWidth from "../../../../hooks/useWidth";

const ExploreVerificationComponent = () => {

    const breakpoints = useWidth();

    return (
        <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: breakpoints === 'xs' ? 1 : 6, pt: breakpoints === 'xs' ? 1 : 3, pb: breakpoints === 'xs' ? 1 : 3 }}>
            <Grid item xs={12}>
                <Typography sx={{
                     fontSize: 29,
                     margin: 0,
                     lineHeight: 1.2,
                     fontWeight: 700,
                     textAlign: 'center',
                     color: "#000"
                }}>
                    Explore the OVCODE Enterprise Portal's Platform
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mt: 5 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 600,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    ml: (breakpoints == "xl") ? "34px !important" : (breakpoints == "lg") ? "23px !important" : {}
                                }}>
                                    Introducing OVCODE Enterprise Portal

                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    width: (breakpoints == "xl") ? "90%" : (breakpoints == "lg") ?  "90%" :  (breakpoints == "xs") ? "95%" : '80%',
                                    ml: (breakpoints == "xl") ? "34px !important" : (breakpoints == "lg") ? "23px !important" : {},
                                    mt: "-5px !important",
                                    mb: "8px !important",
                                }}>
                                    The OVCode Enterprise Portal stands as a versatile and
                                    comprehensive platform tailored to streamline project
                                    management and collaboration within enterprises. At its core,
                                    the portal offers a user-friendly interface for initiating and
                                    overseeing projects from inception to completion. Its intuitive
                                    project creation tools empower teams to define project
                                    scopes, set milestones, and allocate resources efficiently.

                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <img alt="" src={verificationOne} style={{ width:  breakpoints === 'xs' ? '100%' : '90%' , borderRadius: 10}} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={1} sx={{ justifyContent: 'center!important', display: 'flex!important' }}>
                        <div style={{
                            borderBottomWidth: '.0625rem!important',
                            borderBottomStyle: 'solid',
                            borderBottomColor: '#d2d2d2'
                        }}></div>
                        <div
                            style={{
                                borderRightWidth: '.0625rem!important',
                                borderRightStyle: 'solid',
                                borderRightColor: '#d2d2d2'
                            }}></div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                }}>
                                    One of the standout features of
                                    the OVCode Enterprise Portal is
                                    its integrated API documentation
                                    functionality. This vital
                                    component equips developers
                                    and teams with a structured
                                    platform to document APIs
                                    comprehensively.

                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img src={verificationTwo} style={{ width:  breakpoints === 'xs' ? '100%' : '75%', borderRadius: 10 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                            <div style={{
                                borderBottomWidth: '.0625rem!important',
                                borderBottomStyle: 'solid',
                                borderBottomColor: '#d2d2d2'
                            }}></div>
                            <div
                                style={{
                                    borderRightWidth: '.0625rem!important',
                                    borderRightStyle: 'solid',
                                    borderRightColor: '#d2d2d2'
                                }}></div>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                }}>
                                    The OVCode Enterprise Portal
                                    also extends its capabilities to
                                    user management. Administrators
                                    can effortlessly create and add
                                    users, assigning them roles and
                                    permissions tailored to their
                                    responsibilities. This ensures that
                                    the right individuals have access
                                    to pertinent information while
                                    upholding data security and
                                    integrity.

                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src={verificationThree} style={{ width: breakpoints === 'xs' ? '100%' : '75%', borderRadius: 10 }} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default ExploreVerificationComponent;
import React, { useState } from "react";
import { Card,  CardContent,  Chip,  List, ListItem, ListItemIcon,  ListItemText, Typography} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const OvcodeReleaseNotesV2 = () => {
    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card
            sx={{
                borderRadius: "4px",
                marginBottom: "10px",
                transition: ".5s",
                boxShadow: "0 0 6px 1px rgba(0,0,0,0.08)",
                backgroundColor: "#ffffff",
                "&:hover": {
                    boxShadow: "0 0 12px 2px rgba(0,0,0,0.16)",
                },
            }}
        >
            <div style={{ padding: "40px" }}>
                <header style={{ textAlign: "start" }}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}
                    >
                        OVCODE Enterprise Portal Version 2.1
                    </Typography>
                    <div
                        style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            alignItems: "center",
                            fontSize: 14,
                            color: "#999",
                        }}
                    >
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={"NEW"} sx={{background: '#00800061'}} /> &nbsp;
                                <Chip label={"FIXES"} sx={{background: '#ff000057'}} /> &nbsp;
                                <Chip label={"ENHANCEMENTS"} sx={{background: '#ffa5004f'}} /> 

                            </ListItem>
                        </List>
                        <Typography
                            variant="body2"
                            component="span"
                            className="posted-time"
                        >
                            <time dateTime="2022-11-24">November 24, 2023</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>
                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: "start" }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Change Filter for Analytics</Typography>}
                                    secondary="Analytics filter changed from year to date range."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Restore Deleted User</Typography>}
                                    secondary="Added functionality to restore deleted users."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Separation of MANAGE_CREDIT Permission</Typography>}
                                    secondary="MANAGE_CREDIT permission split into ADD_CREDIT and DEDUCT_CREDIT."
                                />
                            </ListItem>
                            {showFullDetails && (
                                <>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Credit Management in Project and Dashboard Component</Typography>}
                                            secondary="Project Credit Management now available in Project and Dashboard components."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Detailview Analytics</Typography>}
                                            secondary="Analytics now available in Project Detailview."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Notes in Project Deletion</Typography>}
                                            secondary="Added notes in Project Deletion that existing credit will be transferred automatically to 
                                            the enterprise account after deletion."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Filtering by Project</Typography>}
                                            secondary="Templates can now be filtered by project."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Files from Vault Filtering by Date Range</Typography>}
                                            secondary="Files from the vault can now be filtered by date range."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Analytics Filtering by Date Range</Typography>}
                                            secondary="Analytics can now be filtered by date range."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Export Analytics to PDF</Typography>}
                                            secondary="Added the ability to export analytics to PDF"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Analytics Total Display in Graph</Typography>}
                                            secondary="Analytics now display the total in graph form."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Reports UI/UX Design</Typography>}
                                            secondary="Enhanced UI/UX design for reports."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Remove OVCODE Logo from Landing Page</Typography>}
                                            secondary="Removed the OVCODE logo from the landing page and replaced it with 'Powered by 
                                            OVCODE' at the bottom right of the page"
                                        />
                                    </ListItem>

                                    <br/>

                                    <Typography variant="h6" component="h3" sx={{ textAlign: "start" }}>
                                        Fixes
                                    </Typography>
                                    <List sx={{ paddingLeft: 0 }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Get Project Credit Limit API</Typography>}
                                                secondary="Resolved an issue in the Get Project Credit Limit API that was causing inaccuracies."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Auto Revoked Config Features</Typography>}
                                                secondary="Addressed issues related to Project Auto Revoked Config features for improved stability."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Signature Implementation</Typography>}
                                                secondary="Revamped template signature implementation for enhanced performance and reliability."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Webhook API Secret Validation</Typography>}
                                                secondary="Improved Webhook API Secret Validation for increased security."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>SOA File Max Upload Size</Typography>}
                                                secondary="Adjusted the maximum upload size for SOA files to accommodate larger files (MB)."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>SOA Query Features</Typography>}
                                                secondary="Enhanced SOA Query Features for better search and retrieval capabilities."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Name Validation</Typography>}
                                                secondary="Updated template name validation to allow up to 50 characters with alphanumeric 
                                                characters, dash, and spaces only."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Name Validation</Typography>}
                                                secondary="Improved project name validation to allow up to 50 characters with alphanumeric 
                                                characters, dash, and spaces only."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Component - UI/UX</Typography>}
                                                secondary="Resolved UI/UX issues related to Project Component."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Deactivate and Activate Project</Typography>}
                                                secondary="Deactivate and Activate Project."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Enable Download Function in Project</Typography>}
                                                secondary="Resolved issues preventing the download function in projects."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Whitescreen Issue after Uploading Project Logo or Details</Typography>}
                                                secondary="Fixed whitescreen issues occurring after uploading project logo or details."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Issue in Configure with Certificate Function</Typography>}
                                                secondary="Addressed problems in configuring templates with certificate functions."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Auto Remove Signature Background</Typography>}
                                                secondary="Fixed issues related to automatically removing the signature background after uploading."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>eSeal Issues</Typography>}
                                                secondary="Resolved issues occurring during the uploading of eSeals."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Image Display of Signature in Template View</Typography>}
                                                secondary="Fixed issues related to the display of signature images in the template view."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Webhook UI/UX and Back Button</Typography>}
                                                secondary="Enhanced Webhook UI/UX and added a back button for improved navigation."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>User Component Editing</Typography>}
                                                secondary="Added information about the current role of the user being edited."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}> Menu Highlight on the Current Component</Typography>}
                                                secondary="Fixed menu highlighting to accurately reflect the current component."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}> Activity Logs Filter Records</Typography>}
                                                secondary="Resolved issues related to filtering records in activity logs."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Authentication Records UI/UX</Typography>}
                                                secondary="Improved UI/UX of authentication records and added links to IPFS for enhanced 
                                                traceability."
                                            />
                                        </ListItem>
                                    </List>

                                    <br/>

                                    <Typography variant="h6" component="h3" sx={{ textAlign: "start" }}>
                                        Enhancements
                                    </Typography>
                                    <List sx={{ paddingLeft: 0 }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Webhook API Error Handling</Typography>}
                                                secondary="Enhanced error handling for Webhook APIs."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Credit Error and Success Handling</Typography>}
                                                secondary="Improved error and success handling for Project Credit operations."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Credit Real-Time UI/UX Updates</Typography>}
                                                secondary="Real-time UI/UX updates for Project Credit operations."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template Service API Validation</Typography>}
                                                secondary="Added validation in template service APIs."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Project Expiration Date Features</Typography>}
                                                secondary="Added features related to project expiration dates."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>UI/UX Real-Time Update on Project Component</Typography>}
                                                secondary="Real-time updates on UI/UX when project components are updated."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Signature Image Placeholder</Typography>}
                                                secondary="Improved the placeholder for signature images."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Template UI/UX Design and Real-Time Updates</Typography>}
                                                secondary="Enhanced UI/UX design and real-time updates for templates."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Billing Record Search Functionality</Typography>}
                                                secondary="Added search functionality in billing records."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Clear Filter Functionality</Typography>}
                                                secondary="Added a clear filter option to reset queries in all list records."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Enterprise Logo Display</Typography>}
                                                secondary="Added the display of the enterprise logo on the top left after login."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon sx={{ width: 12, height: 12 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{fontSize: 16, fontWeight: 600}}>Align RBAC Configuration with Project Documentation</Typography>}
                                                secondary="Enhanced RBAC (Role-Based Access Control) configuration to align with the latest 
                                                updates in the Project Documentation, ensuring consistency and accuracy in user 
                                                permissions."
                                            />
                                        </ListItem>
                                    </List>
                                    
                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    );
};

export default OvcodeReleaseNotesV2;

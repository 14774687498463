import { Box, CardMedia, Grid, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth";


function VerifyBannerComponent() {

    const breakpoints = useWidth();


    return (
        <Box sx={{ mb: 1, width: (breakpoints === 'xs' || breakpoints === 'sm') ? '90%' : '100%', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Grid item xs={12} sx={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ mb: 4 }}>
                        <Typography sx={{ fontSize: 29, margin: 0, lineHeight: 1.2, fontWeight: 700, textAlign:  'center' , color: "#000" }}>
                            VERIFY HERE
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <Grid item xs={12} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={{ fontSize: 24, margin: 0, lineHeight: 1.2, fontWeight: 700, textAlign: 'center', color: "#000" }}>
                            Blockchain Trust Platform
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Typography sx={{ fontSize: 16, margin: 0, lineHeight: 1.6, color: 'rgb(67 69 71)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word' }}>
                            <b style={{ fontWeight: 700, textTransform: 'uppercase' }}>OVCODE</b> authenticates and verifies digital files and assets through the creation of your electronic identity, signature, and NFT.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )

}

export default VerifyBannerComponent
import { Box, Button, Grid, Typography, Link } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import YouTube from 'react-youtube'; // Import the react-youtube package
import LandingSlider from "./landing-slider.component";
import LandingBannerComponent from "./landing-banner.component";
import AboutUsComponent from "../../about/about-us.component";
import AboutUsScreen from "../../../screen/AboutUsScreen";
import LandingComponentv1 from "../../landing/landing.component";
import SolutionsSliderComponent from "../../landing/solutions/solutions-slider.component";
import DrawerHeader from "../layout/drawer-header";
import ServicesSliderComponent from "../../landing/products/services-slider.component";
import static_variable from "../layout/static-variable.component";
import ProductsSliderComponent from "../../landing/products/products-slider.component";
import AwardsComponent from "../../landing/awards/awards2.component";
import PatentsComponent from "../../landing/patents/patents2.component";
import PartnersComponent from "../../landing/partners/partners2.component";
import { useState } from "react";
import SolutionsShowCaseComponent from "../../landing/solutions/solutions-show-case.component";
import AboutBannerComponent from "../../about/about-banner2.component";
import AboutBannerComponentv1 from "../../about/about-banner.component";
import ExploreCoreTechnologiesComponent from "./core-technologies.component";
import BackToTopButton from "../../layout/scroll-up.component";
import IndustriesWeServeComponent from "./industries-we-serve.component";
import OVCodeAdvantagesComponent from "./ovcode-advantages.component";
// import background from "../../../images/join-us-banner5.png";
import JoinUsFutureComponent from "./join-us-future.component";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faTiktok, faYoutube, faPinterest, faTelegram, faRedditSquare, faWeixin, faMedium, faDigg, faMix } from '@fortawesome/free-brands-svg-icons';
import ProductPortfolioComponent from "../products/product-portfolio.component";
import FacebookIcon from "../../../icons/fb-sym.png";
import TwitterIcon from "../../../icons/twitter-sym.png";
import YoutubeIcon from "../../../icons/youtube-sym.png";
import Chatbot from "../../layout/chatbot.component";

const LandingComponent = () => {

    const breakpoints = useWidth();

    const [current_image, setCurrentImage] = useState(0);
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);

    const toggleChatbot = () => {
      setIsChatbotOpen(!isChatbotOpen);
    };

    return (
        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
            <div class="layout-container responsivegrid aem-GridColumn aem-GridColumn--default--12">
                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">

               

                    <div class="container-banner" style={{ padding: '0 5px' }}>
                        <LandingSlider setCurrentImage={setCurrentImage} />
                    </div>

                    <div class="container">
                        {/* { isChatbotOpen ?
                            null
                        :
                            <BackToTopButton isChatbotOpen={isChatbotOpen} />
                        } 
                        <Chatbot setIsChatbotOpen={setIsChatbotOpen} /> */}
                        
                        <Box sx={{ mt: 5 }} />

                        <SolutionsShowCaseComponent />

                        <div id="about-us-section"></div>
                        <Box></Box>
                        
                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <AboutBannerComponent />

                        <div id="solutions-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <ServicesSliderComponent />

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <ProductPortfolioComponent />

                        <div id="features-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <OVCodeAdvantagesComponent />


                        <div id="industry-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <IndustriesWeServeComponent />


                        <div id="implementation-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <SolutionsSliderComponent />

                        <div id="awards-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <AwardsComponent />

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <PatentsComponent />

                        <div id="partners-section"></div>
                        <Box></Box>

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />
                        
                        <PartnersComponent />

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <JoinUsFutureComponent />

                        <Box sx={{ mt: '3rem!important', mb: '3rem!important' }} />

                        <div class="socialfollow aem-GridColumn aem-GridColumn--default--12" style={{textAlign: 'start'}} >
                            <section class="col-12" aria-label="follow us on social media" style={{ display: 'flex', alignItems: 'center', padding: '0px' }} >
                                <div class="base font-weight-normal d-inline align-middle mr-g">
                                    Follow OVCODE 
                                </div> &nbsp;&nbsp;&nbsp;
                                <div class="soc-widget">
                                    <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                                        {/* <i><FontAwesomeIcon icon={faFacebook} /></i> */}
                                        <img alt="" src={FacebookIcon} style={{height: 20, marginRight: "3px"}} />
                                    </Link>
                                    <Link  target="_blank"  href="https://twitter.com/OVCode" underline="none">
                                        {/* <i><FontAwesomeIcon icon={faYoutube} /></i> */}
                                        <img alt="" src={TwitterIcon} style={{height: 18, marginRight: "5px"}} />
                                    </Link>
                                    <Link target="_blank" href="https://www.youtube.com/channel/UC4658E4JwgY3IFdqqohZBWw" underline="none">
                                        {/* <i><FontAwesomeIcon icon={faTwitter} /></i> */}
                                        <img alt="" src={YoutubeIcon} style={{height: 27, objectFit: "contain"}} />
                                    </Link>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingComponent;

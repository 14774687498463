import React, {useEffect} from "react"
import VerifyLinkComponent from "../components/landing/verify/verify-link.component";

const VerifyReferenceLinkScreen = () => {

    useEffect(() => {
        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog"); 
    }, []);

    return (
        <>
            <VerifyLinkComponent />
        </>
    )
}

export default VerifyReferenceLinkScreen
import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../../images/blog/secure-storage.png";


const SecureStorageComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                From On-Prem to Cloud: <br /> OVCODE's Flexible and Secure Storage Explained
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 02 April  2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As more businesses migrate from on-premise infrastructures to the cloud, the need for flexible and secure storage options has never been more pressing.
                                OVCODE rises to meet this challenge, offering a suite of storage solutions that fuse flexibility with fortress-like security, ensuring that the transition from on-prem to cloud is not just seamless, but also elevates the standard of data protection.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Embracing the Cloud with Confidence
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The shift to cloud storage comes with numerous advantages, including scalability, cost-effectiveness, and accessibility.
                                OVCODE enhances these benefits with robust security protocols that protect data across all stages of the migration and beyond.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Secure Migration Services
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word', textJustify: 'inter-word', wordSpacing: '3.8px' }}>
                                OVCODE's secure migration services are designed to protect data as it moves from on-premise servers to the cloud.
                                With <br /> end-to-end encryption and meticulous data handling practices, OVCODE ensures that the integrity of data is maintained throughout the transition.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Flexible Storage Options
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Understanding that each organization has unique needs, OVCODE provides a range of storage options.
                                From fully-managed cloud services to hybrid solutions that integrate with existing on-premise infrastructures, OVCODE tailors its offerings to fit the specific requirements of every client.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Advanced Security Features
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Security is at the forefront of OVCODE's cloud storage solutions, integrating advanced features that safeguard data against the evolving landscape of digital threats.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Cutting-Edge Encryption
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Data in OVCODE's cloud storage is shielded by cutting-edge encryption technologies.
                                This cryptographic protection acts as a virtually impenetrable barrier against unauthorized access, ensuring that sensitive information remains confidential.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Continuous Monitoring and Compliance
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's cloud services include continuous monitoring systems that detect and neutralize threats in real-time.
                                Compliance with international standards and regulations is a given, as OVCODE commits to upholding the strictest data protection policies.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion: A Smooth Transition with OVCODE
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Transitioning from on-prem to cloud storage can be daunting, but with OVCODE's flexible and secure storage solutions, businesses can make the leap with confidence.
                                By prioritizing security, compliance, and adaptability, OVCODE provides a cloud storage experience that is not just about keeping up with the times but staying ahead of them.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                    // onClick={() => window.location.href = "/blog-industry-hybrid-approach"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Evolution of Digital Collectibles with OVCODE and Cybergems
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Take a look at how the collaboration between OVCODE and Cybergems is pushing the boundaries of digital collectibles and leading an industry evolution.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 07, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-hybrid-approach"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE's Hybrid Approach: Combining Traditional PKI with Blockchain Innovation
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        OVCODE's hybrid security model merges the reliability of traditional PKI with the cutting-edge innovation of blockchain
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                    {/* <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-evolution"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Evolution of Digital Trust: A Deep Dive into OVCODE
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        In the rapidly advancing digital age, trust is the linchpin that holds our online interactions together.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card> */}
                </Grid>
            </Grid>
        </Box>
    )
}

export default SecureStorageComponent
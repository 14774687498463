import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";

function SolutionsDialogComponent (props) {

    const {openSolutions, handleClose, description, title, name} = props;

    const handleCloseDialog = () => {
        handleClose();
    }

    console.log("namexxxx", name)

    return(
        <Dialog open={openSolutions} onClose={handleClose} maxWidth={'md'} sx={{overflow: 'hidden'}}>
            <DialogTitle style={{fontSize: '1.2rem', margin: 0,  color: 'rgb(45, 55, 72)', fontWeight: 700, textAlign: 'center'}} >
                {title}
            </DialogTitle>

            <DialogContent>
                {description}
            </DialogContent>

            <DialogActions>
                {/* {name !== "eSign" && name !== "eID" && name ? 
                    (
                        <Typography sx={{fontSize: 12, fontWeight: 'bold'}}>(scroll to read more)</Typography>
                    ) 
                :
                    null
                } */}

                <Button sx={{textTransform: "none", color: "#1976d2", fontSize: 16}} 
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SolutionsDialogComponent
import {createStore, selector} from "./helper";

const module_name = "verifiedData"

export const verifiedDataStore = createStore((set) => ({
    
    // state
    scannedReference: null,
    scannedInfo: null,
    docPrivacy: null,
    isDownloadable: null,
    pdfBase64UrlData: null,
    docStatus: null,


    // actions
    setScannedReference: (scannedReference) => set({scannedReference: scannedReference}, null, module_name + "/data"),
    setScannedInfo: (scannedInfo) => set({scannedInfo: scannedInfo}, null, module_name + "/data"),
    setDocPrivacy: (docPrivacy) => set({docPrivacy: docPrivacy}, null, module_name + "/data"),
    setDownloadable: (isDownloadable) => set({isDownloadable: isDownloadable}, null, module_name + "/data"),
    setPdfBaseUrlData: (pdfBase64UrlData) => set({pdfBase64UrlData: pdfBase64UrlData}, null, module_name + "/data"),
    setDocStatus: (docStatus=> set({docStatus: docStatus}, null, module_name + "/data"))

}), module_name)

export const useVerifiedDataStore = () => verifiedDataStore(selector)
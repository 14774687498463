import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Container, CssBaseline, Avatar, Backdrop, Grid, List, ListItem, ListItemText, Box, AppBar, Toolbar } from '@mui/material';
import useWidth from '../hooks/useWidth';
import DrawerHeader from "../components/layout/drawer-header";

const styles = {
    paper: {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: '1rem',
    },
};


const AccountDeletionInstructionScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <Box component="main" sx={{ flexGrow: 1, pt: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 0, width: "100%" }}>

                {(breakpoints == 'xs' || breakpoints == 'sm') && <DrawerHeader />}

                <Box sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? "0px 8px 0px 8px" :  "0px 128px 0px 128px" }}>

                    <Box sx={{ mb: 4, backgroundColor: "#f2f2f2", paddingTop: "25px", paddingBottom: "25px" }}>
                        <Typography sx={{ color: "#000000CC", fontSize: 48, margin: 0, lineHeight: 1.2, fontWeight: 400, textAlign: 'center' }}>
                            Requesting Account Deletion
                        </Typography>
                    </Box>

                    <Grid container sx={{ padding: "0px" }}>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                1. Login to Your Account
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Open your OVCode Mobile Application.
                                    </li>
                                    <li>
                                        Enter your login credentials (email and password) and click "Sign In."
                                    </li>
                                    {/* <li>
                                        Enter the verification code sent to the Microsoft Authenticator App.
                                    </li> */}
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                2. Access Your Settings
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        After logging in, tap on the Settings icon located in the menu bar of the app.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                3. Find the "Delete Account" Option
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Within your Setting, look for the "Delete Account” option.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                4. Follow the Deletion Process
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Click on the "Delete Account" option and carefully read the process of deleting your account.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                5. Wait for Confirmation
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Once you have successfully initiated the account deletion process, you will receive an email regarding your process.
                                    </li>
                                    <li>
                                        The system will notify you to expect an email within 3-5 business working days for confirmation.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                6. Check Your Email
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Keep an eye on the email associated with your account.
                                    </li>
                                    <li>
                                        In the next 3-5 business working days, you will receive an email confirming the deletion of your account.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                7. Review Confirmation Email
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        Open the confirmation email and review its contents.
                                    </li>
                                    <li>
                                        The email will officially confirm that your account has been deleted.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2.5 }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 26 }}>
                                8. Contact Support if Needed
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "start", fontWeight: 400, fontSize: 16 }}>
                                <ul style={{ fontWeight: "400", marginTop: "3px" }}>
                                    <li>
                                        If you encounter any issues, did not initiate the request, or have concerns during the waiting period, please reach out to our support team for assistance.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountDeletionInstructionScreen;

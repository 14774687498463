import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import customer_stories_images from "../customer-stories/customer-stories-images.component";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function ProductsSliderComponent(props) {
  const breakpoints = useWidth();
  const [swiper, setSwiper] = useState(null);

  return (
    <Box sx={{ mb: 4,  margin: "auto", overflow: "hidden" }}>

      <Box sx={{mb: 4}}>
        <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
            Customer Stories
        </Typography>
      </Box>

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <button onClick={() => swiper.slidePrev()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
          <ArrowBackIosIcon />
        </button>
        <Swiper
          pagination={{ clickable: true }}
          loop={true}
          className="mySwiper"
          onSwiper={setSwiper}
        >
          {customer_stories_images.map((value) => {
            return (
              <SwiperSlide key={value.name}>
                <div style={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                   border: "1px solid #80808030", display: "flex", height: "100%", width: "90%", borderRadius: 4, margin: 'auto' }}>
                  <Grid container spacing={2} style={{ height: breakpoints === "md" ? 300 : 235, width: "100%" }}>
                    <Grid item xs={12} sm={4} sx={{ justifyContent: "center", alignItems: "center", alignSelf: "center" }}>
                      <Box sx={{ padding: 4}}>
                        <CardMedia
                          component="img"
                          image={value.icon}
                          style={{
                            objectFit:
                              value.name === "S. Murad , Trade Facilitation Consultant" ||
                                value.name === "A. Bendicio, IT Manager" ||
                                value.name === "A. Tomas, BSBA Major in Marketing Management"
                                ? "cover"
                                : "contain",
                            margin: "auto",
                            width: 120,
                            height: 120,
                            borderRadius:
                              value.name === "S. Murad , Trade Facilitation Consultant" ||
                                value.name === "A. Bendicio, IT Manager " ||
                                value.name === "A. Tomas, BSBA Major in Marketing Management"
                                ? "50%"
                                : 10,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box sx={{ padding: 5 }}>
                        <div style={{ position: "relative" }}>
                          <span
                            style={{
                              color: "#017eff",
                              display: "inline-block",
                              fontFamily: "Sailec, sans-serif",
                              fontSize: "68px",
                              lineHeight: ".76",
                              position: "absolute",
                              left: "-40px",
                              top: 0,
                            }}
                          >
                            &ldquo;
                          </span>
                          <Typography
                            sx={{
                              margin: 0,
                              fontWeight: 400,
                              fontSize: 16,
                              textAlign: "justify",
                              textJustify: "inter-word",
                              color: "rgb(100, 110, 115)",
                            }}
                          >
                            {value.description}
                          </Typography>
                        </div>
                        <Typography
                          sx={{
                            textAlign: "start",
                            fontWeight: 700,
                            mt: 1,
                            pt: 0.5,
                            pb: 2,
                            fontSize: "1.1rem",
                          }}
                        >
                          {value.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button onClick={() => swiper.slideNext()} style={{ background: "transparent", border: "none", color: "#017eff" }}>
          <ArrowForwardIosIcon />
        </button>
      </div>
    </Box>
  );
}

export default ProductsSliderComponent;

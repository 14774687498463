
import news1 from '../../../images/news/news-1.jpg';
import news2 from '../../../images/news/news-2.jpg';
import news3 from '../../../images/news/news-3.jpg';



const ovcode_news_images = [
    {
        name : "Authenticate Digital ID",
        icon : news1,
        icon_hover : news1,
        description : "Find out how to authenticate digital IDs and verify them via OVCODE.",
        path: "https://www.facebook.com/OVCodeSwitzerlandAG/posts/2928541454139224?__tn__=-R"
    },
    {
        name : "Payslip Authentication",
        icon : news2,
        icon_hover : news2,
        description : "Authenticate and verify your company payslips using OVCODE.",
        path: "https://www.facebook.com/OVCodeSwitzerlandAG/posts/2926954374297932?__tn__=-R"
    },
    {
        name : "Exposing Fake News",
        icon : news3,
        icon_hover : news3,
        description : "Find out the authenticity of the website using OVCODE.",
        path : "https://www.facebook.com/OVCodeSwitzerlandAG/posts/2923910337935669?__tn__=-R"
    }
]

export default ovcode_news_images

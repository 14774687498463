import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import LandingBanner from '../../../images/ovcode-work-v4.png';
import PastelFour from '../../../images/pastel-four.jpg';
import EmpoweringBackground from '../../../images/banner-2.png';
// import EmpoweringBackground from '../../../images/banner/banner-3.png';


const LandingEmpoweringComponent = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${EmpoweringBackground})`, 
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '3rem!important' }}>
                            <Box sx={{ mb: 1 }}>
                                <Typography sx={{
                                color: "#fff",
                                fontSize: 24,
                                margin: 0,
                                lineHeight: 1.6,
                                fontWeight: 400,
                                textAlign: 'start',
                                width: '95%',
                                textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"
                                // textShadow: '1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 0px 1px #ccc, 1px 1px 1px #eee,3px 0px 1px #ccc, 2px 1px 1px #eee,4px 0px 1px #ccc, 3px 1px 1px #eee'

                            }}
                            >
                             Unlocking a New Era of Trust:
                            </Typography>
                            </Box>
                            <Box sx={{ mb: 5 }}>
                                <Typography
                                    sx={{
                                        fontSize: 19,
                                        margin: 0,
                                        lineHeight: 1.2,
                                        fontWeight: 700,
                                        textAlign: 'start',
                                        color: "#fff",
                                        width: '95%',
                                        textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"
                                        // textShadow: '1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 0px 1px #ccc, 1px 1px 1px #eee,3px 0px 1px #ccc, 2px 1px 1px #eee,4px 0px 1px #ccc, 3px 1px 1px #eee'


                                    }}
                                >
                                    The Advantages of OVCODE
                                </Typography>
                            </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography sx={{
                                fontSize: 16,
                                margin: 0,
                                lineHeight: 1.6,
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                color: "#fff",
                                width: '95%',
                                textShadow: "rgb(0 0 0 / 81%) 1px 0 10px"
                                // textShadow: '1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 0px 1px #ccc, 1px 1px 1px #eee,3px 0px 1px #ccc, 2px 1px 1px #eee,4px 0px 1px #ccc, 3px 1px 1px #eee'



                            }}>
                                In today's digital landscape, whether you're safeguarding vital documents, 
                                verifying identities, or sealing critical transactions; 
                                trust is paramount. That's 
                                where OVCODE steps in, revolutionizing the way we 
                                authenticate, secure, and connect. 
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'end' }}>
                        {/* <Box sx={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
                            <img
                                src={LandingBanner}
                                style={{
                                    imageRendering: 'pixelated',
                                    msInterpolationMode: 'nearest-neighbor', // For IE
                                    imageRendering: '-moz-crisp-edges', // For Firefox
                                    imageRendering: '-webkit-optimize-contrast', // For Webkit browsers
                                    width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : (breakpoints == "lg") ? '60%' : (breakpoints == "xl") ? '55%' : (breakpoints == "md") ? '85%' : '65%'

                                }}
                                alt="Landing Solutions"
                            />
                        </Box> */}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default LandingEmpoweringComponent
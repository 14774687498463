import {Box} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import DrawerHeader from "../../layout/drawer-header";
import SolutionsBannerComponent from "./solutions-banner.component";
import SolutionsShowCaseComponent from "./solutions-show-case.component";
import SolutionsSliderComponent from "./solutions-slider.component";
import static_variable from "../../layout/static-variable.component";
import OvcodeNewsComponent from "../ovcode-news/ovcode-news.component";


function SolutionsComponent() {

    const breakpoints = useWidth();



    return(

        <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? '90%' : '100%', margin: 'auto', overflowX : 'hidden'}}>

            <DrawerHeader/>
           
            <SolutionsBannerComponent breakpoints={breakpoints} static_variable={static_variable} />

            <DrawerHeader />

            <SolutionsShowCaseComponent breakpoints={breakpoints} />

            <DrawerHeader />

            <OvcodeNewsComponent breakpoints={breakpoints} />

            {/* <DrawerHeader />

            <SolutionsSliderComponent breakpoints={breakpoints}  /> */}

            <DrawerHeader />

        </Box>

        

    )
    
}

export default SolutionsComponent
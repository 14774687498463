import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const others = [
    {
        name: "How or what will be the P&P of unauthorized transactions or activities in each OVCODE product?",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Yes, OVCODE offers integration capabilities through its API Gateway (Azure APIM), allowing you to securely connect OVCODE's solutions with your systems.
                </Typography>
            </Box>
    },
    {
        name: "Does the distributor have liability? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Our policies and contracts outline the measures we take to prevent unauthorized transactions or activities. In case of any unauthorized action, we have a clear process in place to address and rectify the situation. These steps are in line with our commitment to maintaining the integrity and security of our products)
                </Typography>
            </Box>
    },
    {
        name: "How will the release of patches and bug fixing be managed? ",
        panel: "Panel3",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (The release of patches and bug fixing follows our established policies and agreements. We ensure that any necessary updates are carried out in line with clear terms and conditions.)
                </Typography>
            </Box>
    }
    ,
    {
        name: "OVCODE Product Versioning – does each version update will be free for each client? ",
        panel: "Panel4",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Policy and Contract Based (Whether each version update is free for clients is specified in our policies and agreements. Our commitment to transparency ensures that any potential costs or benefits associated with version updates are clearly outlined.)
                </Typography>
            </Box>
    }

]

export default others

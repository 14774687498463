import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import EvolutionImage from "../../../../../images/blog/evolution.png";

const TheEvolutionOfDigitalTrustComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                The Evolution of Digital Trust: A Deep Dive into OVCODE
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Friday, 12 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={EvolutionImage} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the rapidly advancing digital age, trust is the linchpin that holds our online interactions
                                together. As we navigate an increasingly interconnected world, the demand for secure and
                                trustworthy digital transactions has never been higher. This is where OVCODE steps in,
                                revolutionizing the very fabric of digital trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Unraveling the Historical Threads</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                To understand the significance of OVCODE in the grand tapestry of digital trust, we embark
                                on a journey through the historical evolution of this critical concept. In the early days of the
                                internet, trust was a fragile commodity, easily shattered by concerns of data breaches and
                                fraudulent activities.
                            </Typography>
                            <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                Enter OVCODE, a trailblazer in the blockchain space. Leveraging the power of decentralized
                                ledger technology, OVCODE addresses the vulnerabilities that plagued traditional systems.
                                It is not just a platform; it is a paradigm shift—a commitment to rewriting the narrative of
                                trust in the digital realm.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE: A Catalyst for Change</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE isn’t just a response to the challenges of the digital era; it is a proactive force,
                                shaping the evolution of digital trust. At its core, OVCODE introduces transparency,
                                immutability, and security into every digital interaction. The blockchain technology it
                                employs ensures that once data is recorded, it cannot be altered or tampered with—a digital
                                seal of authenticity.
                            </Typography>
                            <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                With features designed to fortify trust, OVCODE becomes the bedrock upon which secure
                                digital transactions can thrive. Its decentralized nature decentralizes power, making it
                                resilient against single points of failure and immune to the manipulation of bad actors.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Impact Across Industries</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The impact of OVCODE extends far beyond individual transactions. It's a driving force across
                                diverse industries—finance, healthcare, supply chain, and more. In healthcare, OVCODE
                                ensures the integrity of patient data; in finance, it secures transactions with a level of trust
                                previously unimaginable. OVCODE is not merely a solution; it's a movement—an evolution
                                towards a world where digital interactions are synonymous with trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Shaping the Future</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As we gaze into the future, OVCODE stands tall, leading the charge towards a more secure
                                digital landscape. Its vision extends beyond the present, anticipating the needs of industries
                                yet to emerge and the challenges of an ever-evolving technological landscape.
                            </Typography>
                            <Typography sx={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                In conclusion, OVCODE is not just a platform; it's a narrative—the story of digital trust
                                rewritten. As we navigate this digital evolution, OVCODE remains at the forefront, a beacon
                                guiding us towards a future where trust is not just an expectation but an assurance.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>


            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-digital-trust"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Why Digital Trust Matters in Today's World
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Highlight the importance of digital trust in various industries and how OVCODE addresses the growing need for secure digital transactions.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550   }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-trailblazer-blockchain"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE: A Trailblazer in Blockchain Technology
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Showcase how OVCODE leverages blockchain technology to provide a robust and secure solution for digital trust.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550   }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    )
}

export default TheEvolutionOfDigitalTrustComponent
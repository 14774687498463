import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import CommunitySpotlight from "../../../../../images/blog/community-spotlight.png";


const CommunitySpotlightComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Community Spotlight: Stories from the OVCODE User Community
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={CommunitySpotlight} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                The heart of OVCODE lies in its diverse and engaged user community. Here, we celebrate
                                the successes, experiences, and contributions of the individuals and businesses that
                                make up the dynamic fabric of the OVCODE community.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Empowering Businesses with Trust:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;John Doe, CEO of XYZ Enterprises</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        John Doe, the CEO of XYZ Enterprises, shares how OVCODE has empowered his business
                                        with a new level of trust in digital interactions. By implementing OVCODE solutions, XYZ
                                        Enterprises has streamlined its document workflows, ensuring the security and
                                        authenticity of critical business documents. John notes, "OVCODE has not just been a
                                        solution; it's a partner in our journey towards a more secure and efficient digital future."
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. From Artist to Innovator:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;Jane Smith, Digital Artist</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        Jane Smith, a digital artist, discovered a new dimension to her creative journey with
                                        OVCODE's eMark. By safeguarding her digital artworks with eMark, Jane ensures that each
                                        piece retains its authenticity. "OVCODE eMark has transformed the way I approach my art.
                                        It's not just a watermark; it's a statement of authenticity," says Jane.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Collaborative Solutions in the Forum:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;Community Member, OVCODE Forum</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        In the OVCODE community forums, users collaborate and share insights. One user, a
                                        blockchain enthusiast, found solutions to a technical challenge through collaborative
                                        discussions. "The OVCODE forums are not just a place for questions; it's a space for
                                        collective problem-solving. The support and knowledge-sharing here are invaluable,"
                                        shares the community member.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Small Business, Big Impact:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;Small Business Owner</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        A small business owner shares how OVCODE has made a big impact on their operations. By
                                        integrating eSign into their workflow, the business has seen significant time savings and
                                        enhanced document security. "OVCODE eSign is a game-changer for small businesses. It's
                                        easy to use, secure, and has transformed the way we handle contracts and approvals,"
                                        expresses the small business owner.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>5. Navigating the Future Together:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;Developer in the OVCODE Ecosystem</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        A developer within the OVCODE ecosystem reflects on the journey of innovation. "Being
                                        part of the OVCODE ecosystem is not just about technology; it's about navigating the
                                        future together. The collaborative spirit and commitment to excellence make it an exciting
                                        space to be in," says the OVCODE developer.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>6. Beyond Boundaries with Global Access:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>User:</b>&nbsp;Remote Worker</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Story:</b></Typography>
                                    <Typography>
                                        A remote worker highlights the global accessibility of OVCODE solutions. "Working
                                        across different time zones, OVCODE's global accessibility has been a game-changer.
                                        From eSign to secure document sharing, it's a suite of tools that transcends
                                        boundaries," shares the remote worker.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Join the Spotlight:</Typography>
                            <Typography>
                                Your story could be the next one in the OVCODE Community Spotlight! Share your
                                experiences, successes, and contributions on our forums or social media. Whether you're
                                a business leader, an artist, a developer, or part of the global remote workforce, your
                                journey with OVCODE is an integral part of the community narrative.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-navigating-secure-storage"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Navigating Secure Storage Options with OVCODE:  Cloud vs. On-Premises
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        With OVCODE, navigate the choices between cloud and on-premises storage confidently.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 06, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-partner-unlock-growth"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Unlock Growth: A Guide to Becoming an OVCODE Partner
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Outline the benefits and steps for individuals or businesses interested in becoming OVCODE partners.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CommunitySpotlightComponent
import { Box, Grid, Typography } from "@mui/material"


const faq_details = [
    {
        name: "What is OVCODE Trust Platform?",
        panel: "Panel1",
        description: <Box>
            <Typography sx={{ mb: 2 }}>
                The OVCODE Trust Platform is an advanced cloud-based solution that seamlessly integrates cutting-edge technologies, including blockchain, digital signatures, and cryptography. Our platform is designed to establish an unparalleled level of transparency, security, and trust for all your digital interactions. By harnessing the power of our patented zero cycle latency algorithm, we ensure that data published on the OVCODE network remains secure, accurate, and immutable.
            </Typography>

            <Typography sx={{ mb: 2 }}>
                The OVCODE Trust Platform provides an array of tools that empower you to safeguard the integrity of your data, verify identities, and authenticate digital documents and physical items. Whether you're an individual, business, or developer, OVCODE Trust Platform offers a comprehensive suite of solutions to elevate your digital interactions to new heights of reliability and confidence.
            </Typography>

            <Typography sx={{ mb: 2 }}>
                Our commitment to industry-leading standards, such as United States (NIST) and European (eIDAS) regulations, along with our global patent registration and Swiss domicile, further exemplifies the OVCODE Trust Platform's dedication to providing you with an unparalleled level of trust and security.
            </Typography>
        </Box>
    },
    {
        name: "What Makes OVCODE unique? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    OVCODE is a fully operational system with over four years of real-world use cases for identity, blockchain, and digital signatures. Our technology is patented in 155 countries, and we are registered in Switzerland, a neutral and trustworthy domicile. We adhere to United States (NIST) and European (eIDAS) standards and offer a developer console for partners and clients to build custom platforms.
                </Typography>
            </Box>
    },
    {
        name: "Who are the founders of OVCODE?",
        panel: "Panel3",
        description: "The OVCODE team boasts 8-12 years of collaboration under NEXTIX, delivering award-winning products globally. With deployments in various countries, including Silicon Valley, Australia, and China, our team brings a wealth of experience to the table."
    },
    {
        name: "How can OVCODE benefit my business? ",
        panel: "Panel4",
        description: "OVCODE provides tools for identity verification, electronic signatures, and data integrity, which can enhance security, streamline processes, and build trust in your transactions."
    },
    {
        name: "Is OVCODE's technology patented? ",
        panel: "Panel5",
        description: "Yes, OVCODE's technology is patented in 155 countries, providing a strong foundation of innovation and security."
    }
]

export default faq_details

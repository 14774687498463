import DashboardIcon from "../../../../images/verify/icons/dashboard.png";
import ProfileIcon from "../../../../images/verify/icons/account-profile.png";
import ProjectIcon from "../../../../images/verify/icons/project.png";
import VaultIcon from "../../../../images/verify/icons/vault.png";
import AuthenticationIcon from "../../../../images/verify/icons/authentication.png";
import AnalyticsIcon from "../../../../images/verify/icons/analytics.png";
import APIIcon from "../../../../images/verify/icons/api-documentation.png";
import TemplateIcon from "../../../../images/verify/icons/template.png";
import UsersIcon from "../../../../images/verify/icons/users.png";

const verification_images = [
    {
        name: 'Dashboard',
        icon: DashboardIcon,
        icon_hover: DashboardIcon,
        description: "Dashboard - The command center for effortlessly keeping an eye on all your authenticated files, ensuring your data's integrity at a glance."
    },
    {
        name: 'Profile',
        icon: ProfileIcon,
        icon_hover: ProfileIcon,
        description: "Profile - Your Gateway to Personalization! This is where you discover your profile information and snatch your subscription key for exclusive access."
    },
    {
        name: 'Project',
        icon: ProjectIcon,
        icon_hover: ProjectIcon,
        description: "Project - Your Gateway to Personalization! This is where you discover your profile information and snatch your subscription key for exclusive access."
    },
    {
        name: 'Vault',
        icon: VaultIcon,
        icon_hover: VaultIcon,
        description: "Vault - Step into the world of advanced data storage, where files are meticulously encrypted, poised for decryption with a digital flourish, and available for seamless download at your command."
    },
    {
        name: 'Authentication',
        icon: AuthenticationIcon,
        icon_hover: AuthenticationIcon,
        description: "Authentication - Unveiling Digital Truths! Enter a dimension where the reference code, pipeline, project name, status, and the very inception date of each authenticated file."
    },
    {
        name: 'Templates',
        icon: TemplateIcon,
        icon_hover: TemplateIcon,
        description: "Templates - Crafting Digital Realities! Explore a realm where reference codes, pipelines, project names, statuses, and the very inception dates of each authorized file come to life."
    },
    {
        name: 'Analytics',
        icon: AnalyticsIcon,
        icon_hover: AnalyticsIcon,
        description: "Analytics - Decoding Digital Insights! Embark on a journey into a domain where reference codes, pipelines, project names, statuses, and the very inception dates of each authorized file are illuminated."
    },
    {
        name: 'Users',
        icon: UsersIcon,
        icon_hover: UsersIcon,
        description: "Users - Unveiling User Connections! Step into a universe where reference codes, pipelines, project names, statuses, and the very inception dates of each authorized file are revealed, creating pathways of understanding."
    },
    {
        name: 'API Documentation',
        icon: APIIcon,
        icon_hover: APIIcon,
        description: "API Documentation - Mapping Digital Pathways! Enter a dimension where reference codes, pipelines, project names, statuses, and the very inception dates of each authorized file are unveiled, enabling seamless navigation."
    }
  
    
]

export default verification_images
import { Box, Card, CardMedia, Grid, Link, Typography } from '@mui/material';
import OVCODECCPO from '../../../images/solutions/police-clearance-min.png';
import OVCODECHURCH from '../../../images/solutions/church.png';
import OVCODECONTRACT from '../../../images/solutions/contract.png';
import OVCODECOVID from '../../../images/solutions/covid-min.png';
import OVCODEGOVERNMENT from '../../../images/solutions/government.png';
import OVCODEKPOP from '../../../images/solutions/kpop.png';
import OVCODEMS365 from '../../../images/solutions/ms365.png';
import OVCODEOVI from '../../../images/solutions/collectibles.png';
import OVCODEOVSIGN from '../../../images/solutions/ovsign.png';
import OVCODEEID from '../../../images/solutions/eid.png';
import ccpo from '../../../images/solutions/ccpo-sc-1.png';
import church from '../../../images/solutions/church-sc-1.png';
import contract from '../../../images/solutions/contract-sc-1.png';
import covid from '../../../images/solutions/covid.jpg';
import government from '../../../images/solutions/government-casev2.jpg';
import government2 from '../../../images/solutions/government-mdr.jpg';
import kpop from '../../../images/solutions/kpop-sc-1.png';
import ms365 from '../../../images/solutions/ms365-sc-2v2.jpg';
import ovi from '../../../images/solutions/ovi-sealv2.jpg';
import OVSignLogo from '../../../icons/eSignSuperFinalLogoSize.png';
import eIDLogo from '../../../icons/eIDSuperFinalLogoSize.png';



const solutions_images = [
    {
        title : "COVID-19 Test Results",
        name : <p>Securely authenticated and verified <br/> to ensure public safety.</p>,
        secondName : <p>Securely authenticated and verified <br/> to ensure public safety.</p>,
        icon: OVCODECOVID,
        description : 
            <Grid container spacing={2} style={{marginLeft: '0px !important', justifyContent: 'center', width: '100%', marginTop: '0px !important'}}>
                <Grid item xs={12} sm={12} >
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        During the height of the pandemic around October 2020, there were 94,811 authenticated Covid-19 results in OVCODE. 
                    </Typography>
                    <br/>
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        OVCODE's tools securely authenticate and verify COVID test results, with the integration of digital QR Codes for verification. The OVCODE APIs integrate these results into clinics' and testing companies' existing systems. Patient privacy of test results is secured using password protection and email transmission, to assure the integrity and validity of the test results.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                <CardMedia
                        component="img"
                        image={covid}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                </Grid>
            </Grid>,
        text: <>
                <p>
                    During the height of the pandemic around October 2020, there were 94,811 authenticated Covid-19 results in OVCODE. 
                </p>
                {/* <br/> */}
                <p>
                    OVCODE's tools securely authenticate and verifiy COVID test results, with the integration of digital QR Codes for verification.
                 </p>
            </>,
             size: "big"
    },

    {
        title: "Police Background Check",
        name : <p>Transforms a background check  <br/> into a cryptographic hash.</p>,
        secondName : <p>Transforms a background check into a cryptographic hash.</p>,
        icon: OVCODECCPO,
        description : 
        <Grid container spacing={2} style={{marginLeft: '0px !important', justifyContent: 'center', width: '100%', marginTop: '0px !important'}}>
            <Grid item xs={12} sm={12} >
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE is integrated into to the Cebu City Police Clearance system. It has been operational since May 2016. The Cebu City Police Clearance is an official document issued as a background check by the local police agency to enumerate any criminal records the applicant may have.
                    <br/> <br/>
                    Police clearance is one of the documents employers require in the hiring process. It can also be a requirement for passport and other government ID applications. OVCODE's production of a police clearance document transforms that document into a cryptographic hash. It is then published to the OVCODE Blockchain network with the publisher’s digital signature. The published police clearance is assigned an OVCODE for verification.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                <CardMedia
                    component="img"
                    image={ccpo}
                    style={{objectFit: 'contain', margin:'auto'}}
                />
            </Grid>
        </Grid>,
        text : "OVCODE is integrated into to the Cebu City Police Clearance system.It has been operational since May 2016. ",
     
           
    },

    {
        title: "Church Sacrament Certification",
        name : "Integration with the Roman Catholic Church's certificate issuance system.",
        secondName :  "Integration with the Roman Catholic Church's certificate issuance system.",
        icon: OVCODECHURCH,
        description : 
            <Grid container spacing={2} style={{marginLeft: '0px !important', justifyContent: 'center', width: '100%', marginTop: '0px !important'}}>
                <Grid item xs={12} sm={12} >
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify',textJustify: 'inter-word'}}>
                        OVCODE integrates with the Church's certificate issuance system. The Archdiocese of Lipa, Batangas, through the parishes, issues baptismal, confirmation, marriage, and death certificates to the parishioners who receive the Sacraments. It has adopted a computerized system to manage these certificates. OVCODE transforms the document into a verifiable entity that is accessible anywhere through the Internet. The system has been operational since June 2016.
                   </Typography>
                </Grid>
                <Grid item xs={12} sm={12} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                    <CardMedia
                        component="img"
                        image={church}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                </Grid>
            </Grid>,
        text :  "OVCODE integrates with the Church's certificate issuance system. The Archdiocese of Lipa, Batangas, through the parishes, issues baptismal, confirmation, marriage, and death certificates to the parishioners who receive the Sacraments.",
        size: "big"
         
    },

    {
        title: "Office 365",
        name : <p>Authentication and verification <br/> via user "add-in" option.</p>,
        secondName : <p>Authentication and verification  via user "add-in" option.</p>,
        icon: OVCODEMS365,
        description : 
            <Grid container  >
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE can integrate directly to the Microsoft Office 365 user with its 'Add-in' as an option. Once OVCODE is downloaded, the user can publish his/her documents to the OVCODE Blockchain network. The published document is converted to a verifiable entity and confirmed through parameters given by the users. The verification can be executed on the web or mobile in the OVCODE platform.
                </Typography>
                <br/>
                <CardMedia
                    component="img"
                    image={ms365}
                    style={{objectFit: 'contain', margin:'auto'}}
                />
            </Grid>,
        text : "OVCODE can integrate directly to the Microsoft Office 365 user with its 'Add-in' as an option. Once OVCODE is downloaded, the user can publish his/her documents to the OVCODE Blockchain network.",
    },

    {
        title: "Contract Management",
        name : <p>Streamlining with  <br/> enhanced trust and efficiency.</p>,
        secondName : <p>Streamlining with  <br/>  enhanced trust and efficiency.</p>,
        icon: OVCODECONTRACT,
        description :
            <Grid container spacing={2} style={{marginLeft: '0px !important', justifyContent: 'center', width: '100%', marginTop: '0px !important'}}>
                <Grid item xs={12} sm={12} >
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        OVCODE digital signatures and digital seals generate immutable and verifiable documents which are legally binding. The signature and seal tools facilitate agreements and approvals. They speed up and provide convenience to any document transaction, and minimize, or may even eliminate, the need to physically print, route, and scan documents.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                    <CardMedia
                        component="img"
                        image={contract}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                </Grid>
            </Grid>,
        text : "OVCODE digital signatures and digital seals generate immutable and verifiable documents which are legally binding."
    },

    {
        title: "Collectibles",
        name :    <p>Advanced technology transparently <br/> authenticates and verifies. </p>,
        secondName : <p>Advanced technology transparently authenticates and verifies. </p>,
        icon: OVCODEOVI,
        description : 
            <Box>
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE visual verification (OV-i) is the world’s first multi-patented technology which enables visual verification, integrated with Blockchain technology, to produce an immutable and secure product identification and authentication. <br/> <br/> OV-i is enabled to be a marketing tool which features sophisticated, dynamic, and interactive content :
                </Typography>
                <Box sx={{padding: 3, pt: 0, pb: 0}}>
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        <li>an innovative 4-way nano-optical lens (film)</li>
                        <li>an anti-forgery technology with hidden micro patterns developed to replace the current ones, such as:</li>
                            <Box sx={{padding: 3, pt: 0, pb: 0}}>
                                <p style={{marginBlockStart: '0px', marginBlockEnd: '0px'}}>1. holograms - hardly recognizable by ordinary consumers </p> 
                                <p style={{marginBlockStart: '0px', marginBlockEnd: '0px'}}>2. radio frequency identification (RFID) - requires extra reader</p>
                            </Box>
                        <li>its coated pattern has a thickness which makes it impossible to replicate</li>
                    </Typography>
                </Box>
                <Box sx={{padding: 3, pt: 1, pb: 0}}>
                <Typography style={{fontSize: '1.2rem', color: '#007fff', fontWeight: 700, textAlign: 'center'}} >
                    OVCODE USE CASE Verifiable Tamper-proof Seal
                </Typography>
                </Box>
                <CardMedia
                    component="img"
                    image={ovi}
                    style={{objectFit: 'contain', margin:'auto', width: '90%'}}
                />
            </Box>,
        text : "OVCODE visual verification (OV-i) is the world’s first multi-patented technology which enables visual verification, integrated with Blockchain technology, to produce an immutable and secure product identification and authentication.",
    },

    {
        title: "KPOP Digital Assets",
        name : <p>Protecting artists and fans <br/> from fake products.</p>,
        secondName : "Protecting artists and fans from fake products.",
        icon: OVCODEKPOP,
        description :
            <Grid container spacing={2} style={{marginLeft: '0px !important', justifyContent: 'center', width: '100%', marginTop: '0px !important'}}>
                <Grid item xs={12} sm={12} >
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        OVCODE authenticates original digital assets to protect artists and fans from fake products. OVCODE has signed up Dreamcatcher, as the first entity in South Korea and the world, where OVCODE will authenticate the KPop group's photos, videos, and other digital assets in the Blockchain. Dreamcatcher will now have a repository of digital assets which has registrations of ownership and copyrights, and are verifiable anywhere in the world..
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} sx={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                    <CardMedia
                        component="img"
                        image={kpop}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                </Grid>
            </Grid>,
        text : " OVCODE authenticates original digital assets to protect artists and fans from fake products. OVCODE has signed up Dreamcatcher, as the first entity in South Korea and the world, where OVCODE will authenticate the KPop group's photos, videos, and other digital assets in the Blockchain. ",
       
    },
    

   
    
    
    
    
    
    

    
    
    
]

export default solutions_images
import React, {useState, useEffect} from "react";
import {Grid} from "@mui/material";
import {QrReader} from "react-qr-reader";
import useVerification from "../../../hooks/useVerification";

const ScanQRComponent = (props) => {

    const {breakpoints, setOpenScan, setOpenUnverifiedData, setOpenVerifiedData} = props;
    
    const {verifyReference} = useVerification();

    const [scannedRef, setScannedRef] = useState(null);
    
    const handleError = (error) => {
        console.info(error);
    }

    useEffect(() => {
        if (scannedRef) {
            handleVerifyReference(scannedRef);
        }

    }, [scannedRef])

    const handleVerifyReference = (refData) => {

        // public && downloadable true
        // const staticRefData = "https://ovcode.com/v/D-135703f6-9547-4677-9af5-7c1696aaa611"

        // sample pdf file
        // const staticRefData = "https://ovcode.com/v/F-7ae05afe-3f1f-456c-942c-3fedf0df62c5"

        // sample json file
        // const staticRefData = "https://ovcode.com/v/D-d34f635f-9d0c-46b1-af41-107b84d1b580"

        // sample docx file
        // const staticRefData = "https://ovcode.com/v/F-57e26aa4-2a83-46f6-9fd9-5ba11e42c044"

        // public && downloadable false 
        // const staticRefData = "https://ovcode.com/v/F-d485ef81-7686-4a63-b45f-ce2d45ae7652"

        // private
        // const staticRefData = "https://ovcode.com/v/D-341b5ba0-e0d3-445e-95e0-2d8e3e1304a2"

        // const reference = staticRefData.split("/").pop();
        
        const reference = refData.split("/").pop();

        verifyReference(reference, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData);
    }


    return (

        <Grid container spacing={2} sx={{padding: (breakpoints == 'xs' || breakpoints == 'sm') ? 1 : 18, pt: 0, pb: 0, justifyContent: 'center', alignItems: 'center'}}>
            <Grid item xs={10}>
                <QrReader 
                    delay={300}
                    constraints={{facingMode: (breakpoints == "xs" || breakpoints == "sm") ? "environment" : "user"}}
                    style={{width: "10%"}} 
                    autoFocus={true}
                    onError={handleError}
                    onResult={(result, error) => {
                        if (result && result.text) {
                            setScannedRef(result.text);
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default ScanQRComponent;

import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"


const escalation_feedback = [
    {
        name: "When should I escalate a support issue to higher levels?",
        panel: "Panel1",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    If you encounter a complex issue that requires further expertise, or if a user's concern is not being resolved within a reasonable time, you should escalate the issue following our escalation process (process details).
                </Typography>
            </Box>
    },
    {
        name: "How can I gather user feedback and suggestions for improvement? ",
        panel: "Panel2",
        description:
            <Box>
                <Typography sx={{ mb: 2 }}>
                    Encourage users to provide feedback and suggestions through our designated feedback channels (provide channels). Document valuable feedback and submit it to our product team for consideration.
                </Typography>
            </Box>
    }

]

export default escalation_feedback

import { Box, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import backgroundImage from "../../../images/background-1.png";


function ProductsBannerComponent(props) {

  const { static_variable } = props;


    const breakpoints = useWidth();

    return (
        <Box sx={{mb: 4, width: '90%', margin: 'auto', overflow : 'hidden'}}>
            <Grid container spacing={2} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Grid item xs={12} sm={6} >
                    <Box sx={{mb: 4}}>
                        <Typography sx={{color: "#002060", textShadow: "3px 2px 0px rgba(0,0,0,0.15)", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? '2rem' : '3.3333rem', margin: 0, lineHeight: 1.2, fontWeight: 700,  textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'start'}}>
                            OVCODE PRODUCTS
                        </Typography>
                    </Box>

                    <Box sx={{mb: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 4}}>
                        <Typography sx={{fontSize: "1.25rem", margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                            Explore a comprehensive selection of digital solutions offered by <b style={{fontWeight: 700, textTransform: 'uppercase'}}>OVCODE</b>, including advanced digital signature, capabilities to secure asset storage, a robust verification engine, and a cutting-edge NFT infrastructure.
                            {/* <b style={{fontWeight: 700, textTransform: 'uppercase'}}>OVCODE</b> offers a range of products, including a digital signature tool for simple and sophisticated agreement and approval needs, a verification engine to maintain data integrity, an NFT infrastructure for creating unique and immutable representations of digital assets, and a secure vault for permanently registering and storing valuable digital assets with easy management and sharing features. */}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={1} ></Grid>
                <Grid item xs={12} sm={5} >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  sx={{mt: 3, justifyContent: 'center',alignItems: 'center', alignContent: 'center', textAlign:  (breakpoints == "xs" || breakpoints == "sm") ?  'center' : 'end'}}>
                        <Box sx={{height: "100%", width: '100%', maxHeight: "600px !important"}}>
                            <img alt=""  src={backgroundImage} style={{height: (breakpoints == "xs" || breakpoints == "sm") ? {} : 350,imageRendering: 'pixelated', imageRendering: '-webkit-optimize-contrast', width: (breakpoints == "xs" || breakpoints == "sm") ? '100%' : {} }} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProductsBannerComponent
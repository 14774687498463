import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import IdentitiyVerification from "../../../../../images/blog/identity-verification.png";


const IdentityVerificationComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                The Future of Identity Verification: An In-Depth Look at OVCODE eID
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 15 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={IdentitiyVerification} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the digital age, where the boundaries between the physical and virtual worlds blur, the
                                traditional methods of identity verification face unprecedented challenges. Enter OVCODE
                                eID—a transformative solution designed to revolutionize the very fabric of identity
                                authentication.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Challenges of Traditional Identity Verification</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1. Fraud and Impersonation:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Traditional methods often fall victim to sophisticated fraud techniques. Identity theft
                                        and impersonation pose significant threats, eroding trust in online transactions and
                                        interactions.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Data Breaches:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Centralized storage of sensitive personal information is a prime target for hackers. Data
                                        breaches in institutions lead to compromised identities and expose individuals to
                                        various risks.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. User Friction:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Complex and cumbersome verification processes contribute to user dissatisfaction.
                                        Lengthy procedures hinder the seamless user experience, impacting engagement and
                                        adoption rates.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE eID: A Paradigm Shift in Identity Verification</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1.  Immutable Identity Records:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE eID leverages blockchain's immutability to create an unalterable record of
                                        identity. Once recorded, an individual's identity is secured against tampering, providing
                                        a robust defense against fraud.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Decentralized Security:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        By decentralizing identity verification, OVCODE eID eliminates the vulnerability of
                                        centralized databases. This not only protects individuals from large-scale data breaches
                                        but also ensures that the control over one's identity remains in the hands of the rightful
                                        owner.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Biometric Authentication:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE eID integrates advanced biometric authentication methods, adding an extra
                                        layer of security. Facial recognition, fingerprint scans, and other biometric markers
                                        enhance the accuracy and reliability of identity verification.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>4. User-Centric Design:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Recognizing the importance of user experience, OVCODE eID is designed with a user-centric approach. The verification process is streamlined, reducing friction and ensuring
                                        a smooth, intuitive experience for individuals.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Real-World Applications</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1.  Financial Transactions:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        In the financial sector, OVCODE eID ensures secure and seamless identity verification
                                        for transactions. The immutable nature of blockchain adds an unparalleled level of trust
                                        to every financial interaction.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Healthcare Records:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        For healthcare providers, OVCODE eID guarantees the integrity of patient identity
                                        records. This not only facilitates accurate treatment but also safeguards sensitive
                                        healthcare information.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. E-Government Services:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        In the realm of e-government services, OVCODE eID streamlines access to public
                                        services while maintaining the highest standards of identity verification. This fosters
                                        efficiency and transparency in government interactions.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Road Ahead: OVCODE eID and the Digital Identity Landscape</Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                As we navigate the complex terrain of digital interactions, OVCODE eID stands as a beacon,
                                heralding a future where identity verification is not just secure but seamlessly integrated into
                                our digital lives. It's a journey towards a world where individuals have control over their
                                identities, and trust is an inherent part of every online interaction.
                            </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                In conclusion, OVCODE eID isn't just redefining identity verification; it's laying the foundation for
                                a future where digital identities are secure, user-friendly, and resilient to the challenges of the
                                evolving digital landscape.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-success-stories"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        eID Success Stories: Real-world Applications of Secure Identity Verification
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Share case studies and success stories of businesses benefiting from OVCODE eID.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-esign-document-workflow"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Transforming Document Workflows: OVCODE eSign Explained
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Provide insights into how OVCODE eSign streamlines document processes for businesses.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default IdentityVerificationComponent
import {useState} from "react"
import VerificationService from "../services/api/verification"
import {useVerifiedDataStore} from "../zustand/useVerifiedDataStore";
import {enqueueSnackbar} from "notistack";
import {blobToBase64} from "../utils/blobToBase64Utils";
import environment from "../environment/environment";
import {useNavigate} from 'react-router-dom';

const useVerification = () => {

    const {setPdfBaseUrlData} = useVerifiedDataStore();
    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [isDownloading, setDownloading] = useState(false);
    const [isViewBtnClicked, setViewBtnClicked] = useState(null);
    const [isViewingJSONFile, setViewingJSONFile] = useState(false);
    const [isDocxFile, setDocxFile] = useState(false);
    const [verifying_uploaded_data, setVerifyingUploadedData] = useState(false);
    const [scannedResult, setScannedResult] = useState(false);
    const [pdfView, setPdfView] = useState(null)

    const uploadData = (hash, setOpenUpload, setOpenVerifiedData, setOpenUnverifiedData) => {

        setVerifyingUploadedData(true);

        VerificationService.uploadData(hash).then((res) => {
            console.log("uploadData data xxxx", res);

            setVerifyingUploadedData(false);

            // let verified_data = res.data.data.owner.business;
            let verified_data = res.data.data.owner.project;
            let doc_privacy = res.data.data.privacy;
            let doc_downloadable = res.data.data.is_data_downloadable;
            let reference = res.data.data.details.reference;
            let doc_status = res.data.data.status;
            let doc_updatedTime = res.data.data.details.updated_time;

            if (res.data.success) {
                setOpenVerifiedData(true);
                setOpenUnverifiedData(false);
                setSuccess(true);
                
                localStorage.setItem("isScannedDocument", false);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("docPrivacy", doc_privacy);
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("docStatus", doc_status);
                localStorage.setItem("docPublishedDate", doc_updatedTime);

                // navigate("/v/" + reference);

            } else {
                setSuccess(false);
                setVerifyingUploadedData(false);
            }

        }).catch((error) => {
            console.log("uploadData error xxxx", error);

            setVerifyingUploadedData(false);

            let unverified_doc = "The reference not verified";

            if (error.response.data.error == unverified_doc && error.response.data.success == false) {
                setOpenUnverifiedData(true);
                setOpenVerifiedData(false);

                localStorage.setItem("isUnverifiedDialog", true);
                localStorage.setItem("isVerifiedDialog", false);

                // navigate("/v/" + hash);
            }

            setOpenUpload(false);
        })

    }

    const verifyReference = (reference, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData) => {

        VerificationService.verifyReference(reference, setOpenScan).then((res) => {
            console.log("verifyReference data xxxx", res);
     
            // let verified_data = res.data.data.owner.business;
            let verified_data = res.data.data.owner.project;
            let doc_privacy = res.data.data.privacy;
            let doc_downloadable = res.data.data.is_data_downloadable;
            let doc_status = res.data.data.status;
            let doc_updatedTime = res.data.data.details.updated_time;

            if (res.data.success) {
                setScannedResult(true);
                setOpenVerifiedData(true);
                setOpenUnverifiedData(false);
                setOpenScan(false);

                localStorage.setItem("isScannedDocument", true);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("docPrivacy", doc_privacy);
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("docStatus", doc_status);
                localStorage.setItem("docPublishedDate", doc_updatedTime);

                navigate("/v/" + reference);
            }

        }).catch((error) => {
            console.log("verifyReference error xxxx", error);

            let unverified_doc = "The reference not verified";

            if (error.response.data.error == unverified_doc && error.response.data.success == false) {
                setOpenUnverifiedData(true);
                setOpenVerifiedData(false);

                localStorage.setItem("isUnverifiedDialog", true);
                localStorage.setItem("isVerifiedDialog", false);

                navigate("/v/" + reference);
            }

            setOpenScan(false);
        })
    }

    const generateSharedKey = (reference, isViewBtn, setViewingDocument) => {

        setViewBtnClicked(isViewBtn);
        setDownloading(true);
        
        if (isViewBtn) {
            setViewingDocument(true);
        }

        VerificationService.generateSharedKey(reference).then((res) => {
            console.log("generateSharedKey data xxxx", res);

            let sharedKey = res.data.data.shared_key;

            if (res.data.success) {
                downloadUsingSharedKey(sharedKey, isViewBtn, setViewingDocument);
            }

        }).catch((error) => {
            console.log("generateSharedKey error xxxx", error);

            setViewingDocument(false);
            setDownloading(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const downloadUsingSharedKey = (sharedKey, isViewBtn, setViewingDocument) => {
        setDownloading(true);

        if (isViewBtn) {
            setViewingDocument(true);
        }
    
        VerificationService.downloadUsingSharedKey(sharedKey).then((res) => {
            console.log("downloadUsingSharedKey data xxxx", res);

            let mimetype = res.headers["content-type"];
            let splittedFilename = res.headers["content-disposition"].split(";").pop();
            let filename = splittedFilename.split("=").pop();
            let extension = filename.split(".").pop();
            let blob = res.data;

            // for downloaded data variable save in local storage
            let domain = environment.domain.url;
            // let domain = "https://blue-bay-0057a4b1e.2.azurestaticapps.net";
            // let domain = "http://localhost:3000";

            let pdfUrl = domain + "/view/pdf";
            let jsonUrl = domain + "/view/json";
            let textUrl = domain + "/view/text";

            // for downloaded data variable save in local storage
            if (extension == "pdf") {
                blobToBase64(blob).then(res => {
                    // localStorage.setItem("pdfBase64Data", res);
                    console.log('get downloaded pdf hereeeeeeeeeeeeeeee', res)
                    setPdfView(res)
                    // localStorage.setItem("pdfUrl", pdfUrl);
                    setDownloading(false);
                });

            } else if (extension == "json") {
                VerificationService.getDownloadedData(sharedKey).then((res) => {
                    console.log("getDownloadedData data xxxx", res);

                    const jsonString = JSON.stringify(res.data);
                    localStorage.setItem("jsonData", jsonString);
                    localStorage.setItem("jsonUrl", jsonUrl);
                    
                }).catch((error) => {
                    console.log("getDownloadedData error xxxx", error);
                })
            
            } else if (extension == "docx" || extension == "doc") {
                setDocxFile(true);

            } else if (extension == "txt") {
                VerificationService.getDownloadedData(sharedKey).then((res) => {
                    console.log("getDownloadedData data xxxx", res);

                    localStorage.setItem("textData", res.data);
                    localStorage.setItem("textUrl", textUrl);

                }).catch((error) => {
                    console.log("getDownloadedData error xxxx", error);
                })
            }

            // execute this code only if view button is clicked
            if (isViewBtn) {

                let domain = environment.domain.url;
                // let domain = "https://blue-bay-0057a4b1e.2.azurestaticapps.net";
                // let domain = "http://localhost:3000";

                let pdfUrl = domain + "/view/pdf?document=" + filename;
                let jsonUrl = domain + "/view/json?document=" + filename;
                let textUrl = domain + "/view/text?document=" + filename;
                // let docxUrl = domain + "/view/docx?document=" + filename;

                if (extension == "pdf") {
                    blobToBase64(blob).then(res => {
                        setPdfBaseUrlData(res);
                        // localStorage.setItem("pdfBase64Data", res);
                        setPdfView(res)

                        if (res) {
                            // window.open(pdfUrl, '_blank');
                            setViewingDocument(false);
                        }
                    });

                } else if (extension == "json") {
                    setViewingJSONFile(true);

                    VerificationService.getDownloadedData(sharedKey).then((res) => {
                        console.log("getDownloadedData data xxxx", res);

                        const jsonString = JSON.stringify(res.data);
                        localStorage.setItem("jsonData", jsonString);

                        if (res.data) {
                            window.open(jsonUrl, '_blank');
                            setViewingDocument(false);
                        }
                        
                    }).catch((error) => {
                        console.log("getDownloadedData error xxxx", error);
                    })
                
                } else if (extension == "docx" || extension == "doc") {
                    setViewingDocument(false);
                    setDocxFile(true);

                    enqueueSnackbar("Document viewer not supported", { 
                        variant: "error",
                        autoHideDuration: 3000
                    });

                } else if (extension == "txt") {
                    VerificationService.getDownloadedData(sharedKey).then((res) => {
                        console.log("getDownloadedData data xxxx", res);

                        localStorage.setItem("textData", res.data);

                        if (res.data) {
                            setViewingDocument(false);
                            window.open(textUrl, '_blank');
                        }
                        
                    }).catch((error) => {
                        console.log("getDownloadedData error xxxx", error);
                        setViewingDocument(false);
                    })
                }
            }

            // execute this code only if save as button is clicked
            if (res.data && !isViewBtn) {
                setDownloading(false);

                const blobData = new Blob([res.data], {type: mimetype});
                const url = URL.createObjectURL(blobData);
                const link = document.createElement('a');
                
                link.href = url;
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();
            }

        }).catch((error) => {
            console.log("downloadUsingSharedKey error xxxx", error);

            setDownloading(false);
            setViewingDocument(false);
            enqueueSnackbar(error.response.statusText, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const getDownloadedData = (sharedKey) => {
        VerificationService.getDownloadedData(sharedKey).then((res) => {
            console.log("getDownloadedData data xxxx", res)

        }).catch((error) => {
            console.log("getDownloadedData error xxxx", error);
        })
    }


    return {uploadData, verifyReference, generateSharedKey, downloadUsingSharedKey, getDownloadedData, isDownloading, 
        isViewBtnClicked, isViewingJSONFile, isDocxFile, verifying_uploaded_data, scannedResult, pdfView}

}

export default useVerification
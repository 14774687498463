import ovcodeImageOne from '../../../../images/eID-in-depth/in-depth-2.png';
import ovcodeImageTwo from '../../../../images/eID-in-depth/in-depth-1.png';
import ovcodeImageThree from '../../../../images/eID-in-depth/in-depth-3.png';
import ovcodeImageFour from '../../../../images/eID-in-depth/in-depth-4.png';
import ovcodeImageFive from '../../../../images/eID-in-depth/in-depth-5.jpg';
import ovcodeImageSix from '../../../../images/eID-in-depth/in-depth-6.png';
import ovcodeImageSeven from '../../../../images/eID-in-depth/in-depth-7.png';
import ovcodeImageEight from '../../../../images/eID-in-depth/in-depth-8.jpg';
import ovcodeImageNine from '../../../../images/eID-in-depth/in-depth-9.jpg';
import ovcodeImageTen from '../../../../images/eID-in-depth/in-depth-10.png';
import ovcodeImageEleven from '../../../../images/eID-in-depth/in-depth-11.png';
import ovcodeImageTwelve from '../../../../images/eID-in-depth/in-depth-12.jpg';
import ovcodeImageThirteen from '../../../../images/eID-in-depth/in-depth-13.png';


const eidData = [
    {
        title: "The Future of Identity Verification: An In-Depth Look at OVCODE eID",
        date: "October 09, 2023",
        description: "Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.",
        image: ovcodeImageOne,
        link: "/blog-eid-identity-verification"
    },
    {
        title: "eID Success Stories: Real-world Applications of Secure Identity Verification",
        date: "October 09, 2023",
        description: "Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.",
        image: ovcodeImageTwo,
        link: "/blog-eid-success-stories"
    },
    {
        title: "Revolutionizing Secure Payments: Enhancing User Experience with OVCODE's eID Verification",
        date: "April 02, 2024",
        description: "Leverage the seamless integration of OVCODE’s eID verification to transform the payment industry. This platform provides a secure and user-friendly way to authenticate transactions, reducing fraud and simplifying the checkout process. The convenience and security of OVCODE’s eID verification enrich the overall user experience, fostering trust in digital transactions.",
        image: ovcodeImageThree,
        link: "/blog-eid-secure-payments"
    },
    // {
    //     title: "Digital Credentials: OVCODE in Professional Development",
    //     date: "April 02, 2024",
    //     description: "OVCODE is transforming professional development with digital credentials, enabling professionals to securely store, share, and verify their achievements and qualifications. This empowers individuals to advance their careers with confidence and helps organizations ensure the authenticity of their employees’ credentials.",
    //     image: ovcodeImageFour,
    //     link: ""
    // },
    // {
    //     title: "Seamless Checkout: How OVCODE's eID is Redefining Online Shopping",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID technology is redefining the online shopping experience with seamless checkout processes. This summary explores how OVCODE’s secure identity verification minimizes fraud, speeds up transactions, and improves customer satisfaction, leading to a smoother, more trustworthy online shopping journey.",
    //     image: ovcodeImageFive,
    //     link: ""
    // },
    // {
    //     title: "OVCODE's eID: The Key to Frictionless Mobile Payments",
    //     date: "April 02, 2024",
    //     description: "Dive into how OVCODE's eID technology is unlocking the potential for frictionless mobile payments. By ensuring secure and swift identity verification, OVCODE facilitates easier and safer transactions, meeting the modern consumer’s demand for convenience and security.",
    //     image: ovcodeImageSix,
    //     link: ""
    // },
    // {
    //     title: "The Intersection of eID and Wallet Apps: OVCODE’s User-Centric Approach",
    //     date: "April 02, 2024",
    //     description: "Explore the intersection of eID and digital wallet apps through OVCODE’s user-centric approach. This discussion highlights how integrating secure digital identity verification with wallet applications enhances user experience, security, and the overall utility of digital wallets.",
    //     image: ovcodeImageSeven,
    //     link: ""
    // },
    // {
    //     title: "Contactless Commerce: OVCODE's eID Fueling the Future of Payments",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID technology is fueling the future of contactless commerce, ensuring transactions are not only convenient but also secure. By streamlining the authentication process, OVCODE is at the forefront of developing a safer, more efficient digital marketplace.",
    //     image: ovcodeImageEight,
    //     link: ""
    // },
    // {
    //     title: "OVCODE's eID and Subscription Services: Simplifying Recurring Payments",
    //     date: "April 02, 2024",
    //     description: "Discover how OVCODE simplifies recurring payments for subscription services with its eID technology. By streamlining subscriber verification, OVCODE ensures a smoother, more secure subscription experience, reducing fraud and enhancing customer loyalty.",
    //     image: ovcodeImageNine,
    //     link: ""
    // },
    // {
    //     title: "From Brick-and-Mortar to Digital: OVCODE’s eID Transforming Retail Payments",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID is transforming retail by bridging the gap between brick-and-mortar and digital payments. This summary explores how OVCODE’s secure digital identity verification enhances the retail experience, offering customers a seamless, secure way to pay, both online and in-store.",
    //     image: ovcodeImageTen,
    //     link: ""
    // },
    // {
    //     title: "The Impact of OVCODE's eID on Reducing Checkout Abandonment",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID technology significantly reduces checkout abandonment by streamlining the authentication process. Learn how this enhancement in the online shopping journey not only boosts sales but also improves customer satisfaction by offering a hassle-free, secure checkout experience.",
    //     image: ovcodeImageEleven,
    //     link: ""
    // },
    // {
    //     title: "OVCODE's eID: Bridging the Gap for Underbanked Populations",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID technology offers a lifeline to underbanked populations by providing secure, accessible digital identity solutions. This summary delves into how OVCODE facilitates financial inclusion, allowing those without traditional banking access to participate in the digital economy.",
    //     image: ovcodeImageTwelve,
    //     link: ""
    // },
    // {
    //     title: "Enhancing Payment Security and Efficiency with OVCODE's eID Integration",
    //     date: "April 02, 2024",
    //     description: "OVCODE’s eID integration is setting new benchmarks in payment security and efficiency. This summary highlights how leveraging secure digital identities can streamline transactions, reduce fraud, and provide a seamless payment experience for users and businesses alike.",
    //     image: ovcodeImageThirteen,
    //     link: ""
    // },
];

export { eidData }
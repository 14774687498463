import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import exploreOvcodeOne from "../../../images/explore-ovcode/one.png";
import EnhancedSecurity from "../../../images/ovcode-advantages/enhanced-security-min.png";
import SeamlessAuth from "../../../images/ovcode-advantages/seamless-auth2-min.png";
import PaperlessRevolution from "../../../images/ovcode-advantages/paperless-revolution-min.png";
import GlobalReach from "../../../images/ovcode-advantages/global-reach-min.png";
import EnvImpact from "../../../images/ovcode-advantages/env-impact-min.png";
import CompetitiveAdv from "../../../images/ovcode-advantages/competitive-adv-min.png";
import PrivacyFirst from "../../../images/ovcode-advantages/privacy-first-min.png";
import LegalAssurance from "../../../images/ovcode-advantages/legal-assurance-min.png";
import Blockchain from "../../../images/ovcode-advantages/blockchain01.jpg";
import DigitalTrust from "../../../images/ovcode-advantages/digital-trust2-min.png";
import useWidth from "../../../hooks/useWidth";

const OVCodeAdvantagesComponent = () => {

    const breakpoints = useWidth();

    console.log("breakpointsxxx", breakpoints)

    return (
        <Box>
            <Box sx={{mb: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontSize: "37px",
                    margin: 0,
                    lineHeight: 1.2,
                    fontWeight: 600,
                    textAlign: "start",
                    mb: "1.25rem",
                }}>
                    Features and Benefits
                </Typography>
            </Box>

            <Grid container direction="row" spacing={3} >
                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={DigitalTrust} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Digital Trust Redefined
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                        Cutting-edge technology, anchored in blockchain, 
                        ensures the authenticity and integrity of digital data. 
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={EnhancedSecurity} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Enhanced Security
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                        Our robust encryption, tamper-evident records, and cryptographic 
                        hashing fortify your data against threats, safeguarding it like 
                        never before.
                    </Typography>
                </Grid>  
            </Grid>

            <Grid container direction="row" spacing={3} sx={{mt: "10px"}}>
                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={SeamlessAuth} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Seamless Authentication
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                        Easily verify identities, 
                        documents, or digital signatures utilizing our user-friendly interfaces, making authentication 
                        a breeze.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={PaperlessRevolution} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Paperless Revolution
                    </Typography>
                    
                    <Typography sx={{textAlign: "justify"}}>
                        Eliminate the need for physical paperwork, reduce 
                        costs and environmental impact, and embrace the paperless 
                        revolution while enhancing productivity.
                    </Typography>
                </Grid>  
            </Grid>

            <Grid container direction="row" spacing={3} sx={{mt: 1}}>
                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={LegalAssurance} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Legal Assurance
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                        Digital signatures and document authentication
                        ensure compliance with legal and regulatory requirements, 
                        providing legal certainty and peace of mind.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={Blockchain} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                         Blockchain Backbone
                    </Typography>
                    
                    <Typography sx={{textAlign: "justify"}}>
                        Transparency through blockchain
                        technology guarantees immutable, tamper-resistant records, 
                        fostering trust among stakeholders and users alike.
                    </Typography>
                </Grid>  
            </Grid>

            <Grid container direction="row" spacing={3} sx={{mt: 1}}>
                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={PrivacyFirst} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Privacy First
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                        Advanced security measures 
                        protect user information, keeping it private and secure, and giving 
                        users full control over their digital footprint.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={GlobalReach} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Global Reach
                    </Typography>
                    
                    <Typography sx={{textAlign: "justify"}}>
                        Worldwide accessibility connects you without geographical limitations.
                    </Typography>
                </Grid>  
            </Grid>

            <Grid container direction="row" spacing={3} sx={{mt: 1}}>
                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={CompetitiveAdv} 
                        style={{objectFit: "contain", width: "100%"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Competitive Advantage
                    </Typography>

                    <Typography sx={{textAlign: "justify"}}>
                       When you offer unparalleled security and trust features, 
                        setting you apart from the competition and attracting 
                        more customers.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <img alt="" src={EnvImpact} 
                        style={{objectFit: "contain", width: "100%"}}
                        // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{display: "block", textAlign: "start"}}>
                    <Typography sx={{fontWeight: "bold", mb: 2}}>
                        Environmental Impact
                    </Typography>
                    
                    <Typography sx={{textAlign: "justify"}}>
                        Join the sustainability movement with paperless solutions, 
                        reduce environmental waste, and contribute to a 
                        greener planet.
                    </Typography>
                </Grid>  
            </Grid>

            {/* <Box sx={{mt: 5, mb: "-52px", width: breakpoints === 'xl' ? '95%' :  breakpoints === 'lg' ? '97%'  : '100%', margin: 'auto'}}>
                <Typography sx={{textAlign: "center"}}>   
                    In the digital age, trust isn't just an ideal; it's a necessity. 
                    OVCODE empowers you to navigate this new era of trust with confidence. 
                    Explore our solutions, experience the future of security, and redefine 
                    trust with OVCODE by your side. Welcome to a world where trust knows no 
                    bounds.
                </Typography>
            </Box> */}
        </Box>
    )

    // return (
    //     <Box sx={{display: "block", mt: 2}}>
    //         <Grid container direction="row">
    //             <Grid item xs={6} sm={3}>
    //                 <img alt="" src={exploreOvcodeOne} 
    //                     style={{objectFit: "contain", width: "100%", height: "200px"}}
    //                     // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
    //                 />
    //             </Grid>
    //             <Grid item xs={6} sm={3} sx={{display: "block", textAlign: "start"}}>
    //                 <Typography sx={{fontWeight: "bold", mb: 2}}>
    //                     Digital Trust Redefined
    //                 </Typography>
    //                 <Typography>
    //                     OVCODE introduces a new standard of trust in the digital realm.
    //                     Our cutting-edge technology, anchored in blockchain, 
    //                     ensures the authenticity and integrity of digital data, 
    //                     documents, and transactions. Say goodbye to uncertainty 
    //                     and embrace unwavering trust.
    //                 </Typography>
    //             </Grid>
    //         </Grid>

    //         <Grid container direction="row">
    //             <Grid item xs={6} sm={6}>
    //                 <img alt="" src={exploreOvcodeOne} 
    //                     style={{objectFit: "contain", width: "100%", height: "200px"}}
    //                     // style={{width: "100%", borderRadius: 10, height: "320px", objectFit: "cover"}}
    //                 />
    //             </Grid>
    //             <Grid item xs={6} sm={6} sx={{display: "block", textAlign: "start"}}>
    //                 <Typography sx={{fontWeight: "bold", mb: 2}}>
    //                     Digital Trust Redefined
    //                 </Typography>
    //                 <Typography>
    //                     OVCODE introduces a new standard of trust in the digital realm.
    //                     Our cutting-edge technology, anchored in blockchain, 
    //                     ensures the authenticity and integrity of digital data, 
    //                     documents, and transactions. Say goodbye to uncertainty 
    //                     and embrace unwavering trust.
    //                 </Typography>
    //             </Grid>
    //         </Grid>
    //     </Box>
    // )
}

export default OVCodeAdvantagesComponent
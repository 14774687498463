
function PrivateRoute({children}) {

    return children
  
    // if has auth
    // return isUserLoggedIn ? children : <Navigate to="/" />;

}

export {PrivateRoute}